import qiaoQian from './wenan/initial/qiaoQian.vue'
import zhuTiYanJiang from './wenan/initial/zhuTiYanJiang.vue'
import saiLanYiYao from './wenan/initial/saiLanYiYao.vue'
import jinFangYiYao from './wenan/initial/jinFangYiYao.vue'
import shangHaiZhiMeng from './wenan/initial/shangHaiZhiMeng.vue'
import aoJingYiLiao from './wenan/initial/aoJingYiLiao.vue'
import yiMaiYangGuang from './wenan/initial/yiMaiYangGuang.vue'
import jinFangYiYaoTow from './wenan/initial/jinFangYiYao-tow.vue'
import aoRanShengWu from './wenan/initial/aoRanShengWu.vue'
import xinKaiNuo from './wenan/initial/xinKaiNuo.vue'
import shangHaiZhiMengTow from './wenan/initial/shangHaiZhiMeng-tow.vue'
import zhongbang from './wenan/initial/zhongbang.vue'
import xinkai from './wenan/initial/xinkai.vue'
import jinfang from './wenan/initial/jinfang.vue'
// 以下组件命名方式使用 拼音日期+第几篇
// 10月26
import shiYueErShiLiuOne from './wenan/10-26-update/one.vue'
import shiYueErShiLiuTow from './wenan/10-26-update/tow.vue'
import shiYueErShiLiuThree from './wenan/10-26-update/three.vue'
import shiYueErShiLiuFour from './wenan/10-26-update/four.vue'
import shiYueErShiLiuFive from './wenan/10-26-update/five.vue'
import shiYueErShiLiuSix from './wenan/10-26-update/six.vue'
import shiYueErShiLiuSeven from './wenan/10-26-update/seven.vue'
import shiYueErShiLiuEight from './wenan/10-26-update/eight.vue'
import shiYueErShiLiuNine from './wenan/10-26-update/nine.vue'
import shiYueErShiLiuTen from './wenan/10-26-update/ten.vue'
import shiYueErShiLiuEvelen from './wenan/10-26-update/eleven.vue'
import shiYueErShiLiuTwelve from './wenan/10-26-update/twelve.vue'
import shiYueErShiLiuThirteen from './wenan/10-26-update/thirteen.vue'
import shiYueErShiLiuFourteen from './wenan/10-26-update/fourteen.vue'
import shiYueErShiLiuFifteen from './wenan/10-26-update/fifteen.vue'
import shiYueErShiLiuSixteen from './wenan/10-26-update/sixteen.vue'
// 12月5号
import shierYueWuOne from './wenan/12-05-update/one.vue'
import shierYueWuTow from './wenan/12-05-update/tow.vue'
import shierYueWuThree from './wenan/12-05-update/three.vue'
import shierYueWuFour from './wenan/12-05-update/four.vue'
import shierYueWuFive from './wenan/12-05-update/five.vue'
import shierYueWuSix from './wenan/12-05-update/six.vue'
// 23年3月29号
import sanYueErShiJiuHaoOne from './wenan/23-03-29-update/one.vue'
import sanYueErShiJiuHaoTwo from './wenan/23-03-29-update/two.vue'
const list = {
  yiMaiYangGuang,
  sanYueErShiJiuHaoTwo,
  sanYueErShiJiuHaoOne,
  qiaoQian,
  zhuTiYanJiang,
  saiLanYiYao,
  jinFangYiYao,
  shangHaiZhiMeng,
  aoJingYiLiao,
  jinFangYiYaoTow,
  aoRanShengWu,
  xinKaiNuo,
  shangHaiZhiMengTow,
  zhongbang,
  xinkai,
  jinfang,
  shiYueErShiLiuOne,
  shiYueErShiLiuTow,
  shiYueErShiLiuThree,
  shiYueErShiLiuFour,
  shiYueErShiLiuFive,
  shiYueErShiLiuSix,
  shiYueErShiLiuSeven,
  shiYueErShiLiuEight,
  shiYueErShiLiuNine,
  shiYueErShiLiuTen,
  shiYueErShiLiuEvelen,
  shiYueErShiLiuTwelve,
  shiYueErShiLiuThirteen,
  shiYueErShiLiuFourteen,
  shiYueErShiLiuFifteen,
  shiYueErShiLiuSixteen,
  shierYueWuOne,
  shierYueWuTow,
  shierYueWuThree,
  shierYueWuFour,
  shierYueWuFive,
  shierYueWuSix,
}
export default list