// setRefreshToken, removeRefreshToken
import { setToken, removeToken } from '@/util/auth'

import { setStore, getStore } from '@/util/store'
// import { isURL, validatenull } from '@/util/validate'
// import { deepClone } from '@/util/util'
import { loginByUsername, loginByPhoneCode, logout, refreshToken } from '@/api/loginAndgister/index'
import md5 from 'js-md5'
import { getStsToken } from '@/api/common/common.js'
const user = {
  state: {
    stsToken: getStore({ name: 'stsToken' }) || {},
    userInfo: '',
    fromRouter: '',
    upatePasswordShow: false,
  },
  actions: {
    getStsToken ({ commit }) {
      return new Promise((resolve, reject) => {
        getStsToken().then((res) => {
          const data = res.data.data
          commit('SET_STSTOKEN', data)
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 根据用户名登录
    LoginByUsername ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginByUsername(userInfo.userName, userInfo.smsCode, userInfo.smsKey, userInfo.openId, userInfo.UserType, userInfo.GrantType, userInfo.password ? md5(userInfo.password) : undefined).then(res => {
          const data = res?.data?.data
          console.log(res, '登录返回结果')
          if (data.error_description) {
            Message({
              message: data.error_description,
              type: 'error'
            })
          } else {
            commit('SET_TOKEN', data?.accessToken)
            commit('SET_USER_INFO', data)
            // 针对用户点击左上角公司信息 所做的处理
            // setStore({ name: 'clickNum', content: 0 })
            // setStore({ name: 'userInfoCheck', content: false })
          }
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 根据手机号登录
    LoginByPhone ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginByPhoneCode(userInfo).then(res => {
          const data = res?.data?.data
          commit('SET_TOKEN', data?.access_token)
          commit('SET_USER_INFO', data)
          // 针对用户点击左上角公司信息 所做的处理
          // setStore({ name: 'clickNum', content: 0 })
          // setStore({ name: 'userInfoCheck', content: false })
          // commit('DEL_ALL_TAG')
          // commit('CLEAR_LOCK')
          resolve(data.detail.customer.examineStatus)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    RefreshToken ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        refreshToken().then(res => {
          const data = res?.data?.data
          commit('SET_TOKEN', data?.access_token)
          commit('SET_USER_INFO', data)
          resolve(data.detail.customer.examineStatus)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 登出
    LogOut ({ commit }) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          commit('SET_TOKEN', '')
          commit('SET_USER_INFO', '')
          // 针对用户点击左上角公司信息 所做的处理
          // setStore({ name: 'clickNum', content: 0 })
          // setStore({ name: 'userInfoCheck', content: false })
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 注销session
    FedLogOut ({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_USER_INFO', '')
        // 针对用户点击左上角公司信息 所做的处理
        // setStore({ name: 'clickNum', content: 0 })
        // setStore({ name: 'userInfoCheck', content: false })
        removeToken()
        resolve()
      })
    },
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      setToken(token)
      state.token = token
      setStore({ name: 'token', content: state.token })
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
      setStore({ name: 'userInfo', content: state.userInfo })
    },
    SET_STSTOKEN: (state, data) => {
      state.stsToken = data.stsToken
      setStore({ name: 'stsToken', content: state.stsToken })
    },
    SET_UPDATE_PASSWORD_SHOW: (state, data) => {
      state.upatePasswordShow = data
      setStore({ name: 'upatePasswordShow', content: state.upatePasswordShow })
    }
  }
}
export default user