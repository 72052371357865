<template>
    <div class="main">
        <img src="/img/03-25/one.gif" alt="">
        <div class="top"
            :class="(isMobile && isMobile.includes('iPad')) ? 'contentiPad' : isMobile !== null && 'contentMobile'">
            <span></span>
            <div>
                <span class="one"></span>
                <span class="tow"></span>
            </div>
            <span></span>
        </div>
        <p>泽悦创投荣幸地宣布励备兰女士正式加入公司，担任投资合伙人。</p>
        <img src="/img/libeilan.jpg" alt="" class="img-one">
        <p>励备兰女士拥有28年药企销售和政府事务管理经验，也是一名资深的执业医师，曾在勃林格殷格翰、赛诺菲、葛兰素史克、赫斯特等跨国药企任职全国政府事务负责人、区域销售负责人。</p>
        <p>加入泽悦创投前，励备兰是勃林格殷格翰的全国政府事务负责人。她基于对中国市场行业发展趋势的深刻理解，敏锐捕捉政策变化，及时调整企业策略，并建立起广泛的政府关系网络，成功推动了勃林格殷格翰产品线在中国市场的准入和政策应对，以及制定针对性市场策略。
        </p>
        <p>励备兰女士毕业于同济大学医学院临床医学系，获得新加坡国立大学商业管理专业文凭。</p>
        <p>泽悦创投创始合伙人吕晓翔表示：<strong>“欢迎励备兰女士正式加入泽悦创投。她的专业背景、丰富经验和卓越业绩，不仅在行业内树立了标杆，更将为泽悦创投带来宝贵的资源和多元的视野。”</strong></p>
        <div class="top"
            :class="(isMobile && isMobile.includes('iPad')) ? 'contentiPad' : isMobile !== null && 'contentMobile'">
            <span></span>
            <div>
                <span class="one"></span>
                <span class="tow"></span>
            </div>
            <span></span>
        </div>
        <br>
        <br>
        <div class="footer">
            <img src="/img/03-25/tow.png" class="img-tow" alt="">
            <br>
            <p>泽悦创投专注于医疗大健康领域的股权投资，由行业资深人士共同创立，拥有丰富的行业资源和差异化的投资理念。基于对市场环境、医改趋势的深刻洞见，整合研发、生产、营销资源，寻找具有“底层创新”和“全球化商业潜力”的创业项目，以长期价值为基础作出投资决策。
            </p>
        </div>
    </div>
</template>

<script>
export default {
    inject: {
        isMobile: {
            default: null,
        },
    },
    props: {},
    data() {
        return {}
    },
    components: {},
    created() { },
    mounted() { },
    computed: {},
    watch: {},
    methods: {},
}
</script>
<style scoped lang='scss'>
.main {
    img {
        display: block;
        margin: 0 auto;
    }

    .img-one {
        width: 60%;
    }

    .top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &>span {
            width: 46%;
            height: 1px;
            background: #3b6791;
        }

        &>div {
            width: 0.3rem;
            height: 0.15rem;

            span {
                display: inline-block;
                width: 50%;
                height: 100%;
                border-radius: 50%;
            }

            .one {
                border: 1px solid #3b6791;
                transform: translateX(20%);
                position: relative;
                z-index: 3;
            }

            .tow {
                background: #ffca00;
                transform: translateX(-20%);
            }
        }
    }

    .contentMobile {
        &>div {
            width: 0.6rem;
            height: 0.3rem;
        }
    }

    .footer {
        width: 100%;
        background: #f8fafb;
        padding: 0 0.2rem 0.2rem 0.2rem;

        .img-tow {
            width: 40%;
        }

        p {
            margin-bottom: unset !important;
        }
    }
}
</style>