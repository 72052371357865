<template>
  <div>
    <img src="/img/12-05/3.gif" class="img3" alt="" />
    <br />
    <article class="article">
      <div>
        2022年10月22日，由中国医疗器械行业协会增材制造医疗器械专业委员会和陕西省科学技术厅共同主办，中国医师协会心血管外科医师分会和国家卫健委结构性心脏病介入质控中心协办的“2022国际心血管3D打印技术高峰论坛”在线上举行，大会打破时间空间壁垒，聚国际顶级专家学者，集五洲四海同道智慧，带来了一场洞悉全球最前沿心血管3D打印技术的思想盛宴。
      </div>
      <br />
      <img src="/img/12-05/4.jpg" class="img4" alt="" />
    </article>
    <br />
    <p class="text">
      会上，大会执行主席、空军军医大学西京医院（以下简称“西京医院”）
      <strong>杨剑教授</strong> 为大家分享了一例
      <strong>3D打印指导下的风湿性二尖瓣狭窄的经导管二尖瓣置换手术</strong>
      。该例手术于2022年9月14日进行，西京医院心血管外科杨剑教授团队，应用上海纽脉医疗科技股份有限公司（“纽脉医疗”）自主研发的Prizvalve®经导管主动脉瓣膜系统（以下简称“Prizvalve®瓣膜系统”），为一名风湿性二尖瓣狭窄患者成功实施了经股静脉—房间隔途径的经导管二尖瓣置换手术。
    </p>
    <img src="/img/12-05/5.png" class="img5" alt="" />
    <p class="text">
      杨剑教授在术前进行了充分的评估和分析，特别是在3D打印指导下进行解剖结构的分析和体外模型的手术模拟，为手术的成功实施提供了强有力的保障，同时也有力地论证了3D打印技术在心血管领域的实际指导意义和良好的应用前景。
    </p>
    <img src="/img/12-05/6.png" class="img6" alt="" />
    <span class="zhujie">术前3D数字模型</span>
    <img src="/img/12-05/7.png" class="img6" alt="" />
    <span class="zhujie">术前3D打印模型</span>
    <img src="/img/12-05/8.png" class="img6" alt="" />
    <span class="zhujie">3D打印模型模拟TMVR术</span>
    <img src="/img/12-05/9.png" class="img6" alt="" />
    <span class="zhujie">3D打印模拟术后形态</span>
    <img src="/img/12-05/10.png" class="img6" alt="" />
    <span class="zhujie">实际手术过程</span>
    <img src="/img/12-05/11.png" class="img6" alt="" />
    <br />
    <img src="/img/12-05/12.png" class="img6" alt="" />
    <span class="zhujie">术后3D打印，瓣膜位置理想</span>
    <p class="blue">
      Prizvalve®瓣膜系统由纽脉医疗历经多年自主研发，是我国首个拥有自主知识产权的国产球囊扩张式经导管主动脉瓣膜，该产品已申请数十项国家专利，并获得上海市科委重点研发计划支持。Prizvalve®瓣膜系统已于2021年被国家药品监督管理局认可为获准进入加速特別审查的创新医疗器械。
    </p>
    <p class="blue">
      作为国内首款进入注册临床研究的国产球囊扩张式经导管主动脉瓣膜产品，Prizvalve®瓣膜系统已在临床中获得一定数量的应用。由于球扩瓣设计上的优势，在国际上多有报道球扩瓣用于心脏四个瓣膜的临床应用，截止目前，
      <strong
      >纽脉医疗的这款球扩瓣Prizvalve®已助力中国临床专家用于心脏四个瓣膜的全“心”应用，也实现了各类器械（外科瓣、介入瓣、成形环、支架）的适配临床应用，为中国瓣膜疾病治疗同步于国际水平提供了国产解决方案。</strong
      >
    </p>
    <p class="blue">
      目前，Prizvalve®多中心临床研究已顺利完成，期待该产品能够尽早获批上市销售，为临床专家在心脏瓣膜疾病领域提供更多可靠、安全的治疗方案，造福广大患者。
    </p>
  </div>
</template>

<script>
export default {
  inject: {
    isMobile: {
      default: null,
    },
  },
  props: {},
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang='scss'>
img {
  display: block;
  margin: 0 auto;
  width: 100%;
}
.article {
  width: 100%;
  padding: 20px;
  background-color: #f4fdfe;
  border-width: 1px;
  border-radius: 10px;
  border-style: solid;
  border-color: rgba(137, 232, 249, 0.43);
  color: rgb(0, 92, 162);
  div{
        font-size: 15px;
  }
}
.text {
  color: rgb(113, 113, 113);
  strong {
    color: rgb(113, 113, 113);
  }
}
.blue {
  color: rgb(0, 92, 162) !important;
  strong{
  color: rgb(0, 92, 162) !important;

  }
}
</style>