<template>
  <div :class="(isMobile !== null && (isMobile.includes('Android') || isMobile.includes('iPhone'))) ? 'mobile' : 'pc'">
    <p>2022年3月1日，北京市经济和信息化局公告了<span>2021年度第二批北京市专精特新“小巨人”企业名单</span> ，奥精医疗科技股份有限公司成功入选。</p>
    <img src="/img/10-26/image23.png" alt="" class="img23">
    <img src="/img/10-26/image24.png" alt="" class="img24">
    <h4>何谓“专精特新”？</h4>
    <p>“专精特新”中小企业是指专业化、精细化、特色化、创新能力突出的中小企业，“小巨人”则是其中的佼佼者。中小企业是实体经济的重要基础，推动中小企业实现“专精特新”发展，既是加快制造业转型升级提质的需要，也是持续增强实体经济发展活力、培育新动能的要求。</p>
    <h4>
      专精特新“小巨人”企业需满足哪些条件？
    </h4>
    <p>评审综合考察申报企业在经济效益、专业化程度、创新能力和经营管理等方面的指标，要求企业在细分市场内名列前茅，代表行业中前沿创新能力、掌握领域关键核心技术。具体要求可参考北京市经济与信息化局网站。</p>
    <h4>奥精医疗</h4>
    <p>
      奥精医疗科技股份有限公司成立于2004年，<span>是一家专注于高端再生医学材料及植入类医疗器械的技术研发、高端制造、市场推广的国家高新技术企业</span>。历经15年风雨，已成长为我国再生医学材料医疗器械领域的领军企业，其产品技术先进性、临床认可度、品牌影响力、年销售收入等均处于行业领先地位。公司在上海证券交易所科创板上市（A股股票代码：688613）。
    </p>
    <p>奥精公司于2011年率先在国际上研制出成分和结构均与天然人体骨组织高度相似的矿化胶原仿生骨材料，并完成了技术产业化和临床转化的具体工作，同时建立起具有完全自主知识产权的骨胶原体外仿生矿化技术平台，使得我国在仿生人工骨修复材料这一高端医疗器械领域处于国际领先地位。矿化胶原仿生骨系列产品现已获得3项中国CFDA三类医疗器械产品注册证和1项美国510(k)市场准入许可。矿化胶原仿生人工骨的物质组成和微观结构均与人体自身骨组织高度相似，能够被广泛应用于骨科、口腔科、神经外科、整形外科等领域的骨缺损修复，产品现已在临床使用超百万例。</p>
    <p>奥精公司的矿化胶原仿生骨产品于2018年被中国科技部评为“国际原创类创新医疗器械”，材料的相关基础研究和产业化工作曾荣获国家自然科学奖二等奖（2011年，一等奖空缺）和国家技术发明奖二等奖（2008年）。奥精公司现已申请和获得国内外专利50余项；公司入选“十三五”首批“北京市生物医药产业跨越工程（G20工程）”创新引领企业、中关村高新技术企业、北京市海淀园“海帆企业”，并凭借突出的自主研发能力和科技创新能力，被北京市科委认定为“北京市级企业科技研究开发机构”；产品还荣获国家重点新产品、北京市自主创新产品、北京市新技术新产品等荣誉。</p>
    <p>此外，奥精医疗在保持国内市场领先地位的同时，也加大推进公司国际化战略，逐步建立了覆盖美国东部主要城市的营销网络，同时积极响应习近平主席提出的“一带一路”倡议，精准拓展面向欧洲、东南亚、南美洲等地区的业务渠道，稳步实现公司全球化销售战略，全力打造国际化高端品牌。</p>
    <p>未来，公司将继续专注于高端生物医用材料，加大科技研发投入，包括矿化胶原/聚酯人工骨及胶原蛋白海绵等研发项目，持续助力高端医疗器械国产化进程。</p>
  </div>
</template>

<script>
export default {
  props: {},
  inject: {
    isMobile: {
      default: null,
    },
  },
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang="scss">
.mobile{
  h4{
    line-height: unset !important;
  }
}
p{
  span{
    color: red;
  }
}
img{
  display: block;
  margin: 0 auto;
}
</style>
