<template>
  <div>
    <img src="/img/12-05/32.png" class="img32" alt="">
    <p></p>
    <div class="blue">
      <img src="/img/12-05/34.svg" class="img34" alt="">
    </div>
    <p></p>
    <section class="sec1">
      <p>为加强张江科投与参股基金之间交流，帮助参股基金投资企业更加了解张江的创新创业生态，解决创业过程中遇到的瓶颈和挑战， <strong>11月23日，张江集团与泽悦创投共同举办被投企业交流会。这是该系列交流会的第四期。</strong> </p>
      <p>张江集团副总经理陈衡、张江集团副总经济师、张江生物医药基地开发有限公司总经理楼琦、张江集团招商服务中心主任赵栋，张江科投总经理余洪亮、张科禾润总经理林瓴，张江科投副总经理孙维琴，张江集团招商服务中心主任助理赵盈盈，张江科投总经理助理张泓斐，泽悦创投创始合伙人吕晓翔，泽悦创投联合创始合伙人张捷，泽悦创投合伙人屠晓洲，泽悦创投董事总经理曹文炜等嘉宾出席活动。</p>
      <img src="/img/12-05/35.jpg" class="img34" alt="">
      <div class="imgs">
        <img src="/img/12-05/36.jpg" class="img34" alt="">
        <img src="/img/12-05/37.jpg" class="img34" alt="">
      </div>
    </section>
    <img src="/img/12-05/38.gif" class="img38" alt="">
    <img src="/img/12-05/39.jpg" class="img34" alt="">
    <p class="blue2">张江集团副总经理陈衡</p>
    <br>
    <p>历经30年的开发建设，张江实现了从全球集聚到创新引领的快速发展，建设了全球瞩目的生物技术和医药产业创新集群，成为研发资源高度集聚、创新成果快速涌现、新技术新模式率先突破的标志性科技园区。张江集团副总经理陈衡在致辞中表示，“今天的相见相识，仅仅是开始。我们非常期待能邀请各位企业家走进张江、了解张江、加入张江，与张江携手共进，一起创造生命健康产业的美好未来。”
    </p>
    <img src="/img/12-05/40.jpg" class="img34" alt="">
    <p class="blue2">张江集团招商服务中心主任赵栋</p>
    <br>
    <p>张江集团招商服务中心主任赵栋分享了张江生物医药产业创新生态。张江已经形成了从新药探索、药物筛选、药理评估、临床研究、中试放大、注册认证到量产上市的完整产业链，来自全球医疗健康领域的龙头企业、创新企业在张江聚集，国家级和市级的公共创新服务平台也纷纷落地。“非常欢迎大家深入了解张江，我们也将为大家提供更为优质的服务，助力创新企业更好发展。”
    </p>
    <p class="yuan">截至今年10月底，张江科投在生命健康领域，累计投资了46个项目，见证了生态超过40家企业在海内外资本市场上市，其中8家在今年迎来上市的钟声，包括和元生物、荣昌生物、益方生物、奥浦迈等；同时，累计投资了18支生命健康产业一线前沿基金，进一步通过资本的“接棒”，帮助创新企业向全国乃至全球展现张江的创新力量</p>
    <img src="/img/12-05/41.jpg" class="img34" alt="">
    <p class="blue2">张江科投总经理余洪亮
    </p>
    <br>
    <p>张江科投总经理余洪亮表示，“三十而励，向新而行，张江科投也将持续聚焦产业资源优势，构建具有差异化的投后服务逻辑，帮助大家解决创业路上遇到的瓶颈和挑战，以张江创新加速度助力各位企业家实现跨越式发展。”
    </p>
    <img src="/img/12-05/42.gif" class="img42" alt="">
    <br>
    <div class="box">
      <img src="/img/12-05/43.jpg" class="img34" alt="">
      <p class="blue2">泽悦创投吕晓翔</p>
      <p><strong class="off">泽悦创投</strong> 专注于医疗大健康领域的股权投资，由行业资深人士共同创立，拥有丰富的行业资源和差异化的投资理念。基于对市场环境、医改趋势的深刻洞见，整合研发、生产、营销资源，寻找具有“底层创新”和“全球化商业潜力”的创业项目，以长期价值为基础作出投资决策。
      </p>
      <p>吕晓翔表示，泽悦创投致力于与创业者一起创业，并通过差异化投资、深度投后服务加速创新落地。首家受资企业——奥精生物已于2021年科创板上市。未来，泽悦创投将持续通过全球化视角投资多元化组合，为生命科学企业、产业乃至整个社会贡献出自己的力量。 </p>
      <img src="/img/12-05/44.jpg" class="img34" alt="">
      <p class="blue2">挚盟医药创始人&CEO 陈焕明</p>
      <p><span class="off">挚盟医药</span> 致力于慢性乙型肝炎（CHB）和中枢神经系统的创新药物研发，以满足患者急切的临床需求。挚盟医药创始人&CEO 陈焕明介绍，公司致力于开发用于治疗神经系统疾病的临床急需药物，例如癫痫、神经性疼痛和脑卒中等，努力为患者提供更加安全、有效的药物，改善患者的生命质量。</p>
      <img src="/img/12-05/45.jpg" class="img34" alt="">
      <p class="blue2">元启生物创始人&CEO 丁元华</p>
      <p><span class="off">元启生物</span> 是一家靶向药物研发商，基于生物学的深刻理解和多种研究策略，结合靶点结构研究技术、人工智能技术等新兴技术，针对包括GPCR靶点在内的具有挑战性的靶点进行探索性研究工作。元启生物创始人&CEO 丁元华介绍，“管理团队均有20+年跨国药企研发和管理经验，公司已有4个创新药。”</p>
      <img src="/img/12-05/46.jpg" class="img34" alt="">
      <p class="blue2">赛岚医药创始人&CEO 吴海平</p>
      <p><span class="off">赛岚医药</span> 专注于表观遗传学领域治疗技术和新药研发。赛岚医药创始人&CEO 吴海平说：“公司立足于做创新药，做中国患者急需的好药，特别是实现从基础科研成果向医药应用的快速转化。”成立两年以来，赛岚医药已打造了一条丰富和具有独特创新力和竞争力的管线，包括10余个全新靶点和机理（first-in-class）或同类最优（best-in-class）的项目，已有多个分子达到PCC阶段，且已有2个项目进入临床试验阶段，针对中国特有的肿瘤等重大疾病和适应症。公司独特的EpigenPLUS技术平台，是具有表观遗传治疗特色，融合了生物学、生物化学、药物化学和创新医学技术等多元化的创新药物发现的技术平台。</p>
      <img src="/img/12-05/47.jpg" class="img34" alt="">
      <p class="blue2">上药云健康CFO 孔凌飞</p>
      <p><span class="off">上药云健康</span> 是中国领先的“互联网+”医药商业科技平台。公司提供以专业药房为基础的创新药全生命周期服务、以电子处方+云药房为基础的普慢药“互联网+”服务，开创医药分业中国模式。药云健康CFO 孔凌飞表示，“新型专业药房可为创新药商业化构建广阔的院外市场蓝图。上药云健康聚合了中国大健康产业链头部优质资源，构建了覆盖创新药全生命周期的服务链条。”</p>
      <img src="/img/12-05/48.jpg" class="img34" alt="">
      <p class="blue2">奥然生物创始人&CEO 余家昌</p>
      <p><span class="off">奥然生物</span> 专注即时分子诊断技术，成功研发了一系列适用于微生物检测、个性化用药基因检测、癌症检测、法医物证、食品安全等领域的全自动化检测仪、试剂及试剂智能盒以及配套全自动生产线。据创始人&CEO 余家昌介绍，公司自主研发的全自动全密封荧光PCR分析系统顺应全球分子诊断“Sample in, Results Out”的发展趋势，作为新一代PCR技术平台代表，具有技术优势和市场竞争力，广泛应用于各级医疗机构、疾控中心、第三方临检机构、法医中心、检验检疫局等。</p>
      <img src="/img/12-05/49.jpg" class="img34" alt="">
      <p class="blue2">心凯诺医疗创始人&CEO 赵振心</p>
      <p><span class="off">心凯诺医疗</span> 创始人&CEO 赵振心介绍，心凯诺医疗是一家专注于研发高端医疗器械的创新技术企业，拥有神经介入和外周介入两大产品线。公司致力于研发最高质量的神经及外周血管介入医疗器械，为医生和患者提供完整的脑卒中及外周血管疾病的介入器械解决方案。</p>
      <img src="/img/12-05/50.jpg" class="img34" alt="">
      <p class="blue2">清谱科技总经理助理高俊海</p>
      <p><span class="off">清谱科技</span> 专注于高端质谱分析产品的开发、生产和制造，主要产品包括多款便携式质谱仪器及ΩAnalyzer脂质双键定位分析系统等, 为现场检测、科学研究等领域提供实时、准确、简便的检测方案。清谱科技总经理助理高俊海介绍，公司设计了多款便携式质谱仪器及ΩAnalyzer脂质双键定位分析系统等产品，其中Mini β小型质谱分析系统获得2017年“BCEIA金奖”和“2017年科学仪器行业优秀新产品”奖。</p>
      <img src="/img/12-05/51.jpg" class="img34" alt="">
      <p class="blue2">赛德迪康创始人&CEO 孙琳</p>
      <p><span class="off">赛德迪康</span> 创始人&CEO孙琳介绍，赛德迪康是拥有全球领先的组织工程及再生医学创新技术平台的医疗器械公司，目前主要产品为组织工程皮肤。“期待我们的产品尽快进行临床试验，加速推进技术国产化，为更多需要的人群带去福音。”</p>
      <p>至此，张江集团&泽悦创投被投企业交流会落下帷幕。未来，张江科投将持续以多元的产业资源助力创业者走向成功。</p>
    </div>
  </div>
</template>

<script>
export default {
  inject: {
    isMobile: {
      default: null,
    },
  },
  props: {},
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang='scss'>
.yuan{
  padding: 20px;
  border-radius: 20px;
  border: 2px solid black;
}
.box{
  padding: 20px;
  border: 1px solid black;
}
.off{
    color: rgb(45, 69, 151);
}
.blue2{
  font-size: 0.125rem;
  color: rgb(31, 73, 125);
  text-align: center;
  margin-bottom: 15px !important;
}
.img42{
  width: 40%;
}
img {
  display: block;
  margin: 0 auto;
}
.img32{
  width: 100%;
}
.blue{
  width: 100%;
  background: #2d4597;
  overflow: hidden;
  .img34{
    margin-top: 40px;
    width: 60%;
  }
}
section.sec1{
  padding: 10px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: dashed;
  border-color: rgb(33, 33, 34);
  background: #f8f8f8;
  strong{
    color: rgb(45, 69, 151);
  }
}
.imgs{
  display: flex;
  justify-content: space-between;
  img{
    width: 49.8%;
    margin: unset;
    margin-top: 10px;
  }
}
.img38{
  width: 5%;
}
</style>