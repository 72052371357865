<template>
  <div class="home">
    <Banner1 id="0" v-scroll-reveal.reset :data-wow-duration="animateConfig.duration" class="fadeIn wow el "/>
    <Banner2 id="1" v-scroll-reveal.reset :data-wow-duration="animateConfig.duration" class="fadeIn wow el " />
    <Banner3 id="2" v-scroll-reveal.reset :data-wow-duration="animateConfig.duration" class="fadeIn wow el " /> <!-- 这里的类名用来点击其他地方关闭弹窗 -->
    <Content1 id="3" v-scroll-reveal.reset :data-wow-duration="animateConfig.duration" class="fadeIn wow el content1c" />
    <Content2 id="4" v-scroll-reveal.reset :data-wow-duration="animateConfig.duration" class="fadeIn wow el content2c" />
    <Content3 id="5" v-scroll-reveal.reset :data-wow-duration="animateConfig.duration" class="fadeIn wow el content3c" />
  </div>
</template>

<script>
import Banner1 from '@/views/home/component/banner/banner1'
import Banner2 from '@/views/home/component/banner/banner2'
import Banner3 from '@/views/home/component/banner/banner3'
import Content1 from '@/views/home/component/content/content1'
import Content2 from '@/views/home/component/content/content2'
import Content3 from '@/views/home/component/content/content3'
import { mapGetters } from 'vuex'
import { WOW, wowInit, animateConfig } from '@/util/scrollReveal.js'
export default {
  inject: {
    isMobile: {
      default: null
    }
  },
  data () {
    return {
      diaLogIsShow: false,
      timeOut: null,
      animateConfig
    }
  },
  components: {
    // Header,
    Banner1,
    Banner2,
    Banner3,
    Content1,
    Content2,
    Content3,
    // Footer
  },
  mounted () {
    const wowi = new WOW(wowInit)
    wowi.init()
    window.addEventListener('scroll', this.onScroll)
    this.navBarItemActive < 6 && this.goView()
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScroll)
  },
  computed: {
    ...mapGetters(['navBarItemActiveClick', 'navBarItemActive'])
  },
  watch: {
    navBarItemActiveClick: {
      immediate: true,
      handler () {
        this.navBarItemActive < 6 && this.goView()
      }
    }
  },
  methods: {
    onScroll () {
      clearTimeout(this.timeOut)
      var header = document.querySelector('.header').clientHeight + 150 // 这个 150 是页面元素距离顶部导航栏还有 150px 时就切换导航栏
      // console.log(header)
      // 获取所有的锚点元素
      const el = document.querySelectorAll('.el')
      // 所有锚点元素的 offserTop
      const offsetTop = []
      el.forEach(item => { offsetTop.push(item.offsetTop) })
      // 获取当前文档流的 scrollTop
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // 定义当前点亮的导航下标
      let activeNav = 0
      offsetTop.forEach((item, index) => { if (scrollTop > offsetTop[index] - header) { activeNav = index } })
      // console.log(offsetTop[1] - header, scrollTop)
      this.timeOut = setTimeout(() => {
        // 当能获取到这个页面元素的时候再修改导航栏的效果
        document.querySelector('.el') && this.$store.commit('setNavBarItemActive', activeNav)
      }, 100)
    },
    goView () {
      // console.log(this.navBarItemActive, '=-=-=-=-=-===-==-=--=', document.getElementById(this.navBarItemActive))
      this.$nextTick(() => {
        window.scrollTo({
        // document.querySelector('.header').clientHeight 是头部导航栏的高度
          top: document.getElementById(this.navBarItemActive).offsetTop - document.querySelector('.header').clientHeight,
          behavior: 'smooth' // 滚动条平滑滚动
        })
      })
    }
  },
}
</script>

<style scoped lang='scss'>
</style>