<template>
  <div :class="(isMobile !== null && (isMobile.includes('Android') || isMobile.includes('iPhone'))) ? 'mobile' : 'pc'">
    <p>
      6月14日~18日，以“中国故事”为主题的第六届未来医疗100强大会在线上拉开帷幕，同时隆重发布“2022未来医疗100强”榜单。上药云健康凭借卓越的创新实力荣登“2022未来医疗100强主榜——
      <strong class="color">创新医疗服务TOP100”</strong> ，位列榜单
      <strong class="color">第14位</strong> 。
    </p>
    <p>
      创立于2015年的未来医疗100强榜是由VB100、动脉网、蛋壳研究院推出的，面向企业、投资机构、医院、产业园区的
      <strong class="color">全球性创新医疗健康生态榜单</strong>
      ，是目前生命健康领域
      <strong class="color">最受产业界、资本界关注</strong> 的年度评选。
    </p>
    <img src="/img/10-26/image49.jpeg" alt="" />
    <p>
      作为
      <strong
        class="color"
      >中国最大、最专业的创新药商业化全周期服务平台</strong
      >
      ，上药云健康为正在腾飞的中国创新药行业提供从覆盖创新药临床研发阶段，到上市后的院外用药市场的全面商业化解决方案。
    </p>
    <div class="title">
      <div class="num">01</div>
      <div class="text">
        <strong>全国最大的专业化药房平台，</strong>
        <strong> 布局网络覆盖全国</strong>
      </div>
    </div>
    <p>
      上药云健康立志成为中国领先的
      <strong class="color">“互联网+”医药商业科技平台</strong>
      ，目前拥有医药、商业、创新各个环节的全牌照资源，尤其在新特药领域，已建起涵盖全
      <strong class="color">生命周期的、全国一体化且体量最大</strong>
      的新特药DTP专业药房体系，目前在全国22个省拥有200余家专业DTP药房。
    </p>
    <p>
      围绕院外场景实现服务叠加，满足患者全方位需求：包括
      <strong class="color">慈善赠药</strong> ，完善业务生态链闭环，<strong
        class="color"
      >建立罕见病标杆服务模式</strong
      >
      ，已成为罗氏、辉瑞、赛诺菲、梯瓦等知名药企成功推广标杆，以
      <strong class="color">创新支付+保险</strong> 打造健康险服务。
    </p>
    <div class="title">
      <div class="num">02</div>
      <div class="text">
        <strong>益药平台已成为</strong>
        <strong>创新药全国首单落地首选</strong>
      </div>
    </div>
    <p>
      上药云健康“益药·药房”在2021年一年内助力中国主流市场TOP50的跨国和本土创新药企实现新品首发和全国首单落地，现已成为
      <strong class="color">80%新特药首单落地的第一选择</strong>
      。目前上药云健康与
      <strong class="color">300多</strong> 家新特药企达成合作，总共惠及近
      <strong class="color">600万</strong> 急需新特药的患者。
    </p>
    <p>
      凭借涵盖
      <strong class="color">全生命周期、全国一体化</strong> 且体量最大的新特药
      <strong class="color">DTP专业药房体系</strong>
      ，上药云健康“益药·药房”力争成为更多
      <strong class="color">新特药物、创新疗法等新产品、新模式</strong>
      落地中国的首选服务平台，成为广大创新药企品牌孵化、实现商业化落地的最佳拍档，持续提升
      <strong class="color">创新药在中国的可及性。</strong>
    </p>
    <div class="title">
      <div class="num">03</div>
      <div class="text"><strong>全面参与CAR-T产品商业化设计</strong></div>
    </div>
    <p>
      以革命性疗法CAR-T产品为例，上药云健康在短短几个月内帮助复星凯特
      <strong class="color">设计商业化路径</strong> ，建立创新性的
      <strong class="color">全国统一服务标准</strong>
      ，并在全国21省23市的连锁DTP药房中落地，以70%的超高市场销售份额成为CAR-T行业领导者。即使在防疫保供，复工复产的特殊时期里，奕凯达®商业化药品回输服务持续落地“益药·药房”，制定“一罐一策”的保供仓储运输应急预案，
      <strong class="color">完成全国第100单落地</strong> 。
    </p>
    <p>
      作为全国连锁一体化管理的专业DTP药房，“益药·药房”定制标准化的CAR-T服务流程，从患者签约，到量身定制专用设备，产品配送，再到后续健康管理都有严格的执行标准，以先发优势走在行业前列。
    </p>
    <p></p>
    <div class="imgs">
      <div>
        <img src="/img/10-26/image50.jpeg" class="img50" alt="" />
        <img src="/img/10-26/image51.jpeg" class="img51" alt="" />
      </div>
      <div>
        <img src="/img/10-26/image52.jpeg" class="img52" alt="" />
        <img src="/img/10-26/image53.jpeg" class="img53" alt="" />
      </div>
    </div>
    <p></p>
    <p>
      积极拥抱行业趋势变化，高质量传递企业价值。在未来，上药云健康将继续
      <strong class="color">坚持“一体两翼”战略，以服务为荣</strong>
      ，坚定所想，笃行所向，坚决拥护和践行“健康中国2030”，用“
      <strong class="color">新特药，找益药</strong>
      ”的理念回馈社会各方支持，为推动中国医疗健康事业的高质量发展和提高群众健康水平再添新动力。
    </p>
    <div class="tac">
      <h4>2022 未来医疗100强<br />中国创新医疗服务榜Top100</h4>
    </div>
    <img src="/img/10-26/image54.jpeg" class="img54" alt="" />
    <p></p>
    <div class="regard">
      <div class="border">
        <div class="t">
          <h4>关于上药云健康</h4>
          <span></span>
        </div>
        <p>
          上海医药大健康云商股份有限公司成立于2015年3月18日。作为中国领先的“互联网+”医药商业科技平台，上药云健康以患者为中心，提供以专业药房为基础的创新药全生命周期服务，以电子处方+云药房为基础的普慢药“互联网+”服务，开创医药分业中国模式。上药云健康在创新药领域拥有“益药”系列品牌，提供从创新药上市前的临床研发阶段、到上市后的院外自费用药专业药房阶段、医保阶段、集采阶段，再到迭代阶段的全国一体化布局的创新药全生命周期服务。在普慢药领域，上药云健康通过线上+线下一体化管理，精准匹配患者需求，提升医疗医药资源的可及性。
        </p>
        <img src="/img/10-26/image55.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  inject: {
    isMobile: {
      default: null,
    },
  },
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang="scss">
img {
  display: block;
  margin: 0 auto;
}
.mobile{
  .title{
    display: flex;
    flex-direction: column;
    font-size: .3819rem !important;
  }
   h4{
    line-height: unset  !important;
  }
  .regard{
    .t{
      width: 100%;
      span{
        display: none;
      }
    }
  }
}
.img54 {
  width: 100%;
}
.tac {
  text-align: center;
}
.regard {
  padding-left: .0208rem;
  background: #f4fbff;
  border-left: 4px solid rgb(95, 156, 239);
  .border{
    width: 100%;
    height: 99%;
    padding: 0.1389rem;
    border-left: 2px solid rgb(95, 156, 239);
  }
  .t {
    position: relative;
    top: -0.2389rem;
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 1.125rem;
    margin-bottom: 0.0694rem;
    color: #fff;
    text-align: center;
    h4 {
      background: #217dd5;
    }
    span {
      display: inline-block;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 3px;
      border-color: rgb(33, 125, 213) rgba(255, 255, 255, 0)
        rgba(255, 255, 255, 0) rgb(33, 125, 213);
      box-sizing: border-box;
    }
  }
}
.imgs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  div {
    width: 49%;
    img {
      width: 100%;
      margin-bottom: 0.1389rem;
      border-radius: 20px;
    }
  }
}
.color {
  color: #137cba;
}
.pc{
  .title {
  height: 0.4125rem;
  display: flex;
  background: #e7f6ff;
  .num {
    width: 0.4722rem;
    height: 100%;
    margin-right: 0.0694rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.0694rem;
    color: #fff;
    background: #137cba;
  }
  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    strong {
      display: block;
    }
  }
}
}
</style>
