import axios from 'axios'
import { getToken } from '@/util/auth'
import website from '@/config/website'
import { serialize } from '@/util/util'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
var baseUrl = ''
if (process.env.NODE_ENV === 'development') { // 开发环境
  baseUrl = ''
} else { // 构造就是生产环境,也就是上线的环境
  baseUrl = ''
}
var instance = axios.create({
  baseURL: baseUrl,
  timeout: 10000
})
NProgress.configure({
  showSpinner: false
})
// 只要用instance 实例发送了ajax请求 请求发送之前都会经过请求拦截器
instance.interceptors.request.use(function (config) {
  NProgress.start()
  config.headers.Authorization = 'Basic YWRtaW46YWRtaW5fc2VjcmV0'
  // debugger
  const meta = (config.meta || {})
  const isToken = meta.isToken === false
  // console.log(config)
  if (getToken() && !isToken) {
    config.headers[website.tokenHeader] = 'bearer ' + getToken()
  }

  if (config.method.toLowerCase() === 'get') {
    config.params = config.data
  }
  if (config.text === true) {
    config.headers['Content-Type'] = 'text/plain'
  }
  // headers中配置serialize为true开启序列化
  if (config.method === 'post' && meta.isSerialize === true) {
    config.data = serialize(config.data)
  }
  // 在发送请求之前做些什么
  return config
},
function (error) {
// 对请求错误做些什么
  return Promise.reject(error)
})

// interceptors.response 响应拦截器 只要数据请求完毕 都会经过这里的响应拦截器
instance.interceptors.response.use(function (response) {
  NProgress.done()
  const status = response.data.code || response.status
  const message = response.data.msg || response.data.error_description || '未知错误'
  // && response.config.url.indexOf('token/wechat/mp?code') === -1

  if (status !== 200 && status !== 1011 && message !== '不合法的 oauth_code') {
    return Promise.reject(new Error(message))
  }

  // 数据请求完毕  关闭弹窗
  // toast1.clear()
  return response
}, function (error) {
  NProgress.done()
  // 对响应错误做点什么
  return Promise.reject(error)
})
export default instance