<template>
  <div>
    <p><strong>普昂医疗Promisemed一次性使用无菌胰岛素注射器在国际上相继拿到FDA、MDR证书后，顺利在2022年1月12日拿到国内医疗器械产品注册证。</strong></p>
    <img src="img/10-26/one.jpg" alt="">
    <strong class="center">↑↑↑  国内注册证  ↑↑↑</strong>
    <img src="img/10-26/a.png" alt="">
    <strong class="center">↑↑↑  FDA证书  ↑↑↑</strong>
    <img src="img/10-26/b.png" alt="">
    <strong class="center">↑↑↑  欧盟MDR证书  ↑↑↑</strong>
    <h4>产品介绍</h4>
    <img src="img/10-26/c.png" alt="">
    <p>一、极低残留设计</p>
    <p>一次性使用无菌胰岛素注射器采用低死角（LDS）极低残留设计，主要是为了防止药液注射后有较大的注射量残余在注射器内，浪费药液，提高用药效果。</p>
    <span class="center"><img src="img/10-26/d.jpg" alt=""></span>
    <div class="ct">
      <span class="back">高死角注射器和低死角(LDS)注射器对比</span>
    </div>
    <p>二、精准计量，测量可靠</p>
    <p>产品严格执行高标准计量方法，刻度精准，容量准确，帮助患者在使用过程中读数、测量轻松明确，一目了然，给予患者可靠的使用标准。</p>
    <p>三、进针顺滑，穿刺痛感低</p>
    <p>产品针管采用进口硅油，无毒无害，再加上普昂专利的硅油附着技术能够使注射器在进针拔针过程中痛感极低，穿刺顺滑，三切面针尖进针锋利，有效抑制疼痛。</p>
    <p>四、操控便捷，质量优越</p>
    <p>注射器推拉顺滑，滑动性能极佳；尾端有活塞止杆，防止拔出及药液漏出；针管粘结力高，避免针头松动。</p>
  </div>
</template>

<script>
export default {
  props: {},
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang='scss'>
.center{
  display: block;
  width: 100%;
  color: #9e1a34;
  text-align: center;
  margin-bottom: 40px;
}
img{
  max-width: 100%;
  text-align: center;
}
.ct{
  text-align: center;
  margin-bottom: 20px;
}
.back{
  background: #e6f6fb;
}
</style>