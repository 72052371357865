<template>
  <div @click="isShowMobile($event), isShow($event)" class="perInfo" :class="isMobile === null ? 'perInfo' : (isMobile && isMobile.includes('iPad')) ? 'perInfoiPad' : 'perInfoMobile'">
    <!-- 插槽放人物图片 -->
    <slot></slot>
    <p class="name">{{ name }}</p>
    <p class="info" v-html="info"></p>
    <!-- <p
      class="content"
      :class="isMobile === 'PC' && 'PC-lineHeight'">{{ content }}
      <span class="more" @click="isShow($event)"></span>
    </p> -->
  </div>
</template>

<script>
export default {
  inject: {
    isMobile: {
      default: null
    }
  },
  props: {
    name: {
      type: String,
      require: true
    },
    info: {
      type: String,
      require: true
    },
    content: {
      type: String,
      require: true
    },
    id: {
      require: true
    }
  },
  data () {
    return {
    }
  },
  components: {},
  created () {},
  mounted () {
  },
  computed: {},
  watch: {},
  methods: {
    isShow (event) {
      // 打开弹窗
      this.$emit('isShow', this.id, event)
    },
    isShowMobile (event) {
      if (this.isMobile !== null) {
        this.$emit('isShow', this.id, event)
      }
    }
  },
}
</script>
<style scoped lang='scss'>
.perInfoMobile{
  .name{
    font-size: .4rem !important;
    margin-top: .2667rem !important;
    font-weight: 500 !important;
  }
  .info{
    font-size: .32rem !important;
    margin-top: .16rem !important;
    margin-bottom: .4267rem !important;
    font-weight: 400 !important;
  }
  .content{
    color: #8D8D8D !important;
    line-height: .5333rem !important;
    font-size: .32rem !important;
    font-weight: 400 !important;

  }
  .more{
    width: .4267rem  !important;
    height: .4267rem !important;
    bottom:  .08rem!important;
  }
}
.perInfoiPad{
  .name{
    font-size: .3rem !important;
    margin-top: .2667rem !important;
    font-weight: 500 !important;
  }
  .info{
    font-size: .2rem !important;
    margin-top: .16rem !important;
    margin-bottom: .4267rem !important;
    font-weight: 400 !important;
  }
  .content{
    color: #8D8D8D !important;
    line-height: .5333rem !important;
    font-size: .27rem !important;
    font-weight: 400 !important;

  }
  .more{
    width: .4267rem  !important;
    height: .4267rem !important;
    bottom:  .08rem!important;
  }
}
.perInfo{
  cursor: pointer;
  // border: 1px solid red;
  width: 100%;
  .name{
    width: 100%;
    margin-top: .104167rem;
    margin-bottom: 0.064167rem;
    text-align: center;
     color: rgba(0,0,0,0.8);
    font-size: .125rem;
    font-weight: 500;
  }
  .info{
    // margin-top: .0347rem;
    font-size: .125rem;
    color: rgba(0,0,0,0.8);
    font-weight: normal;
    white-space:nowrap;
    text-align: center;
  }

  .content{
    position: relative;
    margin-top: .138889rem;
    color: rgba(141,141,141,1);
    font-size: .111111rem;
    font-weight: normal;
    line-height: .179rem;
    text-align: left;
    width: 100%;
    border: 1px solid rgb(242, 242, 242);

    word-break:break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;

    .more{
      position: absolute;
      right: .0139rem;
      bottom: .0139rem;
      width: .138889rem;
      height: .138889rem;
      display: inline-block;
      background: url('/img/more.png') no-repeat ;
      background-size: 100%;
      cursor: pointer;
    }
  }
}
</style>