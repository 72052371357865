<template>
  <div :class="(isMobile !== null && (isMobile.includes('Android') || isMobile.includes('iPhone'))) ? 'mobile' : 'pc'">
    <img src="/img/10-26/image30.jpeg" alt="" class="img25">
    <p>新世纪以来，生物医学基础科研的快速发展，推动了针对各类疾病的新治疗手段和药物的研发和转化。以肿瘤为例，基于肿瘤免疫基础理论研究的进步，相应治疗药物研发已然如火如荼展开。有分析报告指出，2022 年底，预计将共计有 17 款 PD-1/PD-L1 单抗、1 款 PD-1/CTLA-4 双抗在国内上市。</p>
    <p>“诱发肿瘤的遗传和非遗传因素占比大约各占一半，针对遗传因素的靶向治疗药物已有长足发展。然而，针对非遗传因素包括表观遗传调节的疗法相对有限。 <strong class="color">后 PD-1 时代，还存在大量未满足临床需求的深水区，这些为创新机理的转化应用留下了用武之地，基于表观遗传调控新机理的治疗手段在新药研发中具有不可限量的发展机遇</strong> 。” 赛岚医药创始人兼 CEO 吴海平博士说。</p>
    <img src="/img/10-26/image31.jpeg" alt="" class="img31">
    <span class="zhujie">图 | 赛岚医药创始人、董事长兼 CEO 吴海平博士（来源：受访者提供）</span>
    <p>吴海平拥有近 20 年表观遗传学研究和肿瘤药物研发经验，曾在诺华生物医学研发部门任职多年，同时也拥有丰富的连续创业经验；另一位联合创始人、兼 COO 米沅博士曾在哈佛大学进行博士后研究，拥有二十年小分子创新药研究和开发的医药工业界经验，先后参与和领导了多个项目进入临床甚至上市。此前，米沅在诺华担任药物化学和运营部门全球团队负责人，积累了丰富的研发和运营管理经验。</p>
    <p>源头创新离不开与基础科学的合作和转化，赛岚的科学创始人徐国良院士，是中国当代表观遗传学研究领域的代表性人物，几十年来一直专注于在表观遗传调控及其在肿瘤等疾病中发生机制的研究。2019 年，基于创新转化的共同理念，吴海平、米沅和徐国良三位联合创立赛岚医药（CytosinLab Therapeutics）， <strong class="color">成立后的公司希望能基于在表观遗传学研究领域多年的成果和积累，快速推进源头创新研究成果的转化工作。</strong> </p>
    <p>这是一家基于“新一代”表观遗传学机理开发创新型小分子药物等疗法的生物科技公司。基于专有的表观遗传学靶点和药物发现平台，已启动近 10 个在研项目，包括多个源头创新靶点和机理的 FIC 项目，以及生物机理清晰、联用机会广阔的自研管线，<strong class="color">首条管线预计会在今年底或者明年初进入临床研究阶段。</strong></p>
    <img src="/img/10-26/image32.jpeg" alt="" class="img32">
    <span class="zhujie">图 | 赛岚医药位于上海张江的研发中心办公室（来源：受访者提供）</span>
    <p>今年 1 月，赛岚医药刚刚宣布完成泽悦创投和鼎晖 VGC 联合领投近 2 亿人民币的 A 轮融资，并计划今年启动下一轮融资，用于支持管线的临床研究，以及持续建设技术平台并进一步深化转化创新能力。</p>
    <img src="/img/10-26/image33.jpeg" alt="" class="img33 num">
    <h4 class="tac">开发 “新一代” 表观遗传学管线，首条管线接近临床阶段</h4>
    <p>上世纪 50 年代，英国发育生物学家 Conrad Waddington 首次提出了表观遗传学概念。表观遗传学主要研究非 DNA 序列改变引发的基因表达水平变化，包括 DNA 甲基化、组蛋白修饰以及非编码 RNA 等。</p>
    <p>近 20 余年来，随着现代分子生物学技术的进步以及后基因组时代对基因功能调控的深入探索，表观遗传学基础研究得到迅猛发展。值得指出的是，近年来国内这一领域的基础研究方兴未艾，成果颇多。</p>
    <p> <strong class="color">调控表观遗传学进行治疗已然成为新药研发的重要方向之一。</strong> 其中针对 DNA 甲基转移酶抑制剂、组蛋白修饰酶抑制剂和 siRNA 等在内的表观遗传药物研发最为常见。自 2000 年以来，FDA、NMPA 已批准了约 12 款表观遗传学领域药物上市，现阶段还有几百项临床研究和药物联用研究。整体回顾，基于表观遗传学机理的新药研发历程，大体可以经历了几个阶段。</p>
    <img src="/img/10-26/image34.png" alt="" class="img34">
    <p>2000 年初，第一阶段的药物是一些非特异性的表观遗传小分子药物，以 DNA 甲基化酶抑制性药物和组蛋白去乙酰化酶抑制性药物为代表。比如，DNA 甲基化抑制剂阿扎胞苷（azacitidine）和地西他滨（decitabine）已获批上市用于治疗骨髓增生异常综合征，这一时期也被称为表观遗传治疗药物的元年。</p>
    <p>这类药物长时间、低剂量使用可诱导肿瘤特别是血液肿瘤分化，在癌症治疗中有一定的效果，但是限于化学结构等原因选择性差、毒副作用大、应答率低，且在实体瘤中治疗效果有限。</p>
    <p>基础科学随后的进展为解决这些问题提供了新思路。2010 年来，该领域的药物发展进入第二阶段（例如靶向 EZH2、DOT1L、IDH1/2 等的组蛋白修饰或调控因子）得以发展，包括他泽司他 (tazemetostat)，恩西地平（enasidenib），Tibsovo（ivosidenib）等先后获批上市。这些药物能够靶向特定基因突变的肿瘤，精准性更高、毒副作用降低，特别需要指出的是由于应用了创新机理，且对病患人群高度选择性，临床开发速度大大加快，一般二期完成即可获批，但也面临适应症患者群体仍以血液肿瘤为主等应用限制。</p>
    <p>“第一代药物，主要起到了概念验证作用，证明了基于表观遗传学机理研发新药的可行性；第二代药物，得益于基础科研的进步，科学家们发现了更多重要的表观遗传学新靶点，为实现靶向精准治疗提供了机遇； <strong class="color">随着新靶点和新机制的不断涌现，新一代药物将以更安全有效和更广泛的适应症（包括实体瘤）为主，并通过联用治疗对肿瘤微环境重编程，实现 1+1>2 的效果</strong> 。” 吴海平说。</p>
    <p>值得一提的是，“新一代” 表观遗传治疗也已初步取得了一些进展。比如说，基于合成致死新机制的 Tazemetostat 在实体瘤适应症上首次获批；联用治疗方式也显示出了治疗潜力，阿扎胞苷和口服靶向 BCL-2 的小分子药物维奈托克（Venetoclax）一线治疗急性髓系白血病获批且效果显著；与此同时，PROTAC、ADC、siRNA、mRNA 等新型治疗方式也得以发展，这些新的药物形式（drug modality）也有望为新一代表观遗传学治疗提供重大机遇。</p>
    <p>据吴海平介绍， <strong class="color">赛岚医药将会开发 “新一代” 表观遗传治疗药物，实现 “内外兼修”</strong> 。也就是说既靶向肿瘤自身的新机理和新靶点，也包括针对性调节和重塑肿瘤所依赖的微环境，可以利用表观遗传药物的 “重编程” 机制，以及与靶向药、肿瘤免疫疗法联用等方式。</p>
    <p>基于这些策略，该公司搭建了表观遗传靶点和药物发现的专有技术平台 EpigenPLUS platform。根据官网的介绍，该平台既可以针对单个靶点，也可以针对相关表观遗传学调控机制，同时也拥有表观遗传学领域成体系的工具化合物组和体内、体外药效模型群。</p>
    <p> <strong class="color">基于该平台，目前已持续输出在研项目，近 10 个项目处于临床前各阶段，涵盖全球源头创新的 FIC 候选药物和对标临床上已初步得到探索的 BIC 候选药物</strong> 。其中，已有多个小分子新药项目已进入 IND 申请阶段，<strong class="color">首个产品预计会在今年底或者明年初进入临床研究阶段。</strong></p>
    <p>此外，赛岚医药后续的全球原始创新管线，还将会与上文提及的徐国良院士等中国一批优秀的领域科学家及科研院所合作，完成新机理和新靶点的验证，实现从源头创新到药物发现的转化。</p>
    <img src="/img/10-26/image35.jpeg" alt="" class="img35 num">
    <p>“赛岚医药的另一大特色在于平台化思维，平台化思维会贯穿在技术平台搭建和管线开发过程中，最终要实现 Epigenetics Plus and Beyond Epigenetics。现阶段，我们要在临床上快速验证源头创新和差异化创新靶点； <strong class="color">中长期而言，我们的目标是扎实推进平台搭建，抓住表观遗传学赛道的风口</strong> 。” 米沅说。</p>
    <p>在这一理念之下，他们提出了表观遗传的 “PLUS” 策略，包括平台能力建设、管线开发实力的拓展等等。</p>
    <p>其中 EpigenPLUS 平台的建设也是公司长期发展的目标。首先，该平台拟整合新药研发中的多种功能，包括靶点发现能力、药物工具模型、分子优化功能。同时，平台化能力的增强有助于系统性地提高靶点发现、机理研究的科学性，强化管线研发能力和拓展能力。在平台的支持下，能够源源不断输出管线，并与其他治疗方法组成新疗法，有望实现 1+1>2 的治疗效果。</p>
    <img src="/img/10-26/image36.png" alt="" class="img36">
    <span class="zhujie">图 | EpigenPLUS 平台（来源：赛岚医药官网）</span>
    <p>目前，在研管线均以治疗肿瘤的小分子药物为主。“小分子药物只是一种治疗方式，平台的潜能不止于此。EpigenPLUS 平台整合了靶点、模型、适应症转化等多样功能，在这些基础设施之上，未来我们完全有能力开发其他类型的表观遗传学药物。” 米沅补充道。</p>
    <p>基础设施搭建完成后，可以根据需求特异性在工具箱中选择药物的类型，评估小分子药物、RNA 技术、PDC 或者基因编辑疗法哪种方式效果更佳。</p>
    <p>
      “同时，我们也要认识到表观遗传学是一个非常广的领域，涉及不同的研究领域，不同的适应症。 <strong class="color">我们首先以肿瘤为切入点，后续也会延伸到衰老相关疾病、神经、炎症以及免疫疾病中，在适应症上实现表观遗传 Plus。</strong> ” 米沅补充道。
    </p>
    <img src="/img/10-26/image37.png" alt="" class="img37">
    <span class="zhujie">图 | 科研人员正在研发中心实验室进行细胞生物学实验（来源：受访者提供）</span>
    <p>他还告诉生辉，除了自研管线以外，平台也有潜力对接对外合作，这也能够帮助公司把握更多创新的机会。同时，对外合作和自研管线也能够反过来强化平台能力。不久前，赛岚医药与罗氏上海创新中心正式达成研发合作协议，双方将会基于 EpigenPLUS 技术平台和多种研究模型，评估并选择至少一个 FIC 靶点共同推进靶点验证等研发工作。</p>
    <p>对于公司未来的发展规划，吴海平和米沅也有自己的考量。“一方面，我们坚持产品为王，要开发出具有代表性和突破性的产品，并推进我们的创新产品在临床上得以验证；另一方面，更要有持续输出产品的能力，把握赛道发展的机遇；更长远来看，我们希望扎扎实实奋斗，成长为能够发现并引领表观遗传学行业风口的企业。”</p>
    <img src="/img/10-26/image38.png" alt="" class="img37">

  </div>
</template>

<script>
export default {
  props: {},
  inject: {
    isMobile: {
      default: null,
    },
  },
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang="scss">
img{
  display: block;
  margin: 0 auto;
}
.mobile{
  h4{
    line-height: unset  !important;
  }
}
.color{
  color: rgb(0, 122, 170);
}
.tac{
  text-align: center;
  color: rgb(0, 122, 170);

}
</style>
