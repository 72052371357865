import request from '../axios'
import { path } from '../path.js'

export const getCaptcha = () => request({
  url: `${path}captcha`,
  method: 'get',
})
export const getStsToken = () => request({
  url: `${path}stsToken`
})
// 获取上传至oss的文件前缀路径
export const getOssUrlPrefix = (params) => {
  return request({
    url: `${path}file/archiveCategory/urlPrefix`,
    method: 'get',
    params
  })
}

// 病种列表
export const getDiseaseList = (current, size, params) => {
  return request({
    url: `/api/patient/diseaseType`,
    method: 'get',
    data: {
      ...params,
      current,
      size,
    }
  })
}
export const DiseaseList = (current, size, params) => {
  return request({
    url: `/api/admin/diseaseType`,
    method: 'get',
    data: {
      ...params,
      current,
      size,
    }
  }).then(res => {
    return res.data
  })
}
// 上传文件
export const putFile = (data, config) => {
  return request({
    headers: config,
    url: '/api/blade-resource/oss/endpoint/put-file',
    method: 'post',
    data
  })
}
