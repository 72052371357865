<template>
  <div class="banner" :style="{backgroundSize: isMobile === null ? '100%' : '100% 5.3333rem'}">
    <!-- 泽悦描述 -->
    <!-- :class="{describe: isMobile === null, describeiPad: (isMobile && isMobile.includes('iPad')), describeMobile: (!(isMobile && isMobile.includes('iPad')) && isMobile !== null)}" -->
    <div
      :class="isMobile === null ? 'describe' : (isMobile && isMobile.includes('iPad')) ? 'describeiPad' : 'describeMobile'"
    >
      <Chuang />
      <!-- PC 端 -->
      <div
        class=" wow fadeIn text scrollReveal"
        v-if="isMobile === null"
        :data-wow-duration="animateConfig.duration"
        :data-wow-delay="animateConfig.delay"
        v-scroll-reveal.reset="scrollSet"
      >
        <p class="white"><span>泽悦创投</span>专注于医疗大健康领域的股权投资，</p>
        <p class="white">由行业资深人士共同创立，拥有丰富的行业资源和差异化的投资理念。 </p>
        <p>基于对市场环境、医改趋势的深刻洞见，整合研发、生产、营销资源，</p>
        <p>寻找具有“底层创新”和“全球化商业潜力”的创业项目，以长期价值为基础做出投资决策。</p>
        <br />
        <p>团队涵盖金融投资、新药发现及临床转化、先进医械、商业营销实践等多元化背景，</p>
        <p>善于提供深度投后服务，帮助受资企业发展“内生外延”的核心竞争力。</p>
      </div>
      <!-- 移动端 -->
      <div class="text" v-else-if="(isMobile && isMobile.includes('iPad'))">
        <p class="white">泽悦创投专注于医疗大健康领域的股权投资，由行业资深人士</p>
        <p class="white">共同创立，拥有丰富的行业资源和差异化的投资理念。基于对</p>
        <p class="con">市场环境、医改趋势的深刻洞见，整合研发、生产、营销资源，寻找具有“底层创新”和“全球化商业潜力”的创业项目，以长期价值为基础做出投资决策。
          团队涵盖金融投资、新药发现及临床转化、先进医械、商业营销实践等多元化背景，善于提供深度投后服务，帮助受资企业发展“内生外延”的核心竞争力。
        </p>
      </div>
      <!-- ipad -->
      <div class="text" v-else>
        <p class="white">泽悦创投专注于医疗大健康领域的股权</p>
        <p class="white">投资，由行业资深人士共同创立，拥有</p>
        <p class="con">丰富的行业资源和差异化的投资理念。基于对市场环境、医改趋势的深刻洞见，整合研发、生产、营销资源，寻找具有“底层创新”和“全球化商业潜力”的创业项目，以长期价值为基础做出投资决策。
          团队涵盖金融投资、新药发现及临床转化、先进医械、商业营销实践等多元化背景，善于提供深度投后服务，帮助受资企业发展“内生外延”的核心竞争力。
        </p>
      </div>
    </div>
    <div style="clear: botn"></div>
  </div>
</template>
<script>
import { WOW, wowInit, animateConfig, scrollSet } from '@/util/scrollReveal.js'
import Chuang from '@/views/home/component/bigText'
export default {
  naem: 'Banner1',
  props: {},
  inject: {
    isMobile: {
      default: null
    }
  },
  data () {
    return {
      animateConfig,
      scrollSet,
    }
  },
  components: {
    Chuang
  },
  created () {},
  mounted () {
    const wowi = new WOW(wowInit)
    wowi.init()
    if (this.isMobile !== null) {
      document.querySelector('.con').style.width = document.querySelector('.white').offsetWidth + 'px'
    }
  },
  updated () {
    if (this.isMobile !== null) {
      document.querySelector('.con').style.width = document.querySelector('.white').offsetWidth + 'px'
    }
  },
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang='scss'>
  .banner{
    width: 10rem;
    background: url('/img/bannar1.png') no-repeat;
    background-size: 100% 5.3333rem;
    overflow: hidden;
    .describe{
      width: 100%;
      height: 2.798611rem;
      margin: 3.076389rem 0 .361111rem .909722rem;
      background: rgba(255, 255, 255, 0.3);
      .bigText{
        margin: .145833rem 0 0 .2777rem;
        background: url('/img/chuang.png') no-repeat;
        background-size: 90%;
        float: left;
      }
      .text{
        margin-top: .5rem;
        float: left;
        font-size: 0.125rem;
        line-height: .277778rem;
        letter-spacing: .010417rem;
        font-weight: lighter;
        color: rgba(0, 0, 0, 1);
        .white{
          color: rgb(255, 255, 255);
        }
        span{
          letter-spacing: .020833rem;
          color: rgb(255, 255, 255);
          font-size: .166667rem;
          font-weight: lighter;
        }
      }
    }
    .describeMobile{
      margin-top: 3.12rem;
      margin-left: .4267rem;
      line-height: .6667rem;
      color: rgba($color: #000000, $alpha: 1) ;
      background: rgba(255, 255, 255, 0.3);
      overflow: hidden;
      .bigText{
        width: 1.4933rem;
        height: 1.6rem;
        margin-top: .4267rem;
        margin-left: .5rem;
        background: url('/img/chuang.png') no-repeat;
        background-size: 100%;
        float: left;
      }
      .text{
        // border: 1px solid red;
        margin: .85rem .2133rem 0 0;
        padding-right: .6133rem;
        padding-left: 2.4rem;
        font-size: .3733rem;
        font-weight: 400;
        .white{
          display: inline-block;
          text-align: justify;
          color: #fff;
          white-space: nowrap;
        }
        p{
          display: inline-block;
          text-align: justify;
        }
        .title{
          letter-spacing: .0267rem;
          font-size: .48rem;
        }
      }
    }
    .describeiPad{
      margin-top: 3.12rem;
      margin-left: .4267rem;
      line-height: .6667rem;
      color: rgba($color: #000000, $alpha: 1) ;
      background: rgba(255, 255, 255, 0.3);
      overflow: hidden;
      .bigText{
        width: 1.4933rem;
        height: 1.6rem;
        margin-top: .4267rem;
        margin-left: .5rem;
        background: url('/img/chuang.png') no-repeat;
        background-size: 100%;
        float: left;
      }
      .text{
        margin: .85rem .2133rem 0 0;
        padding-right: .6133rem;
        padding-left: 2.4rem;
        font-size: .25rem;
        font-weight: 400;
        .white{
          display: inline-block;
          text-align: justify;
          color: #fff;
          white-space: nowrap;
        }
        p{
          display: inline-block;
          text-align: justify;
        }
        .title{
          letter-spacing: .0267rem;
          font-size: .48rem;
        }
      }
    }
  }
</style>