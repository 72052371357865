<template>
  <div :class="(isMobile !== null && (isMobile.includes('Android') || isMobile.includes('iPhone'))) ? 'mobile' : 'pc'">
    <p class="one"><strong>继上周成功完成数亿元人民币C轮融资后，奥然生物再传喜讯：奥然明星产品全自动全密封荧光PCR一体机（Galaxy Nano/Galaxy Lite/Galaxy Pro）及自主研发生产的2个新产品 Solaris Lite 快速qPCR检测仪，Galaxy US 超声破壁仪共5个产品获得欧盟IVDR CE 认证</strong> </p>
    <img src="/img/10-26/image17.jpeg" alt="" class="img17">
    <p>CE认证视为产品进入欧洲市场的“签证”，是欧盟市场对于产品准入的强制性要求。即将实施的体外诊断医疗器械法规（IVDR）较原体外诊断设备指令（IVDD）相比，新法规IVDR在法规层级上从原先的Directive（指令）上升为Regulation（法规），提高了产品获得欧盟市场准入门槛，并且对其监管更加严格。</p>
    <img src="/img/10-26/image18.png" alt="" class="img18">
    <p class="back">2022年5月26日IVDR开始强制实施，欧盟作为奥然生物核心目标市场之一， <strong>此时5个产品获得欧盟IVDR CE认证，标志着奥然生物已做好应对IVDR时代的准备，将继续秉承为用户提供更高品质的产品和服务</strong> </p>
    <div class="list"> <span>1</span> 明星产品-全自动全密封荧光PCR一体机</div>
    <p>全自动全密封荧光PCR一体机集核酸提取、扩增、分析于一体，操作简单，智能化实现核酸提取至荧光PCR分析的全流程实验，真正实现样本进、结果出。</p>
    <img src="/img/10-26/image19.jpeg" alt="" class="img18">
    <p></p>
    <div class="list"> <span>2</span> 新产品- Solaris Lite 快速qPCR检测仪</div>
    <p>Solaris Lite可快速升降温，极大缩短PCR检测时间，30分钟出检测结果，同时实现7色荧光检测，2个独立反应模块（2*8）的结构设计形式，让该设备具有了速度快，多重检测、灵活通量的优势特点。</p>
    <img src="/img/10-26/image20.jpeg" alt="" class="img18">
    <p></p>
    <div class="list"> <span>3</span> 新产品- Galaxy US 超声破壁仪</div>
    <p>Galaxy US超声破壁仪与超声管配套使用，一键启动，便可在全密封的超声管内数秒完成细菌破壁处理，无污染、更安全。</p>
    <img src="/img/10-26/image21.jpeg" alt="" class="img18">
    <p></p>
    <p>截至今日，奥然生物全自动全密封荧光PCR一体机及配套核酸检测试剂盒等共计15个产品获得欧盟CE资质，其中5个产品获得欧盟IVDR CE认证。</p>
    <img src="/img/10-26/image22.jpeg" alt="" class="img18">
  </div>
</template>

<script>
export default {
  props: {},
  inject: {
    isMobile: {
      default: null,
    },
  },
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang="scss">
.mobile{
  .list{
    font-size: 0.3733rem  !important;
    margin-bottom: .2083rem;
    font-weight: bold;
  }
}
img{
  display: block;
  margin: 0 auto;
}
.pc{

.one{
  color: #0e4fa8 !important;
}
.back{
  background: #f6fbfe;
  padding: .2083rem;
  box-sizing: border-box;
}
.list{
  span{
    display: inline-block;
    width: .2014rem;
    height: .2014rem;
    margin-right: .0694rem;
    background: #0e4fa8;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    line-height: .2014rem;
    font-weight: bold;
  }
}
}
</style>
