<template>
  <div>
    <div class="top" v-if="!(isMobile !== null && (isMobile.includes('Android') || isMobile.includes('iPhone')))">
      <span class="yuan">虎</span>
      <span class="center">虎年新禧</span>
      <span class="yuan">年</span>
    </div>
    <p></p>
    <p class="color">
      热烈祝贺奥然生物
      <strong>全自动核酸检测分析系统（型号：Galaxy Pro）</strong>
      喜获国家药品监督管理局（NMPA）三类医疗器械注册证，注册证编号：<strong
      >国械注准20223220070。</strong
      >
    </p>
    <img class="img1" src="/img/10-26/image1.jpeg" alt="" />
    <p></p>
    <img class="img2" src="/img/10-26/image13.jpeg" alt="" />
    <p></p>
    <p>
      奥然生物全自动全密封荧光PCR系列仪器的多个产品已获得市场准入，
      <strong
      >而与此配套的多个核酸检测试剂盒产品（iCassette智能盒检测试剂）也已相继进入注册检、多中心临床试验评估等</strong
      >
      不同注册审批阶段，奥然生物自主研发、生产的即时分子诊断系列产品将
      <strong>更进一步</strong> 用于 <strong>临床应用</strong> ！
    </p>
    <p>
      在不久的将来，奥然生物提供
      <strong>全自动全密封荧光PCR一体机平台解决方案</strong>
      将逐步落地各层级医院，让 <strong>核酸检测</strong> 更
      <strong>安全</strong> 、更 <strong>便捷</strong> 、更
      <strong>高效</strong> 的辅助 <strong>临床分子诊断业务</strong> 的开展，
      <strong>更好的</strong> 服务广泛的客户群体。
    </p>
    <div :class="(isMobile !== null && (isMobile.includes('Android') || isMobile.includes('iPhone'))) ? 'regard-moblie' : 'regard'">
      <h4>关于奥然生物</h4>
      <div v-if="isMobile !== null && (isMobile.includes('Android') || isMobile.includes('iPhone'))">
        <p>奥然生物科技（上海）有限公司成立于2015年，成立之日起就专注于即时分子诊断技术，创立者在美国硅谷和中国大陆均有成功的创业经历，其核心技术团队拥有多年的分子诊断领域研发经验。公司总部坐落于上海国际医学园区，研发生产面积超过4000平米，并在广州国际生物岛设有750平米试剂研发中心，深圳2300平米的仪器和智能盒自动化产线，业务范围分布全国及海外各地。奥然生物具有核心的自主知识产权、强大的科技创新与成果转化能力，被国家评定为高新技术企业。至2021年12月，奥然生物申请知识产权总计95项，获批73项，其中64项专利授权；申请5项国际专利；获得9项软件著作权；申请22项商标，获得11项授权。奥然生物已获得4个三类医疗器械注册证；公司自主研发的全自动全密封荧光PCR一体机（Galaxy Nano/ Galaxy Lite/ Galaxy Pro）及配套的多种核酸检测试剂产品已获欧盟CE认证。创新的分子诊断技术和产品获得了专业机构的青睐，奥然生物于2018年入选国家“十三五”传染病重大专项“结核病多重组合诊断技术的多中心评估”课题，公司的 IGS TB/RIF Lite 产品参与评估，并已通过评估验收。2020年，奥然生物被上海市经信委列为疫情防控应急征用单位。</p>
      </div>
      <div v-else>
        奥然生物科技（上海）有限公司成立于2015年，成立之日起就专注于即时分子诊断技术，创立者在美国硅谷和中国大陆均有成功的创业经历，其核心技术团队拥有多年的分子诊断领域研发经验。公司总部坐落于上海国际医学园区，研发生产面积超过4000平米，并在广州国际生物岛设有750平米试剂研发中心，深圳2300平米的仪器和智能盒自动化产线，业务范围分布全国及海外各地。奥然生物具有核心的自主知识产权、强大的科技创新与成果转化能力，被国家评定为高新技术企业。至2021年12月，奥然生物申请知识产权总计95项，获批73项，其中64项专利授权；申请5项国际专利；获得9项软件著作权；申请22项商标，获得11项授权。奥然生物已获得4个三类医疗器械注册证；公司自主研发的全自动全密封荧光PCR一体机（Galaxy Nano/ Galaxy Lite/ Galaxy Pro）及配套的多种核酸检测试剂产品已获欧盟CE认证。创新的分子诊断技术和产品获得了专业机构的青睐，奥然生物于2018年入选国家“十三五”传染病重大专项“结核病多重组合诊断技术的多中心评估”课题，公司的 IGS TB/RIF Lite 产品参与评估，并已通过评估验收。2020年，奥然生物被上海市经信委列为疫情防控应急征用单位。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  inject: {
    isMobile: {
      default: null,
    },
  },
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {
  },
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang="scss">
.top {
  text-align: center;
  color: #df2f23 !important;
  .yuan {
    display: inline-block;
    width: 0.1458rem;
    height: 0.1458rem;
    line-height: 0.1458rem;
    color: white;
    background: #df2f23;
    border-radius: 50%;
    font-size: 0.0972rem;
  }
  .center {
    border-bottom: #df2f23 1px solid;
    margin: 0 0.2083rem;
    padding-bottom: 0.0347rem;
  }
}
.color {
  color: #0e4fa8 !important;
}
.img1 {
  display: block;
  margin: 0 auto;
  width: 70%;
  margin-top: -0.1389rem;
  box-shadow: 0 0 15px 0 #717171;
}
.img2 {
  display: block;
  margin: 0 auto;
  width: 70%;
}
.regard-moblie{

}
.regard{
  width: 90%;
  margin: 0 auto;
  padding: .1389rem;
  border: 1px dashed #0e4fa8;
  h4{
    margin: 0 auto ;
    margin-top: -0.3472rem;
    width: 1.0625rem;
    height: .3333rem;
    text-align: center;
    background: #e2ecfb;
    color: #0e4fa8;
  }
  div{
    font-size: .1042rem;
    font-weight: lighter;

  }
}
</style>
