<template>
  <div :class="isMobile === null ? 'content3' : 'content3Mobile'">
    <div class="title">
      <Ye />
      <TitleText :cn="'投资企业（部分）'" :en="'Our Portfolio'"/>
    </div>
    <div class="main" @mouseover="mouseIn" @mouseout="autoPlay">
      <ul
        :style="{width: `${list.length*100}%`,position: 'relative', right: `${num*100}%`,transition: `${transition}s`}"
        :data-wow-duration="animateConfig.duration"
        v-scroll-reveal.reset="scrollSet"
        :data-wow-delay="animateConfig.delay"
        class="wow fadeIn">
        <li ref="li" v-for="(item, index) in list" :key="index">
          <!-- <div class="title">{{ item[0] }}</div> -->
          <!-- allImg 设置所有图片的位置 -->
          <div class="allImg">
            <!-- <div
              @click.prevent.stop="openDialog(itemi)"
              v-for="(itemi, index1) in item[1]"
              :class="index1 === 0 && 'clearMargin'"
              :key="index1"
              :style="{background: `url(${ itemi.imgUrl }) no-repeat center`,backgroundSize: `100% 100%`}"></div> -->
            <img
              class="img"
              :class="index1 === 0 && 'clearMargin'"
              v-for="(itemi, index1) in item[1]"
              @click.prevent.stop="openDialog(itemi)"
              :key="index1"
              :src="itemi.imgUrl"
              alt="">
          </div>
        </li>
        <div style="width:0; height: 0; clear: both;"></div>
      </ul>
      <div class="controller">
        <!-- 第一个元素清除外边距 指定元素添加高亮效果 -->
        <span class="trabecula" @click="num = index" v-for="(item, index) in list.length" :class="{'clearMargin': index === 0, 'active': index === num}" :key="index"/>
      </div>
    </div>
    <!-- <transition name="fade"> -->
    <transition>
      <Dialog class="dialogclose" v-if="$store.state.dialog.diaLogIsShowCentent3" :isContent3="isContent3" :dialogCentent="dialogCentent" @closeDialog="closeDialog"/>
    </transition>
  </div>
</template>

<script>
import Ye from '@/views/home/component/bigText'
import TitleText from '@/views/home/component/titleText'
import Dialog from '@/views/home/component/content/dialog'
import { WOW, wowInit, animateConfig, scrollSet } from '@/util/scrollReveal.js'
import { mapMutations } from 'vuex'
export default {
  props: {},
  inject: {
    isMobile: {
      default: null
    }
  },
  data () {
    return {
      animateConfig,
      scrollSet,
      list: [
        ['', [{
          title: '奥精医疗科技（688613）',
          photo1: '/img/aoJing.svg',
          imgUrl: '/img/aoJing.svg',
          official: 'http://www.allgensmed.cn',
          contentAll: '高端再生医学材料及植入类医疗器械公司。'
        }, {
          title: '奥然生物',
          photo1: '/img/aoRan.svg',
          imgUrl: '/img/aoRan.svg',
          official: 'http://www.igenesisbio.com',
          contentAll: '专注于分子诊断POCT及相应试剂。'
        }, {
          title: '恩盛',
          photo1: '/img/enSheng.svg',
          imgUrl: '/img/enSheng.svg',
          official: null,
          contentAll: '专注研发外周静脉疾病整体解决方案医疗器械公司。'
        }, {
          title: '高光制药',
          photo1: '/img/gaoGuang.svg',
          imgUrl: '/img/gaoGuang.svg',
          official: 'http://www.highlightllpharma.com',
          contentAll: '自身免疫性疾病和肿瘤领域新药研发公司。'
        }, {
          title: '劲方医药',
          photo1: '/img/jinFang.svg',
          imgUrl: '/img/jinFang.svg',
          official: 'http://www.genfleet.com',
          contentAll: '专注于肿瘤、自身免疫性疾病等领域创新药研发公司。'
        }, {
          title: '纽脉医疗',
          photo1: '/img/niuMai.svg',
          imgUrl: '/img/niuMai.svg',
          official: 'https://www.newmed.cn',
          contentAll: '专注于开发治疗结构性心脏病介入产品的心脏瓣膜器械公司'
        }, {
          title: '普昂医疗',
          photo1: '/img/puAng.png',
          imgUrl: '/img/puAng.png',
          official: 'http://www.promisemed.cn',
          contentAll: '专注于糖尿病治疗、肿瘤诊疗、给药系统等医疗器械公司，主要产品包括胰岛素笔针、安全笔针、血针、活检针等穿刺类耗材。'
        }, {
          title: '清谱科技',
          photo1: '/img/qingPu.svg',
          imgUrl: '/img/qingPu.svg',
          official: 'http://www.purspec.cn',
          contentAll: '专注于高端质谱分析产品的开发、生产和制造，主要产品包括多款便携式质谱仪器，MS Mate质谱仪原位电离离子源及ΩAnalyzer脂质双键定位分析系统等。'
        }]],
        ['', [{
          title: '赛德迪康',
          photo1: '/img/saiDe.svg',
          imgUrl: '/img/saiDe.svg',
          official: null,
          contentAll: '拥有全球领先的组织工程及再生医学创新技术平台的医疗器械公司，目前主要产品为组织工程皮肤。'
        }, {
          title: '赛岚',
          photo1: '/img/saiLan.svg',
          imgUrl: '/img/saiLan.svg',
          official: 'http://www.cytosinlab.com',
          contentAll: '专注于表观遗传学领域治疗技术和新药研发。',
        }, {
          title: '上药云健康',
          photo1: '/img/shangYaoYun.svg',
          imgUrl: '/img/shangYaoYun.svg',
          official: 'https://www.sphcchina.com',
          contentAll: '中国领先的 “互联网+”医药商业科技平台，提供以专业药房为基础的创新药全生命周期服务、以电子处方+云药房为基础的普慢药“互联网+”服务，开创医药分业中国模式。',
        }, {
          title: '数鸣科技',
          photo1: '/img/shuMing.svg',
          imgUrl: '/img/shuMing.svg',
          official: 'http://www.shumingdata.com',
          contentAll: '专注于利用人工智能算法，使用深度数据挖掘技术，通过拥有自主知识产权的SaaS云端服务和核心算法为保险、大健康、金融等行业头部客户提供用户消费行为预测分析服务。',
        }, {
          title: '心凯诺医疗',
          photo1: '/img/xinKaiNuo.svg',
          imgUrl: '/img/xinKaiNuo.svg',
          official: 'http://skynormedical.com',
          contentAll: '专注于研发高端血管微创介入医疗器械，拥有神经介入和外周介入两大产品线。',
        }, {
          title: '一脉阳光',
          photo1: '/img/yiMo.svg',
          imgUrl: '/img/yiMo.svg',
          official: 'https://www.rimag.com.cn/',
          contentAll: '从事医学独立影像中心运营、影像云平台技术开发、医学影像人才培养专业性公司。',
        }, {
          title: '挚盟医药',
          photo1: '/img/zhiMeng.svg',
          imgUrl: '/img/zhiMeng.svg',
          official: 'http://www.core-biopharma.com/',
          contentAll: '致力于慢性乙型肝炎（CHB）和中枢神经系统的创新药物研发公司。',
        }, {
          title: '免疫性疾病和肿瘤领域新药研发公司',
          photo1: '/img/mianYiXingJiBing.jpg',
          imgUrl: '/img/mianYiXingJiBing.jpg',
          official: null,
          contentAll: '免疫性疾病和肿瘤领域新药研发公司',
        }]]],
      num: 0, // 偏移量
      timer: null,
      transition: 0.5,
      isShow: false,
      dialogCentent: [],
      isContent3: ''
    }
  },
  components: {
    Ye,
    TitleText,
    Dialog
  },
  created () {
    this.autoPlay()
  },
  mounted () {
    const wowi = new WOW(wowInit)
    wowi.init()
  },
  computed: {},
  watch: {
  },
  methods: {
    // 自动轮播函数
    autoPlay () {
      var listLength = this.list.length
      this.timer = setInterval(() => {
        this.transition = 0.5
        this.num <= listLength - 1 && this.num === listLength - 1 ? this.num = 0 : this.num++
      }, 5000)
    },
    mouseIn () {
      clearInterval(this.timer)
    },
    openDialog (itemi) {
      this.dialogCentent = [itemi]
      this.openDiaLog('diaLogIsShowCentent3')
      this.isContent3 = 'content3'
    },
    ...mapMutations({
      openDiaLog: 'openDialog',
      closeDialog: 'closeDialog'
    })
  }
}
</script>
<style scoped lang='scss'>
  ::v-deep .ContentAll{
    text-align: center;
  }
.content3{
  width: 10rem;
  // height: 3.159722rem;
  margin-top: 1.1rem;
  margin-bottom: .347222rem;
  position: relative;
  // border: 1px solid red;
  .title{
    position: relative;
      margin-bottom: .0486rem;
  }
  .bigText{
    background: url('/img/ye.png') no-repeat 0.047294rem 0.189175rem;
    background-size: 80%;
    margin-left: .229167rem;
  }
  .titleText{
    position: absolute;
    left: .930556rem;
    bottom: 0;
  }
  .main{
    width: 8.2639rem;
    min-height: 2.201389rem;
    margin-left: .930556rem;
    padding-bottom: 70px;
    overflow: hidden;
    position: relative;
    background: transparent;
    ul{
      height: 101%;
      li{
        width: 8.2639rem;
        height: 100%;
        float: left;
        .title{
          margin-top: .347222rem;
          margin-bottom: .277778rem;
          color: rgba(0,0,0,0.9);
          font-size: .125rem;
          font-weight: lighter;
          text-align: center;
        }
        .allImg{
          display: flex;
          flex-wrap: wrap;
          cursor: pointer;
          .img{
            width: 1.8681rem;
            height: 1.2083rem;
            margin-right: .1875rem;
            border: 1px solid #ebebeb;
            margin-bottom: .1736rem;
          }
        }
      }
    }
    .controller{
      display: flex;
      justify-content: space-between;
      position: absolute;
      left: 3.576389rem;
      bottom: .347222rem;
      span{
        width: .25rem;
        height: .041667rem;
        margin-left: .118056rem;
        background: rgba(196,196,196,1);
      }
      .active{
        background: rgba(0,0,0,0.8);
      }
    }
    .clearMargin{
      margin-left: 0 !important;
    }
  }
  .dialog{
    width: 4.902778rem;
    position: absolute;
    bottom: .138889rem;
    left: 2.604167rem;
  }
  .fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .trabecula{
    cursor: pointer;
  }
}
.content3Mobile{
  margin-top: 1.5rem;
  margin-bottom: .8533rem;
  position: relative;
  &>.title{
    margin-bottom: .1067rem;
    height: 1.6rem;
    position: relative;
    .bigText{
      z-index: -1;
      position: absolute;
      bottom: .16rem;
      left: .4267rem;
      width: 1.4933rem;
      height: 1.6rem;
      background: url('/img/ye.png') no-repeat center center/100%;
    }
    .titleTextMobile{
      position: absolute;
      bottom: 0;
      left: 1.7867rem;
    }
  }
  .main{
    width: 9.1467rem;
    // height: 6.2667rem;
    margin: 0 auto;
    position: relative;
      overflow: hidden;
    background: transparent;
    ul{
      width: 100%;
      li{
        width: 9.1467rem;
        float: left;
        .title{
          margin-top: .64rem;
          margin-bottom: .6133rem;
          font-size: .4267rem;
          text-align: center;
        }
        .allImg{
          width: 100%;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .img{
            width: 4.2933rem;
            height: 2.6933rem;
            border: 1px solid #ebebeb;
            margin-bottom: .2667rem;
          }
        }
      }
    }
    .controller{
      display: flex;
      justify-content: center;
      margin-top: .4267rem;
      span{
        width: .64rem;
        height: .16rem;
        margin-left: .118056rem;
        background: rgba(196,196,196,1);
      }
      .active{
        background: rgba(0,0,0,0.8);
      }
    }
  }
  .dialogMobile{
    position: absolute;
    left: 50%;
    bottom: 25%;
    transform: translate(-50%, 0);
  }
}
</style>