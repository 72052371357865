<template>
  <div :class="(isMobile !== null && (isMobile.includes('Android') || isMobile.includes('iPhone'))) ? 'mobile' : 'pc'">
    <div class="box">
      <p>
        2022年6月，互联网周刊发布2022中国准独角兽企业100强企业名单，<strong
        >一脉阳光影像医院集团荣获榜单第34位。</strong
        >
      </p>
      <img src="/img/10-26/image60.jpeg" class="img60" alt="" />
      <p></p>
    </div>
    <h4 class="tac">独角兽·潜力背后</h4>
    <div class="box">
      <p>
        当你去查维基百科，搜索词条“独角兽”，它可能会出现一个头顶长长且带着角的漫画角色。而实际在当下的语义环境中，这是
        <strong
        >2013年由风险投资家Aileen
          Lee创造出来的概念：简单定义就是估值在10亿美元以上、其成立时间不超过10年且未上市创业公司。</strong
        >
      </p>
      <div>
        古时有句话讲：天时地利人和
        <br />
        说一个事情的成功必然需要有这几方面的因素。时机成熟、环境适宜、人恰巧努力。
        <br />
        当然，这是从字面浅显翻译过来的意思，再往大了说，它涉及到法律法规、涉及到国家政策、涉及到资源优势。
        <br />
        一家独角兽的诞生绝不是空穴来风，通过业务线发展，我们能看到它的市场潜力与隐藏的能量。它所属的行业、所属的地位也将是它走过路程的丰碑。
      </div>
      <p></p>
      <p>
        从最初的几家发展到几十家、几百家。这是经济市场和全球化的推动，也是资本市场的影响。通过本次榜单我们不难发现，其行业主要集中在医疗健康、人工智能、数据安全等领域。民生是生存和发展的基础，契合作为“人”的需求，因此发展空间潜力大；人工智能代表的是什么呢？是科技，是智慧、是便捷、是创新，它让“人”的生活更加简单、也让“人”可以享受前所未有的福音。人脑创造一切，一切馈赠以人。
      </p>
      <div>
        归根结底，无论哪个领域，都要树牢一个基本点：服务社会大众。
        <br />
        当我们立足于这个基本点时，便有了业务拓宽的方向。
        <br />
        因为谁、为了谁、有因有果，一切使然。
      </div>
    </div>
    <h4 class="tac">追逐，追的是什么？</h4>
    <div class="box">
      <p>
        疫情笼罩的三年，或许摧毁了很多企业，在冷静反思的同时，也对未来的发展有了全新的认识。对于一脉阳光而言，作为一家医学影像公司，它在这个过程中又起到了怎样的作用？医学影像又给大众带来了什么？
      </p>
      <p>
        众所周知，
        <strong
        >作为人体内部情况的“探测仪”，医学影像在经过近些年的发展，已有了“深入人心”的作用。“影像与临床”之间打破界限、打破常规，开始相互走进、相互探讨，给予患者更多疾病检查方案，不仅大大节约了患者的看病时间，也让各科室、多学科之间的联系日趋紧密。</strong
        >
        从怀疑到信任、从不被理解到欣然接受、从默默无闻地背后力量走向与人沟通的前方，这大概就是追逐的意义。
      </p>
      <p></p>
      <div>
        诚然，当经济越往上发展、当资本的力量越来越强大，当我们的业务范围更加宽泛，就必然要想到两个词，其一是商业利益、其二是社会效益。
        <br />
        任何一个创业者，在创业之初我想都是看到了行业发展的时代缺位。
      </div>
      <p></p>
      <div>
        因为看到了不愿看到的，所以想致力于改变。
        <br />
        所以你问一家企业最终的方向在哪？它的使命价值在哪？它的初心在哪？我想归结于社会效益，或者换句话说，理应是社会效益远高于商业利益。
      </div>
    </div>
    <h4 class="tac">处于苍穹之下，不忘山峰之巅</h4>
    <div class="box">
      <p>
        行业在发展，时代在变革。
        独角兽的称号更像是一个鼓舞，鞭策着企业飞往更高的平台；
        <br />
        不能停滞不前、不能故步自封
        <br />
        所以有了【创新】的含义。
        <br />
        挖掘大众新痛点，拓宽业务生产线，研发新产品，走进百姓心声。
        <br />
        一脉阳光的核心价值观是【务实创新、至诚至简、服务至上】
        <br />
        我们把最终的落脚点放在【服务】二字上。
        <br />
        以热情、以新兴、以求真、以探索。
        <br />
        让这只准独角兽散发其真正的价值。
      </p>
    </div>
    <h4 class="tac end">END</h4>
    <p class="end">
      一脉阳光影像医院集团，由多名行业精英于2014年投资创建，是一家专业从事医学影像中心投资运营、医学影像云平台技术开发、医学影像人才培养、医学影像产业链服务的影像医院集团。作为中国第三方医学影像中心的开拓者、领航者，一脉阳光始终以“医学影像服务”为核心，坚持科技创新和模式创新，通过发展医生集团、医学影像云服务、医学影像学院三大业务支柱，打造医学影像全产业链服务平台，服务整个医学影像行业。
    </p>
  </div>
</template>
<script>
export default {
  props: {},
  inject: {
    isMobile: {
      default: null,
    },
  },
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang="scss">
img {
  display: block;
  margin: 0 auto;
}
.mobile{
  h4{
    margin-top: .6944rem;
    line-height: unset !important;
  }
}
.end {
  font-size: 0.0833rem;
  color: rgb(136, 136, 136);
}
.img60 {
  border: 1px dotted #4963a7 !important;
}
.box {
  width: 100%;
  padding: 0.1389rem;
  border: 1px solid #4963a7;
  box-shadow: rgb(73 99 167) 9px 9px 1px;
}
.tac {
  text-align: center;
  margin-top: 0.2778rem;
  color: #4963a7;
  font-size: .1389rem;
  &.end {
    color: rgb(109, 157, 254) !important;
  }
}
</style>
