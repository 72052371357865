<template>
  <div :class="(isMobile !== null && (isMobile.includes('Android') || isMobile.includes('iPhone'))) ? 'mobile' : 'pc'">
    <p>
      劲方医药及美国SELLAS生命科学集团（纳斯达克股票代码：SLS）今日宣布达成独家授权协议：SELLAS生命科学集团将作为独家合作伙伴，获得劲方医药新一代高选择性CDK9小分子抑制剂GFH009注射剂在大中华区（包括中国大陆、香港、澳门、台湾）之外的全球开发和商业化权益。
    </p>
    <p>
      根据协议，SELLAS将支付劲方1000万美元首付款及技术转让费，以及基于不超过3个适应症开发、累计达4800万美元的开发里程碑付款。此外，劲方将获得大中华区之外累计达9200万美元的销售里程碑付款。基于GFH009的年度净销售额百分比，SELLAS还将支付劲方梯度特许权使用费。
    </p>
    <p>
      CDK9为CDK激酶家族最具潜力的靶点之一，其活性与多种类型癌症患者的总生存率呈现负相关。全球范围内尚无高选择性CDK9抑制剂获批上市，GFH009为劲方自主研发、国内首个步入临床试验的此类化合物，也是劲方首个独立递交FDA临床试验申请获批的全球多中心临床开发项目，已在中美两地平行开展GFH009针对复发性/难治性恶性血液瘤患者的I期临床试验。
    </p>
    <p>
      基于劲方正在开展的I期剂量爬坡试验初步药代动力学数据，SELLAS相信GFH009作用机制独特，且和其他同类型临床研究阶段产品相比，其对CDK9蛋白的高选择性抑制有望大大降低毒性并提升疗效。I期试验包括扩展阶段预计招募约80名患者，目前处于预设剂量组的第四个剂量组，在试验中已经观察到3名淋巴瘤患者经治疗后疾病稳定。第三个剂量（9 mg）组一例急性髓系白血病（AML）患者观察到骨髓中白血病细胞比例从40%降低至20%。
    </p>
    <h4>劲方医药董事长吕强博士</h4>
    <p>
      “GFH009是劲方first-in-class管线中的头部产品项目之一，其研发进展很好地贯彻了劲方‘全球新’开发理念。SELLAS则在AML和其他血液瘤、实体瘤领域拥有专业的临床开发能力和卓越的项目执行力，将帮助劲方快速推进GFH009的临床开发以及商业化。我们很高兴与SELLAS合作，有望加速GFH009的全球多中心临床试验，也让我们对该产品走向国际市场的临床开发和商业化前景更有信心。随着明年我们基于该产品开展更多的临床试验，GFH009也将有望以更快的速度惠及中国及全球患者。”
    </p>
    <h4>
      SELLAS生命科学集团CEO兼总裁<br />Angelos Stergious博士
    </h4>
    <p>
      “GFH009具有成为同类最佳产品的潜力，劲方则是中国原创型‘全球新’药物开发的领先企业。SELLAS聚焦血液瘤等多种癌症疗法的临床开发和商业化，双方合作能够形成优势互补。我们预计于明年初针对AML患者开启GFH009与维奈托克（venetoclax）的联合疗法II期试验；而AML亦为SELLAS管线中头部产品galinpepimut-S ('GPS')的适应症。此外，我们还针对儿童人群中高度未满足的医疗需求，计划于今年底或明年初针对儿童患者开启I/II期篮式试验。我们相信劲方的新药研发效率将使GFH009的共同开发助力SELLAS，让我们在未来以更加成本高效的模式，推进更多适应症的临床研究。”
    </p>
    <h4>
      关于GFH009临床II期试验设计和CDK9
    </h4>
    <p>
      临床前实验模型表明GFH009具有与venetoclax的协同治疗潜力。SELLAS生命科学集团相信GFH009有望提升患者对venetoclax的用药响应率；II期试验中GFH009将与venetoclax和azacitidine联用治疗AML患者。此外SELLAS还将针对儿童软组织肉瘤（包括尤因肉瘤和横纹肌肉瘤）患者，开展一项GFH009单药I/II期篮式试验，计划于2023年底完成。劲方医药还计划在中国针对血液瘤开展一系列II期临床试验。
    </p>
    <p>
      作为强效、高选择性小分子CDK9抑制剂，GFH009在多种人体细胞系和疾病动物模型中，展现较强的诱导凋亡和抗增殖活性，可有效抑制多种模型肿瘤生长，并显著延长荷瘤动物生存期。临床前实验数据显示GFH009对CDK9蛋白的选择性抑制率超过其他CDK亚型100倍以上。
    </p>
    <p>
      细胞周期依赖蛋白激酶（cyclin-dependent kinase, CDK）为一大类丝氨酸/苏氨酸激酶家族蛋白，在细胞周期调节和转录过程中扮演重要角色。CDK9活性与多种血液瘤、实体瘤患者总生存率呈现负相关，包括急性髓系白血病（AML）和淋巴瘤、如骨肉瘤、儿童软组织肉瘤和黑色素瘤、子宫内膜癌、肺癌、前列腺癌、乳腺癌和卵巢癌患者。
    </p>
    <h4>关于SELLAS生命科学集团</h4>
    <p>
      SELLAS生命科学集团是一家后期临床阶段的生物制药公司，专注于开发多种癌症新型疗法。公司管线中的头部产品GPS由斯隆·凯特琳癌症中心授权、靶向WT1蛋白，该蛋白存在于多种类型的肿瘤组织。GPS有望通过单药和联合疗法，治疗多种血液瘤和实体瘤患者。目前公司已获得由劲方医药授权合作开发高选择性CDK9抑制剂GFH009，获得该产品在大中华区之外的诊疗用途权益。
    </p>
    <h4>
      关于劲方医药
    </h4>
    <p>
      劲方医药以未满足的临床需求为出发点，以疾病生物学机理和临床转化医学为核心，深入研究肿瘤信号通路、肿瘤免疫微环境及免疫调节等领域的最新生物学机制，主攻尚无临床验证的创新靶点与适应症，致力于原创型“全球新”药物开发，并拥有全球自主知识产权。

      劲方医药自2017年成立以来，已建立了涵盖大、小分子药物的技术平台、研发体系及人才梯队，产品管线包含十余个“全球新”项目，并有多个候选药物已进入包括中国、美国、澳大利亚等地在内的全球多中心临床研究阶段。预计未来三到五年，更多研发项目进入后期临床研究的同时，公司也将迈入产业化阶段。
    </p>
    <p></p>
    <div class="connect-us">
      <p>联系我们</p>
      <p>电话：021-68821388</p>
      <p>邮箱：pr@genfleet.com;</p>
      <p class="in">hr@genfleet.com;</p>
      <p class="in">bd@genfleet.com</p>
      <p>官网：www.genfleet.com</p>
      <p>地址：上海市浦东新区张江路1206号A座</p>
      <p>邮编：201203</p>

    </div>
  </div>
</template>

<script>
export default {
  props: {},
  inject: {
    isMobile: {
      default: null,
    },
  },
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang="scss">
img{
  display: block;
  margin: 0 auto;
}
.mobile{
  h4{
    line-height: unset  !important;
  }
}
.connect-us{
  p{
    margin-bottom: 0px !important;
    text-indent: 0em !important;
  }
  .in{
    text-indent: 3em !important;
  }
}
</style>
