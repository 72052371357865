<template>
  <div :class="isMobile === null ? 'content1' : (isMobile && isMobile.includes('iPad')) ? 'content1iPad':'content1Mobile'" >
    <div class="title">
      <Ren />
      <TitleText :cn="'泽悦创始人'" :en="'Our Founders'"/>
    </div>
    <div class="center" >
      <PeopleInfo
        v-for="item in list"
        :key="item.id"
        :id="item.id"
        :name="item.name"
        :info="item.info"
        :content="item.content"
        @isShow="openDalog"
        v-scroll-reveal.reset="scrollSet"
        class="wow fadeIn"
        :data-wow-duration="animateConfig.duration"
        :data-wow-delay="animateConfig.delay"
      ><div class="img imgLv" :style="{background: `url(${item.photo}) no-repeat center`, backgroundSize: `100%`}"></div>
      </PeopleInfo>
    </div>
    <!-- <transition name="fade"> -->
    <!-- <transition> -->
    <div class="close" :class="isMobile === null ? 'dialog': 'dialogMobile'" v-show="$store.state.dialog.diaLogIsShowCentent1" >
      <DiaLog ref="dialog" :dialogCentent="dialogCentent" class="dialogclose" @closeDialog="closeDialog"/>
    </div>
    <!-- </transition> -->
  </div>
</template>

<script>
import Ren from '@/views/home/component/bigText'
import { WOW, wowInit, animateConfig, scrollSet } from '@/util/scrollReveal.js'
import TitleText from '@/views/home/component/titleText'
import PeopleInfo from '@/views/home/component/peopleInfo-max'
import DiaLog from '@/views/home/component/content/dialog'
import { mapMutations } from 'vuex'
export default {
  name: 'Content1',
  props: {},
  inject: {
    isMobile: {
      default: null
    }
  },
  data () {
    return {
      scrollSet,
      animateConfig,
      list: [
        {
          id: 1,
          name: '吕晓翔',
          photo: '/img/lvxiaoxiang.png',
          info: '创始合伙人<br />医生',
          content: '20余年跨国医药企业管理经验，曾担任葛兰素史克中国总经理、葛兰素史克澳大利亚总经理、诺华大中...',
          contentAll: '超过20年跨国药企管理经验，曾担任葛兰素史克中国（疫苗），香港（澳门），及澳大利亚总经理；诺华（疫苗及诊断）大中华区总裁等职务。熟悉处方药、OTC、疫苗及生物制品等多个子行业业务，在研发、生产、注册/医学、市场营销/商业渠道等领域具有丰富经验。在多年商业实践中形成了独到的行业视角及决策理念，积累了深厚的产业资源和人脉。主导投资了奥精医疗(688613)、一脉阳光等20多家医药大健康企业。东南大学医学院学士，中欧国际工商学院工商管理硕士。'
        }, {
          id: 2,
          name: '张捷',
          photo: '/img/zhangjie.png',
          info: '联合创始合伙人<br />连续创业者 天使投资人',
          content: '在上海交通大学任教多年，拥有20余年的医疗健康领域创业、管理及投资经验。曾任微创医疗(HK00...',
          contentAll: '拥有20余年医疗领域创业、管理及投资经验。曾任微创医疗(HK00853)常务副总裁，作为最早的核心高管，带领微创发展成为中国高端医疗器械的领军企业并在香港主板成功上市。作为天使投资人，善于发现具有颠覆性潜力的创新，通过对市场的深度理解，对创始团队的赋能，帮助被投企业成为独角兽。代表项目包括逸思医疗、纽脉医疗、微密医疗、福寿康、卓道医疗等。创立上海中侨护理与健康学院并担任学院院长及执行董事，创办895医疗健康孵化器。上海交通大学工学和经济学双学士，中欧国际工商学院工商管理硕士。'
        },
      ],
      dialogCentent: [], // 弹窗内容
      // isShow: false
    }
  },
  components: {
    Ren,
    TitleText,
    PeopleInfo,
    DiaLog
  },
  created () {
  },
  mounted () {
    const wowi = new WOW(wowInit)
    wowi.init()
  },
  computed: {
  },
  watch: {},
  methods: {
    openDalog (id, event) {
      event.stopPropagation()
      event.preventDefault()
      // this.isShow = true
      // 筛选数据
      this.openDiaLog('diaLogIsShowCentent1')
      this.dialogCentent = this.list.filter(item => item.id === id && item)
    },
    ...mapMutations({
      openDiaLog: 'openDialog',
      closeDialog: 'closeDialog'
    })
  },
}
</script>
<style scoped lang='scss'>
.content1{
  width: 10rem;
  position: relative;
  margin-top: 0.9rem;
  .main{
    overflow: hidden;
  }
  .title{
    margin-bottom: .0486rem;
    position: relative;
    overflow: hidden;
  }
  .bigText{
    margin-left: 0.39rem;
    // background: url('/public/img/ren.png') no-repeat center;
    background: url('/img/ren.png') no-repeat 0 0.335292rem/90%;
  }

  .titleText{
    position: absolute;
    bottom: 0;
    left:  .909722rem;
  }
  .center{
    width: 8.166667rem;
    overflow: hidden;
    padding-bottom: .416667rem;
    margin-left: .909722rem;
    margin-bottom: .2847rem;
    background: #F2F2F2;
    .perInfo{
      width: 1.868056rem;
      float: left;
      margin-top: .416667rem;
      margin-left: 1.44444rem;
    }
    .img{
      width: 100%;
      height: 2.291667rem;
      background-size: 100%;

    }
  }
  .dialog{
    width: 4.902778rem;
    .dialog{
      position: absolute;
      left: 2.548611rem;
      top: 0;
    }
  }

}
.content1Mobile {
  width: 100%;
  margin-top: -0.6rem;
  position: relative;
  .title{
    height: 1.6rem;
    margin-bottom: .1067rem;
    position: relative;
    .bigText{
      z-index: -1;
      position: absolute;
      left: .4267rem;
      bottom: -0.08rem;
      width: 1.4933rem;
      height: 1.6rem;
      background: url('/img/ren.png') no-repeat 0 0.315292rem/100%;
    }
    .titleTextMobile{
      position: absolute;
      bottom: 0;
      margin-left: 1.65rem;
    }
  }
  .center{
    width: 9.1467rem;
    display: flex;
    margin: 0 auto;
    justify-content: space-around;
    padding: .4267rem 0;
    background: #F2F2F2;
    .perInfo{
      width: 3.8933rem;
      .img{
        width: 3.8933rem;
        height: 4.8rem;
      }
    }
  }
  .dialogMobile{
    .dialogMobile{
    position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
  }
}
.content1iPad{
  width: 100%;
  margin-top: -0.6rem;
  position: relative;
  .title{
    height: 1.6rem;
    margin-bottom: .1067rem;
    position: relative;
    .bigText{
      z-index: -1;
      position: absolute;
      left: .4267rem;
      bottom: -0.08rem;
      width: 1.4933rem;
      height: 1.6rem;
      background: url('/img/ren.png') no-repeat 0 0.315292rem/100%;
    }
    .titleTextMobile{
      position: absolute;
      bottom: 0;
      margin-left: 1.65rem;
    }
  }
  .center{
    width: 9.1467rem;
    display: flex;
    margin: 0 auto;
    justify-content: space-around;
    padding: .4267rem 0;
    background: #F2F2F2;
    .perInfo{
      width: 3.8933rem;
      .img{
        width: 3.8933rem;
        height: 4.8rem;
      }
    }
  }
  .dialogMobile{
    .dialogMobile{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>