<template>
  <div
    @click="isShowMobile($event), $emit('isShow', id, $event)"
    class="perInfo"
    :class="isMobile === null ? 'perInfo' : (isMobile && isMobile.includes('iPad')) ? 'perInfoiPad' : isMobile !== null && 'perInfoMobile'">
    <!-- 插槽放人物图片 -->
    <slot></slot>
    <p class="name">{{ name }}</p>
    <p class="info" v-html="info">  </p>
    <!-- <p class="content" :class="isMobile === 'PC' && 'Mobile-lineHeight'">
      {{ content }}
      <span class="more" @click="$emit('isShow', id, $event)"></span>
    </p> -->
  </div>
</template>

<script>
export default {
  inject: {
    isMobile: {
      default: null
    }
  },
  props: {
    id: {
      type: Number,
      require: true
    },
    name: {
      type: String,
      require: true
    },
    info: {
      type: String,
      require: true
    },
    content: {
      type: String,
      require: true
    }
  },
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {
    isShowMobile (event) {
      if (this.isMobile !== null) {
        this.$emit('isShow', this.id, event)
      }
    }
  },
}
</script>
<style scoped lang='scss'>
.perInfo{
  cursor: pointer;
  .name{
    width: 100%;
    margin-top: .097222rem;
    text-align: center;
    color: #000;
    font-size: .125rem;
    font-weight: 500;
  }
  .info{
    margin-top: .027778rem;
    font-size: .111111rem;
    color: #000;
    font-weight: normal;
    text-align: center;
    white-space:nowrap;
  }
  .content{
    position: relative;
    margin-top: .069444rem;
    color: rgba(141,141,141,1);
    font-size: .097222rem;
    font-weight:normal;
    text-align: left;
    line-height: .179rem;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    //  word-break: break-all;
    -webkit-line-clamp: 3;
    overflow: hidden;
    .more{
      position: absolute;
      right: -0.0069rem;
      bottom: .0278rem;
      display: inline-block;
      width: .138889rem;
      height: .138889rem;
      background: url('/img/more.png') no-repeat center;
      background-size: 100%;
            cursor: pointer;
    }
  }
}
.perInfoMobile{
  .name{
    font-size: .4rem !important;
    margin-top: .2667rem !important;
  }
  .info{
    font-size: .4rem !important;
    margin-top: .16rem !important;
    margin-bottom: .4267rem !important;
  }
  .content{
    font-size: .32rem !important;
    line-height: .5333rem !important;
  }
  .more{
    width: .4267rem !important;
    height: .4267rem !important;
    bottom: .10rem !important;
  }
}
.perInfoiPad{
    .name{
    font-size: .3rem !important;
    margin-top: .2667rem !important;
  }
  .info{
    font-size: .21rem !important;
    margin-top: .16rem !important;
    margin-bottom: .4267rem !important;
  }
  .content{
    font-size: .27rem !important;
    line-height: .5333rem !important;
  }
  .more{
    width: .4267rem !important;
    height: .4267rem !important;
    bottom: .10rem !important;
  }
}
</style>