import request from '../axios'
import { path } from '../path'

// 授权后获取使用code获取用户信息,如果用户没有注册过则返回openid
export const wechatMp = (data, UserType) => {
  return request({
    headers: {
      'User-Type': UserType,
    },
    url: `${path}token/wechat/mp?code=${data}`,
    method: 'get',
  })
}

// 获取验证码
export const getPhoneCode = (params, UserType) => {
  return request({
    headers: {
      'User-Type': UserType,
    },
    url: `${path}sms`,
    method: 'GET',
    data: {
      ...params
    }
  })
}

// 登录 通过用户名密码 获取认证
export const loginByUsername = (userName, smsCode, smsKey, openId, UserType, GrantType, password) => request({
  url: `${path}token`,
  method: 'post',
  headers: {
    'User-Type': UserType,
    'Grant-Type': GrantType,
  },
  data: {
    userName,
    smsCode,
    smsKey,
    openId,
    password
  }
})
// 退出登录
export const logout = (tenantId, key, code) => request({
  url: path + 'login/oauth/logout',
  method: 'get',
  headers: {
    'Tenant-Id': tenantId,
    'Captcha-Key': key,
    'Captcha-Code': code,
    'User-Type': 2
  },
})

// 登录 通过手机号 验证码 获取认证
export const loginByPhoneCode = (data) => {
  return request({
    url: path + 'sms/login/oauth/token',
    method: 'post',
    data
  })
}
// 用户 注册
export const registerByUser = (data) => {
  return request({
    url: path + 'customer/register',
    method: 'post',
    data
  })
}
// 用户详情
export const getUserDetailInfo = () => {
  return request({
    url: path + 'customer/detail',
    method: 'get',
  })
}
// 用户 编辑
export const editUserInfo = (data) => {
  return request({
    url: path + 'customer/edit',
    method: 'put',
    data
  })
}
// 用户 修改密码
export const updatePassword = (data) => {
  return request({
    url: path + 'customer/change/password',
    method: 'put',
    data
  })
}
// 忘记密码
export const submitForgetpwd = (data) => {
  return request({
    url: path + 'customer/reSet',
    method: 'post',
    data
  })
}
// 刷新token
export const refreshToken = () => {
  return request({
    url: path + 'login/oauth/refresh/token',
    method: 'post'
  })
}