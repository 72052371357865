<template>
  <div class="footer">
    <div :class="isMobile === null ? 'logo' : 'logo_Mobile'" ></div>
    <div class="nav" v-if="isMobile === null">
      <ol v-for="(item, index) in list" :key="index">
        <li @click="goView(item1.id)" v-for="(item1, index1) in item" :key="index1">
          <router-link :to="`${item1.path || '/'}`">{{ item1.title }}</router-link>
        </li>
      </ol>
      <ol>
        <li style="text-align: center;">关注我们</li>
        <li class="erweima"><img src="/img/erweima.png" alt=""></li>
      </ol>
    </div>
    <!-- 移动端布局 -->
    <div :class="(isMobile && isMobile.includes('iPad')) ? 'naviPad' : 'nav_Mobile'" v-else>
      <ul>
        <li @click="goView(index, item.path)" v-show="item.meta.title" v-for="(item, index) in listMobile" :key="index">
          <router-link :to="`${item.path || '/'}`">  {{ item.meta.title }}</router-link>
        </li>
        <div style="clear: both"></div>
      </ul>
      <div class="erweima"></div>
      <div style="clear: both"></div>
    </div>
    <i class="heng" :class="isMobile !== null && 'hengMobile'"></i>
    <p class="bei-an" :class="(isMobile && isMobile.includes('iPad')) ? 'be-aniPad' : isMobile !== null && 'bei-anMobile'"><a href="https://beian.miit.gov.cn" target="_black">浙ICP备2022001889号-1</a> © 2022 泽悦创投 版权所有</p>
  </div>
</template>

<script>
import routes from '@/router/routes.js'
import { mapGetters } from 'vuex'
export default {
  name: 'Footer',
  inject: {
    isMobile: {
      default: null
    }
  },
  props: {},
  data () {
    return {
      listMobile: routes,
      // list: [['首页', '泽悦价值观', '投资领域与策略'], ['泽悦创始人', '泽悦核心成员', '受资企业名录'], ['泽悦圈', '联系我们']],
      list: [[{
        id: 0,
        title: '首页'
      }, {
        id: 1,
        title: '价值观'
      }, {
        id: 2,
        title: '投资策略'
      }], [{
        id: 3,
        title: '创始人'
      }, {
        id: 4,
        title: '团队'
      }, {
        id: 5,
        title: '投资企业'
      }], [{
        id: 6,
        path: '/zeyuequan',
        title: '泽悦圈'
      }, {
        id: 7,
        path: '/contact',
        title: '联系我们'
      }]],
    }
  },
  components: {},
  created () {},
  mounted () {},
  computed: {
    ...mapGetters(['navBarItemActiveClick'])
  },
  watch: {},
  methods: {
    goView (index) {
      this.$store.commit('setnavBarItemActiveClick', index)
    }
  },
}
</script>
<style scoped lang='scss'>
.footer{
  width: 100%;
  background: rgba(0,21,41,0.95);
  overflow: hidden;
  .logo{
    width: 1.388889rem;
    height: .402778rem;
    float: left;
    margin-top: .541667rem;
    margin-left: 1.006944rem;
    background: url('/img/logo1.png') no-repeat;
    background-size: 100%;
  }
  .nav{
    width: 3.618056rem;
    margin-top: .541667rem;
    margin-left: 5.444444rem;
    margin-bottom: .267361rem;
    display: flex;
    justify-content: space-between;
    ol{
        color: rgba(240,240,240,0.6);
      font-size: .097222rem;
      font-weight: normal;
      line-height: .097222rem;
      li{
        line-height: .263889rem;
        a{
          color: rgba(240,240,240,0.6);
          font-size: .097222rem;
          font-weight: normal;
          line-height: .097222rem;
        }
      }
      .erweima{
        width: .5556rem;
        height: .5556rem;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .heng{
    width: 8rem;
    height: .006944rem;
    display: block;
    margin: 0 auto;
    background: #505f6e;
  }
  .bei-an{
    margin-top: .190972rem;
    margin-bottom: .159722rem;
    text-align: center;
    color: rgba(204,204,204,0.6);
    font-size: .0694rem;
    font-weight: normal;
    a{
      color: rgba(204,204,204,0.6);
      font-size: .0694rem;
      font-weight: normal;
    }
  }
  .logo_Mobile{
    width: 3.413333rem;
    height: 1.066667rem;
    margin-top: .646667rem;
    margin-left: .426667rem;
    background: url('/img/logo1.png') no-repeat;
    background-size: 100%;
  }
  .nav_Mobile{
    position: relative;
    width: 10rem;
    ul{
      margin-top: .556533rem;
      margin-bottom: .2667rem;
      padding-left: .426667rem;
      padding-right: .426667rem;
      // padding-bottom: 1.653333rem;
      color: rgba(255,255,255,1);
      font-size: .373333rem;
      font-weight: 400;
      line-height: .8rem;
      li{
        width: 40%;
        height: 0.8rem;
        float: left;
      }
      a{
        color: rgba(255,255,255,1);
        font-size: .373333rem;
        font-weight: 400;
        line-height: .8rem;
      }
    }
    .erweima{
      width: 2.133333rem;
      height: 2.133333rem;
      margin-right: .64rem;
      margin-bottom: .2933rem;
      position: absolute;
      right: 0;
      bottom: 0.096618rem;
      background: url('/img/erweima.png') no-repeat;
      background-size: 100%;
    }
  }
  .naviPad{
    position: relative;
    width: 10rem;
    ul{
      margin-top: .556533rem;
      margin-bottom: .2667rem;
      padding-left: .426667rem;
      padding-right: .426667rem;
      // padding-bottom: 1.653333rem;
      color: rgba(255,255,255,1);
      font-size: .25rem;
      font-weight: 400;
      line-height: .8rem;
      li{
        width: 40%;
        height: 0.6rem;
        float: left;
      }
      a{
        color: rgba(255,255,255,1);
        font-size: .25rem;
        font-weight: 400;
        line-height: .8rem;
      }
    }
    .erweima{
      width: 2.133333rem;
      height: 2.133333rem;
      margin-right: .64rem;
      margin-bottom: .2933rem;
      position: absolute;
      right: 0;
      bottom: 0.096618rem;
      background: url('/img/erweima.png') no-repeat;
      background-size: 100%;
    }
  }
  .hengMobile{
    width: 100%;
  }
  .bei-anMobile{
    font-size: .2667rem;
    a{
      font-size: .2667rem;
    }
  }
  .be-aniPad{
        font-size: .2rem;
    a{
      font-size: .2rem;
    }
  }
}
</style>