<template>
  <div>
    <div
      class="top-box"
      :class="
        isMobile && isMobile.includes('iPad')
          ? 'pad'
          : isMobile !== null && 'mob'
      ">
      <div class="circle-img">
        <img src="/img/10-26/e.png" alt="" />
        <div>近日，BioBAY“创业者交流会” 成功召开
          <br /> 园内企业家受邀参与
          <br />“Explore the Unknown，
          <br />Invest the Future”
          <br />2021BioBAY年度【成长奖】揭晓
          <br />元启生物制药荣登榜单！
        </div>
      </div>
    </div>
    <img
      class="image8"
      :class="
        isMobile && isMobile.includes('iPad')
          ? 'pad'
          : isMobile !== null && 'mob'
      "
      src="/img/10-26/image8.jpeg"
      alt="">
    <p>元启生物首席商务官邵湘红女士表示：</p>
    <p>2021年的关键词是新冠风口、内卷和二级市场估值重构。2022年被称为中国原创新药的元年，在原创、差异化、国际化/出海这些展望和期待之际，元启非常兴奋能够加入BioBay这个大家庭。</p>
    <p>大家最近经常讨论未来十年中国biotech的成功之路，元启团队也有很多思考和讨论。元启生物拥有中美两地运营的国际化团队，核心团队不仅拥有平均20年以上的跨国药企新药研发经验 （其中包括四个上市药，一个NDA），还拥有丰富的从早期创新合作至临床、上市阶段全生命周期的BD和合作经验，我们在元启聚焦的领域还拥有强大的中美两地大学和科研院所科学家团队的支持，这些元素与大家对中国创新药新纪元的展望有很好的契合。衷心感谢BioBay对元启生物制药的支持和认可！未来中国的生物医药发展有更大的压力和挑战，也有更多新的机遇和合作机会，元启生物医药希望向园区的前辈公司们多多学习，积极拓展合作，与大家共同探索后创新药新纪元生物医药企业成功之路！</p>
    <p><strong>恭祝元启生物获得2021BioBAY年度【成长奖】！作为优秀的新药研发企业，未来元启生物也将继续努力，建设全球化的新药研发平台，在不断努力发展壮大的同时，承担并践行更多社会责任！</strong></p>
    <p>同时也恭贺2021 BioBAY年度【成长奖】的其他园区优秀企业。<strong>元启生物愿与生物医药同行一道，立足BioBAY、共同发展，合力推动生物医药产业的蓬勃发展！</strong></p>
    <h4> 关于 元启生物 </h4>
    <p>元启生物制药是一家于2020年11月注册成立的总部位于苏州BioBay的生物技术公司，在中国苏州及美国波士顿均设有研发中心。元启生物由一群资深的国际药企研发及管理人员创建，通过团队对免疫和肿瘤疾病独到的见解，丰富的药物研发经验与合作伙伴特有的技术相结合，同时充分利用全球各地区的不同优势，致力成为创新药物研发的领导者。元启生物正在通过内部研发和外部合作建立自己的特色产品，重点解决一些免疫和肿瘤方面尚未满足的医疗需求。</p>
  </div>
</template>

<script>
export default {
  inject: {
    isMobile: {
      default: null,
    },
  },
  props: {},
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang='scss'>
.top-box {
  box-sizing: border-box;
  width: 4.7014rem;
  padding: .1389rem;
  padding-top: .4167rem;
  background: #f4705b;
  margin: 0 auto;
  .circle-img {
    width: 100%;
    text-align: center;
    background: #fff6ee;
    position: relative;
    padding-top: .3472rem;
    img {
      width: .6944rem;
      height: .6944rem;
      border-radius: 50%;
      margin-top: -0.6944rem;
    }
    div{
      padding-bottom: .2083rem;
    }
  }
  &.mob{
    width: 100% !important;
    font-size: 16px;
  }
}
.image8{
  display: block;
  width: 4.1667rem;
  margin: .2083rem auto;
  &.mob{
    width: 100%;
  }
}
</style>