<template>
  <div id="app" @click="cancelPop($event)">
    <!-- 顶部导航固定，占位元素，该高度取决于 顶部的高度 -->
    <div class="placeHolderBox" :style="{height:isMobile1 === null ? '.694444rem' : (isMobile1 && isMobile1.includes('iPad')) ? '1.5rem' : '2.053333rem'}"></div>
    <Header ref="header"/>
    <router-view></router-view>
    <Footer v-scroll-reveal.reset class="wow fadeIn" :data-wow-duration="animateConfig.duration" />
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import { WOW, wowInit, animateConfig } from '@/util/scrollReveal.js'
var nig = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
export default {
  provide () {
    // 判断是不是移动端，并且下发给所有后代组件
    return {
      isMobile: [90, -90].includes(window.orientation) ? null : nig,
    }
  },
  data () {
    return {
      el: '',
      animateConfig,
      isMobile1: [90, -90].includes(window.orientation) ? null : nig,
      isMobile: nig,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (val) {
        if (val.meta.id === 6 || val.meta.id === 7) {
          this.$store.commit('setnavBarItemActiveClick', val.meta.id)
        }
      }
    }
  },
  components: {
    Header,
    Footer
  },
  created () {
    // 屏幕旋转后 页面自动刷新
    if (this.isMobile !== null) {
      window.addEventListener('onorientationchange' in window ? 'orientationchange' : 'resize', this.isPortrait, false)
    }
  },
  mounted () {
    console.log(this.isMobile)
    const wowi = new WOW(wowInit)
    wowi.init()
    var that = this
    window.onload = function () {
      resizeFont()
    }
    resizeFont()
    // 重置当前rem的基本参照字体大小
    // eslint-disable-next-line no-inner-declarations
    function resizeFont () {
      if (that.isMobile === null) {
        document.documentElement.style.fontSize = '144px'
        document.getElementById('app').style.width = '1440px'
        return
      }
      // 当前设备宽度
      var _w = document.documentElement.clientWidth
      // 动态设置跟字体大小 一般为了书写方便 取屏幕宽度的10%
      document.documentElement.style.fontSize = _w / 10 + 'px'
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // 滚动条平滑滚动
    })
  },
  methods: {
    isPortrait () {
      location.reload()
    },
    _isMobile () {
      var flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
    // 点击弹窗以外的区域关闭弹窗
    cancelPop (event) {
      if (document.querySelector('.home')) {
        // 三个变量分别控制三个弹窗的显示隐藏
        if (this.$store.state.dialog.diaLogIsShowCentent1 === true) {
          this.el = '.content1c'
        } else if (this.$store.state.dialog.diaLogIsShowCentent2 === true) {
          this.el = '.content2c'
        } else if (this.$store.state.dialog.diaLogIsShowCentent3 === true) {
          this.el = '.content3c'
        } else {
          // 如果三个弹窗都属关闭状态，那就终止事件执行，避免报错
          return
        }
        const el = document.querySelector('.home').querySelector(this.el).querySelector('.dialogclose')
        if (el) {
          if (!el.contains(event.target)) {
            this.$store.commit('closeDialog')
          }
        }
      }
    }

  }
}
</script>

<style>

</style>

<style>

#app {
  margin: 0 auto;
  font-family: 'Siyuanotf' !important;
  Text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
}
#nav {
  padding: 30px;
}
#nav a {
    font-weight: bold;
}
#nav a .router-link-exact-active {
  color: #42b983;
}
#nprogress .bar { background: #13C2C2 !important;}
.fade-enter-active{
  transition:opacity .5s;
}
.fade-leave-active{
  opacity:0;
  transition:opacity .5s;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>