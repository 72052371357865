<template>
  <div class="bigText" :style="{background: background}"/>
</template>

<script>
export default {
  name: 'BigText',
  inject: {
    isMobile: {
      default: null
    }
  },
  props: {
    background: {
      type: String,
      require: true
    }
  },
  data () {
    return {
    }
  },
  components: {},
  created () {
  },
  mounted () {
  },
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang='scss'>
.bigText{
  width: .902778rem;
  height: .958333rem;
}

</style>