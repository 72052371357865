<template>
  <div :class="(isMobile !== null && (isMobile.includes('Android') || isMobile.includes('iPhone'))) ? 'mobile' : 'pc'">
    <p>劲方医药及纳斯达克上市公司BioLineRx（股票代码：BLRX）今日宣布，双方将共同开发BioLineRx管线中接近新药上市申请（NDA）阶段的产品CXCR4抑制剂motixafortide。劲方医药将在中国大陆独立完成motixafortide针对晚期胰腺导管腺癌患者的一线联合疗法临床设计以及开发；基于motixafortide获批上市后所有适应症的全球销售额，BioLineRx将向劲方支付一定比例的特许权费用。</p>
    <p>
      基础研究证实CXCR4不仅是肿瘤细胞表达最为普遍的趋化因子受体之一，更参与炎症反应、调控造血等多种生理机制。BioLineRx针对多发性骨髓瘤自体造血干细胞移植患者，已完成Motixafortide联合粒细胞集落刺激因子动员造血干细胞的III期临床试验，并完成新药上市前会议沟通，近期将向FDA递交新药上市申请。</p>
    <p>更有意义的是，胰腺导管腺癌（pancreatic ductal adenocarcinoma, PDAC）在全球范围内尚无一线靶向治疗手段，是一项重大未满足临床需求。BioLineRx已在海外针对PDAC患者开展Motixafortide三联疗法进行二线治疗的IIa单臂试验，并顺利获得积极进展。劲方将在中国大陆的适应症人群中开展一项IIb随机对照试验，评估motixafortide联合PD-1抑制剂及化疗药物三联疗法在一线治疗患者中的安全性/耐受性。</p>
    <img src="/img/10-26/image56.jpeg" alt="">
    <span class="zhujie">劲方医药董事长吕强博士、BioLineRx首席执行官
      Philip Serlin在美国纽约签署合作协议</span>
    <h4>劲方医药董事长吕强博士</h4>
    <p>
      “非常高兴与BioLineRx达成协议、共同开发接近NDA阶段的产品，双方以新颖的模式拓展创新药企业的跨国合作，有效提升 motixafortide的潜在价值，同时加速劲方的商业化及国际化进程。目前BioLineRx在海外开展的motixafortide针对干细胞活化效应适应症已临近NDA提交，而针对PDAC的IIa临床试验也已为患者带来临床获益。在获得针对所有适应症国际销售提成的基础上，劲方将独立设计与开展motixafortide针对PDAC的一线联合疗法试验，为更多患者带来创新疗法。”</p>
    <h4>BioLineRx首席执行官Philip Serlin</h4>
    <p>“Motixafortide三联疗法的IIa期试验(COMBAT/KEYNOTE-202)已获得积极进展，为开发更多针对血液瘤和实体瘤的联合疗法奠定基础。劲方医药拥有众多抗肿瘤创新产品及资深的新药开发团队，我们相信我们找到了一家优秀、卓越的合作伙伴来推动motixafortide对胰腺癌疗法更深入的开发。同时BioLineRx也将继续motixafortide针对干细胞活化效应的NDA提交工作，并预计于2023年获得FDA批准。”</p>
    <h4>关于PDAC与motixafortide </h4>
    <p>
      胰腺导管腺癌 (PDAC) 是最常见的胰腺癌类型，侵袭性较强且预后不良。PDAC肿瘤微环境包括大量致密基质成分、免疫细胞，以及大量可溶性免疫抑制因子，具有高度免疫抑制作用，促进肿瘤生长、转移。
      <br>
      BioLineRx开展的COMBAT/KEYNOTE-202项目为多中心、单臂二线疗法的IIa期研究（NCT02826486），采用motixafortide 和派姆单抗联合化疗，治疗新发转移性 PDAC 患者、一线吉西他滨治疗后病情进展的 PDAC 患者，70%受试者入组时伴有癌症肝转移（不良预后的关键指标）。研究目的为评估疗效和安全性，目前研究数据已在国际医学期刊Nature Medicine公布。
      <br>
      Motixafortide在一项针对多发性骨髓瘤自体造血干细胞移植的III期GENESIS试验（NCT03246529）中，联合粒细胞集落刺激因子（G-CSF）疗法动员造血干细胞，并达到所有主要及次要终点。目前，BioLineRx已经与FDA完成pre-NDA会议沟通。
    </p>
    <h4>关于劲方医药</h4>
    <p>
      劲方医药以未满足的临床需求为出发点，以疾病生物学机理和临床转化医学为核心，深入研究肿瘤信号通路、肿瘤免疫微环境及免疫调节等领域的最新生物学机制，主攻尚无临床验证的创新靶点与适应症，致力于原创型“全球新”药物开发，并拥有全球自主知识产权。
      <br>
      劲方医药自2017年成立以来，已建立了涵盖大、小分子药物的技术平台、研发体系及人才梯队，产品管线包含十余个“全球新”项目，并有多个候选药物已进入包括中国、美国、澳大利亚等地在内的全球多中心临床研究阶段。预计未来三到五年，更多研发项目进入后期临床研究的同时，公司也将迈入产业化阶段。
    </p>
    <p></p>
    <div class="contact">
      <strong>联系我们</strong>
      <br> 电话：021-68821388
      <br>邮箱：pr@genfleet.com; bd@genfleet.com; hr@genfleet.com
      <br> 官网：www.genfleet.com
      <br> 地址：上海市浦东新区张江路1206号A座
      <br> 邮编：201203
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  inject: {
    isMobile: {
      default: null,
    },
  },
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang="scss">
img {
  display: block;
  margin: 0 auto;
}
.mobile{
  font-size: .3681rem;
}
</style>
