export default {
  state: {
    navBarItemActive: 0, // 被激活的列表项目
    navBarItemActiveClick: 0, // 导航栏触发点击事件这个值就会发生改变
  },
  mutations: {
    setNavBarItemActive (state, num) {
      // console.log(num)
      state.navBarItemActive = num
    },
    setnavBarItemActiveClick (state, num) {
      state.navBarItemActive = num
      // console.log(state.navBarItemActive)
      state.navBarItemActiveClick++
    },
  },
}