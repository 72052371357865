<template>
  <div>
    <p>
      9月2日，信达生物宣布与劲方医药达成授权协议，获得后者KRAS G12C抑制剂GFH925在中国（包括中国大陆、香港、澳门及台湾）的全球独家开发和商业化权利，并拥有全球开发和商业化权益的选择权。
    </p>
    <p>
      信达生物将支付劲方医药2200万美元首付款和累计不超过5000万美元的全球开发支持费用。如果信达生物行使全球权益的选择权，劲方医药还将获得总额不超过2.4亿美元的全球开发、注册及销售里程金，以及基于中国及全球市场内GFH925的年度销售额的梯度特许权使用费。
    </p>
    <p>
      根据协议，信达生物将负责GFH925在中国的临床开发及商业化，并保留中国以外地区开发和商业化权益的选择权。待该候选药物获批上市后，信达生物负责GFH925的全国商业推广。劲方医药则继续在全国范围内，负责临床开发以及商业化阶段的药物生产。
    </p>
    <p>
      GFH925通过共价不可逆修饰KRAS G12C蛋白突变体半胱氨酸残基，有效抑制该蛋白介导的GTP/GDP交换从而下调KRAS G12C蛋白活化水平；临床前半胱氨酸选择性测试，也显示了GFH925对于该突变位点的高选择性抑制效力。此外，GFH925抑制KRAS G12C蛋白后可进而抑制下游信号传导通路，有效诱导肿瘤细胞凋亡及细胞週期阻滞，达到抗肿瘤效果。
    </p>
  </div>
</template>

<script>
export default {
  props: {},
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang='scss'>
</style>