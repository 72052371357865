<template>
  <div>
    <div class="header" v-if="isMobile === null">
      <div class="logo" @click="goView(0)"></div>
      <!-- PC 布局 -->
      <div class="navBar">
        <div @click="goView(index, item.path)" class="navBarItem" v-for="(item, index) in title" :key="index">
          <div>{{ item.meta.title }}</div>
          <span :class="`stripes stripes${index}`"/>
        </div>
      </div>
    </div>
    <!-- 移动端 -->
    <div class="header header_Mobile" v-else :class="{headerIpad: (isMobile && isMobile.includes('iPad'))}">
      <div class="logo" @click="goView(0)"></div>
      <div class="right">
        <!-- 关闭按钮 -->
        <transition name="fade">
          <span class="el-icon-close close" v-show="isShow" @click="close"></span>
        </transition>
        <!-- 显示导航按钮 -->
        <span class="more" @click="more"></span>
      </div>
      <transition name="fade">
        <ul v-if="isShow">
          <li :class="index === 0 && 'firstLi'" @click="goView(index, item.path)" v-for="(item, index) in title" v-show="item.meta.title" :key="index" >
            {{ item.meta.title }}
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
import routes from '@/router/routes.js'
import { mapGetters } from 'vuex'

export default {
  inject: {
    isMobile: {
      default: null
    }
  },
  props: {

  },
  name: 'Header',
  data () {
    return {
      // 导航栏列表
      title: routes,
      isShow: false,
      screenWidth: document.body.clientWidth,
    }
  },
  watch: {
    navBarItemActive: {
      // immediate: true,
      handler () {
        if (this.isMobile === null) {
          document.querySelectorAll(`.stripes`).forEach(item => { item.style.right = '100%' })
          document.querySelector(`.stripes${this.navBarItemActive}`).style.right = 0
        }
      },
    }
  },
  components: {
  },
  created () {
  },
  mounted () {
    if (this.isMobile === null) {
      document.querySelector(`.stripes${this.navBarItemActive}`).style.right = 0
    }
    // 判断是不是 safari 浏览器, 是的话,把导航栏字体粗细设置为 500
    if ((/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent))) {
      document.querySelectorAll('.navBarItem').forEach(item => {
        item.style.fontWeight = '500'
      })
    }
  },
  computed: {
    ...mapGetters(['navBarItemActive', 'navBarItemActiveClick'])

  },
  methods: {
    close () {
      this.isShow = false
      document.body.style.overflow = ''
    },
    more () {
      this.isShow = true
      // 解决移动端弹出导航栏后 页面还能继续滑动的问题
      // 让页面无法滑动
      document.body.style.overflow = 'hidden'
    },
    goView (index, path) {
      // 让页面可以继续上下滑动
      this.$store.commit('setnavBarItemActiveClick', index)
      index === 0 ? this.$router.push({ path: '/' }) : this.$router.push({ path })
      document.body.style.overflow = ''
      this.isShow = false
    },
  },
}
</script>
<style scoped lang="scss">
.header{
  width: 10rem;
  height: .6944rem;
  // box-shadow: 0 .013889rem .034722rem #888888;
  position: fixed;
  top: 0;
  background: white;
  z-index: 999999;
  .logo{
     width: 1.388889rem;
     height: .397708rem;
     float: left;
     margin: .1458rem  0 .1509rem .9097rem;
     background: url('/img/logo.png') no-repeat;
     background-size: 100%;
     cursor: pointer;
  }
  .navBar{
    width: 4.7014rem;
    height: .222222rem;
    font-size: .11rem;
    position: absolute;
    top: .298611rem;
    left: 4.152778rem;
    display: flex;
    justify-content: space-around;
    .navBarItem {
      cursor: pointer;
      position: relative;
      padding-bottom: .1112rem;
      overflow: hidden;
      font-weight: 600;
      a{
        display: block;
      }
      .stripes{
        // transition: all 0.3s;
        display: inline-block;
        width: 100%;
        height: .0139rem;
        position: absolute;
        right: 100%;
        bottom: .04rem;
        background: #FFB600;
      }
    }
  }
}
.header_Mobile{
  width: 10rem;
  // height: 100%;
  height: 2.053333rem;
  position: fixed;
  top: 0;
  background: white;
  box-shadow: 0 .013889rem .534722rem #888888;
  z-index: 999999;
  div{
    float: left;
  }
  .logo{
    width: 3.413333rem;
    height: 1.066667rem;
    background: url('/img/logo.png') no-repeat;
    background-size: 100%;
    margin-top: .48rem;
    margin-left: .346667rem;
  }
  .right{
    margin-top: .693333rem;
    span{
      display: inline-block;
    }
    .close{
      width: .586667rem;
      height: .586667rem;
      margin-left: .586667rem;
      font-size: .586667rem;
    }
    .more{
      width: .56rem;
      height: .506667rem;
      position: absolute;
      right: .48rem;
      background: url('/img/san.png') no-repeat;
      background-size: 100%;
    }
  }
  ul{
    width: 10rem;
    height: 100%;
    overflow: auto;
    padding-bottom: .5333rem;
    padding-left: .426667rem;
    padding-right: .426667rem;
    position: fixed;
    top: 2.053333rem;
    background: rgb(255, 255, 255);
    li{
      width: 9.146667rem;
      height: 1.386667rem;
      line-height: 1.413333rem;
      border-bottom: .026667rem solid #ececec;
      padding-left: .213333rem;
      font-size: .3733rem;
      font-weight: 400;
    }
    .firstLi{
      border-top: .026667rem solid #ececec;
    }
  }
}
.headerIpad{
  width: 10rem;
  height: 1.5rem;
  position: fixed;
  top: 0;
  background: white;
  box-shadow: 0 .013889rem .534722rem #888888;
  z-index: 999999;
  div{
    float: left;
  }
  .logo{
    width: 3rem;
    height: 0.6rem;
    background: url('/img/logo.png') no-repeat;
    background-size: 75%;
    margin-top: .48rem;
    margin-left: .346667rem;
  }
  .right{
    margin-top: .693333rem;
    span{
      display: inline-block;
    }
    .close{
      width: .586667rem;
      height: .586667rem;
      margin-left: .586667rem;
      font-size: .4rem;
    }
    .more{
      width: .3rem;
      height: .4rem;
      position: absolute;
      right: .48rem;
      background: url('/img/san.png') no-repeat;
      background-size: 100%;
    }
  }
  ul{
       width: 10rem;
       height: 100%;
       overflow: auto;
       padding-bottom: .5333rem;
       padding-left: .426667rem;
       padding-right: .426667rem;
       position: fixed;
       top: 1.5rem;
       background: rgb(255, 255, 255);
       li{
         width: 9.146667rem;
         height: 1.1rem;
         line-height: 1.1rem;
         border-bottom: .026667rem solid #ececec;
         padding-left: .213333rem;
         font-size: .25rem;
         font-weight: 400;
       }
       .firstLi{
         border-top: .026667rem solid #ececec;
       }
  }
}
</style>