<template>
  <div
    :class="
      isMobile === null
        ? 'centent2'
        : isMobile && isMobile.includes('iPad')
        ? 'centent2iPad'
        : 'centent2Mobile'
    "
  >
    <div class="title">
      <Ju />
      <TitleText :cn="'泽悦团队'" :en="'Our Team'" />
    </div>
    <div class="main">
      <!-- 人物信息组件 -->
      <div class="bottom">
        <PeopleInfo
          @isShow="openDalog"
          v-for="item in list"
          :id="item.id"
          :key="item.id"
          :name="item.name"
          :info="item.info"
          :content="item.content"
          :class="{ clearMargin: item.id === 1 || item.id === 5 }"
          class="wow fadeIn"
          :data-wow-duration="animateConfig.duration"
          v-scroll-reveal.reset="scrollSet"
          :data-wow-delay="animateConfig.delay"
        >
          <div
            class="img"
            :style="{
              background: `url(${item.photo}) no-repeat`,
              backgroundSize: `100%`,
            }"
          ></div>
        </PeopleInfo>
      </div>
    </div>
    <!-- <transition name="fade"> -->
    <!-- <transition> -->
    <!-- 人物详细信息弹窗 -->
    <Dialog
      class="dialogclose"
      :isContent3="isContent3"
      v-show="$store.state.dialog.diaLogIsShowCentent2"
      :dialogCentent="dialogCentent"
      @closeDialog="closeDialog"
    />
    <!-- </transition> -->
  </div>
</template>

<script>
import Ju from '@/views/home/component/bigText.vue';
import TitleText from '@/views/home/component/titleText.vue';
import PeopleInfo from '@/views/home/component/peopleInfo-min.vue';
import PeopleInfoMobile from '@/views/home/component/peopleInfo-max';
import { WOW, wowInit, animateConfig, scrollSet } from '@/util/scrollReveal.js';
import Dialog from '@/views/home/component/content/dialog';
import { mapMutations } from 'vuex';
export default {
  name: 'Centent2',
  inject: {
    isMobile: {
      default: null,
    },
  },
  props: {
    diaLogIsShow: {
      type: Boolean,
      require: true,
    },
  },
  data () {
    return {
      animateConfig,
      scrollSet,
      listTop: [],
      list: [
        {
          id: 1,
          name: '屠晓洲',
          photo: '/img/tuxiaozhou.png',
          info: '合伙人',
          content:
            '超过20年跨国医药企业经验，包括百时美施贵宝、默沙东、拜耳及葛兰素克...',
          contentAll:
            '超过20年跨国医药企业经验，包括百时美施贵宝、默沙东、拜耳及葛兰素史克公司，曾担任市场总监、战略发展总监等职务。对中国医药市场具有深刻的理解。擅长领域包括战略规划，医药产品管线发展/优化，新产品引进/上市评估，产品商业上市/生命周期管理，市场职能绩效提升。上海中医药大学医学士，中欧国际工商学院工商管理硕士。',
        },
        {
          id: 2,
          name: '励备兰',
          photo: '/img/libeilan.png',
          info: '投资合伙人',
          content:
            '28年药企销售和政府事务管理经验，也是一名资深的执业医师，曾在勃林格殷...',
          contentAll:
            '28年药企销售和政府事务管理经验，也是一名资深的执业医师，曾在勃林格殷格翰、赛诺菲、葛兰素史克、赫斯特等跨国药企任职全国政府事务负责人、区域销售负责人。对中国市场医疗大健康行业的发展趋势和政策变化有深刻理解，成功推动市场准入和政策应对。同济大学医学院医学士，获得新加坡国立大学商业管理专业文凭。',
        },
        {
          id: 3,
          name: '曹文炜',
          photo: '/img/caowenwei.png',
          info: '董事总经理',
          content:
            '20年以上跨国和本地知名医药企业的实践及管理经验，其任职公司包括葛兰...',
          contentAll:
            '20年以上跨国和本地知名医药企业的实践及管理经验，其任职公司包括葛兰素史克、诺华疫苗、奈科明、广东天普及辉凌制药，曾担任辉凌中国学院院长及多年绩效优化、企业培训、及运营管理等负责人。具有敏锐的行业视角和先进的管理理念，她擅长的领域包括学习与发展、营销绩效优化、供应链整合、项目管理等。上海大学工学士，上海对外经贸学院经济学硕士。',
        },
        {
          id: 4,
          name: '唐振宁',
          photo: '/img/tangzhenlin.jpg',
          info: '财务总监',
          content:
            '16年以上丰富的财务管理、内外部审计和合规管理经验，曾先后在安永华明...',
          contentAll:
            '16年以上丰富的财务管理、内外部审计和合规管理经验，曾先后在安永华明会计师事务所、飞利浦（中国）医疗器械和弘盛投资担任内部审计、风控合规负责人、财务总监等职务。上海交通大学工商管理系、及复旦大学MBA，获硕士学位。国际注册内审师CIA。',
        },
        {
          id: 5,
          name: '陈海林',
          photo: '/img/chenhailin.png',
          info: '副总裁',
          content:
            '曾就职于上海中科新生命科技有限公司，主要负责药学发现和CMC生产优化...',
          contentAll:
            '曾就职于上海中科新生命科技有限公司，主要负责药学发现和CMC生产优化工作，涉及产品有PD-1单抗，ADC产品等。从事生物医药研发工作多年，在靶点筛选、生物医药的合成和生物大分子检测等领域有着丰富的研究经验。北京化工大学应用化学学士，Technical University of Denmark生物化工硕士，中国科学院大学生物化工博士。',
        },
      ],
      delayTime: 1,
      // isShow: false,
      dialogCentent: [],
      isContent3: '',
    };
  },
  components: {
    Ju,
    TitleText,
    PeopleInfo,
    Dialog,
    PeopleInfoMobile,
  },
  created () { },
  mounted () {
    const wowi = new WOW(wowInit);
    wowi.init();
  },
  computed: {},
  watch: {},
  methods: {
    openDalog (id, event) {
      event.stopPropagation();
      event.preventDefault();
      this.openDiaLog('diaLogIsShowCentent2');
      this.dialogCentent = this.list.filter((item) => item.id === id);
      this.isContent3 = 'content2';
    },
    ...mapMutations({
      openDiaLog: 'openDialog',
      closeDialog: 'closeDialog',
    }),
  },
};
</script>
<style scoped lang="scss">
.centent2 {
  width: 10rem;
  margin-top: 1.25rem;
  margin-bottom: 0.138889rem;
  position: relative;
  .title {
    margin-bottom: 0.0486rem;
    position: relative;
  }
  .bigText {
    margin-left: 0.333333rem;
    background: url('/img/ju.png') no-repeat center;
    background-size: 90%;
  }
  .titleText {
    position: absolute;
    left: 0.909722rem;
    bottom: 0;
  }
  .main {
    width: 8.166667rem;
    margin-left: 0.909722rem;
    padding: 0.416667rem 0 0.416667rem 0;
    // padding: .416667rem .416667rem .416667rem .4375rem;
    background: rgba(242, 242, 242, 1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .top {
      justify-content: space-around;
      padding: 0 0.8rem;
    }
    & > div {
      display: flex;
      justify-content: space-between;
    }
    .perInfo {
      color: red;
      // width: 1.1875rem;
      width: 25%;
      margin-bottom: 0.277778rem;
      float: left;
      .img {
        width: 1.1875rem;
        height: 1.583333rem;
        background-size: 100%;
        margin: 0 auto;
      }
    }
  }
  .clearMargin {
    margin-left: 0 !important;
  }
  .dialog {
    width: 4.902778rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.centent2Mobile {
  margin-top: 1.3rem;
  position: relative;
  .title {
    margin-bottom: 0.1067rem;
    position: relative;
    height: 1.6rem;
    .bigText {
      z-index: -1;
      position: absolute;
      left: 0.4267rem;
      bottom: 0.08rem;
      width: 1.4933rem;
      height: 1.6rem;
      background: url('/img/ju.png') no-repeat center center/100%;
    }
    .titleTextMobile {
      position: absolute;
      bottom: 0;
      left: 1.6rem;
    }
  }
  .main {
    width: 9.1467rem;
    padding: 0.4267rem 0;
    margin: 0 auto;
    background: #f2f2f2;
    overflow: hidden;
    .perInfo {
      width: 50%;
      margin-bottom: 0.4267rem;
      float: left;
      .img {
        width: 3.8933rem;
        height: 5.2rem;
        margin: 0 auto;
      }
    }
  }
  .dialogMobile {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.centent2iPad {
  margin-top: 1.3rem;
  position: relative;
  .title {
    margin-bottom: 0.1067rem;
    position: relative;
    height: 1.6rem;
    .bigText {
      z-index: -1;
      position: absolute;
      left: 0.4267rem;
      bottom: 0.08rem;
      width: 1.4933rem;
      height: 1.6rem;
      background: url('/img/ju.png') no-repeat center center/100%;
    }
    .titleTextMobile {
      position: absolute;
      bottom: 0;
      left: 1.6rem;
    }
  }
  .main {
    width: 9.1467rem;
    padding: 0.4267rem 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    background: #f2f2f2;
    .perInfo {
      width: 3.8933rem;
      margin-bottom: 0.4267rem;
      .img {
        width: 3.8933rem;
        height: 5.2rem;
      }
    }
  }
  .dialogMobile {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .dialogiPad {
    position: fixed;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
