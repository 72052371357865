var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.isMobile === null
      ? 'centent2'
      : _vm.isMobile && _vm.isMobile.includes('iPad')
      ? 'centent2iPad'
      : 'centent2Mobile'},[_c('div',{staticClass:"title"},[_c('Ju'),_c('TitleText',{attrs:{"cn":'泽悦团队',"en":'Our Team'}})],1),_c('div',{staticClass:"main"},[_c('div',{staticClass:"bottom"},_vm._l((_vm.list),function(item){return _c('PeopleInfo',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:(_vm.scrollSet),expression:"scrollSet",modifiers:{"reset":true}}],key:item.id,staticClass:"wow fadeIn",class:{ clearMargin: item.id === 1 || item.id === 5 },attrs:{"id":item.id,"name":item.name,"info":item.info,"content":item.content,"data-wow-duration":_vm.animateConfig.duration,"data-wow-delay":_vm.animateConfig.delay},on:{"isShow":_vm.openDalog}},[_c('div',{staticClass:"img",style:({
            background: ("url(" + (item.photo) + ") no-repeat"),
            backgroundSize: "100%",
          })})])}),1)]),_c('Dialog',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.dialog.diaLogIsShowCentent2),expression:"$store.state.dialog.diaLogIsShowCentent2"}],staticClass:"dialogclose",attrs:{"isContent3":_vm.isContent3,"dialogCentent":_vm.dialogCentent},on:{"closeDialog":_vm.closeDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }