<template>
  <div
    :class="isMobile === null ? 'contact wow fadeIn': (isMobile && isMobile.includes('iPad')) ? 'contactiPad' : 'contactMobile'"
    :data-wow-duration="animateConfig.duration"
    v-scroll-reveal.reset>
    <Banner4/>
    <div class="title">
      <BigText/>
      <TitleText :cn="'联系我们'" :en="'Contact us'"/>
    </div>
    <div class="main" >
      <div
        v-for="(item, index) in list"
        :key="index"
        v-scroll-reveal.reset="scrollSet"
        :data-wow-duration="animateConfig.duration"
        :data-wow-delay="animateConfig.delay"
        class="wow fadeIn">
        <div class="centent">
          <p class="title">{{ item.title }}</p>
          <p class="info">{{ item.info }} <br /> {{ item.email }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner4 from '@/components/banner4'
import BigText from '@/views/home/component/bigText'
import { WOW, wowInit, animateConfig, main, scrollSet } from '@/util/scrollReveal.js'
import TitleText from '@/views/home/component/titleText'
export default {
  props: {},
  inject: {
    isMobile: {
      default: null
    }
  },
  data () {
    return {
      animateConfig,
      main,
      scrollSet,
      list: [{
        title: '求职',
        info: '欢迎志同道合的伙伴联系我们：',
        email: 'hr@lakeventures.com'
      }, {
        title: 'BP',
        info: '欢迎创业团队提交非保密版本的商业计划书至：',
        email: 'info@lakeventures.com'
      }]
    }
  },
  components: {
    Banner4,
    BigText,
    TitleText,
  },
  created () {},
  mounted () {
    const wowi = new WOW(wowInit)
    wowi.init()
  },
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang='scss'>
.contact{
  margin-bottom: .5347rem;
  .banner4{
    margin-bottom: .083333rem;
  }
  .title{
    position: relative;
      margin-bottom: .0486rem;
  }
  .bigText{
    display: inline-block;
    margin-left: .076389rem;
    background: url('/img/lian.png') no-repeat bottom;
    background-size: 80%;
  }
    .titleText{
    display: inline-block;
    position:absolute;
    bottom: 0;
    left: .9097rem;
  }
  .main{
    width: 8.1875rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    div{
      width: 4.006944rem;
      height: 1.972222rem;
      background: #f2f2f2;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .centent{
        height: .840278rem;
        display: flex;
              flex-direction: column;
      justify-content: space-between;
      }
      .title{
        opacity: 1;
        color: rgba(0,0,0,0.8);
        font-size: .166667rem;
        font-weight: 500;
        text-align: center;
        line-height: .208333rem;
      }
      .info{
        color: rgba(0,0,0,0.8);
        font-size: .125rem;
        font-weight: 300;
        text-align: center;
        line-height: .208333rem;
     }
    }
  }
  .footer{
    margin-top: .534722rem;
  }
}
.contactMobile{
  .title{
    position: relative;
    margin-top: 1rem;
    margin-bottom: .1067rem;
    .bigText{
      width: 1.4933rem;
      height: 1.6rem;
      position: absolute;
      left: .36rem;
      bottom: .1067rem;
      z-index: -1;
      background:  url('/img/lian.png') no-repeat center center/100% 100%;
    }
    .titleTextMobile{
      margin-left: 1.6rem;
      margin-top: .5333rem;
    }
  }
  .main{
    div{
      display: flex;
      justify-content: center;
    }
    .centent{
      width: 90%;
      height: 4rem;
      margin: 0 auto;
      margin-bottom: .5333rem;
      display: inline-block;
      background: #f2f2f2;
      .title{
        margin-top: 1.0667rem;
        margin-bottom: .1333rem;
        font-size: .48rem;
        font-weight: 500;
      }
      .info{
        font-size: .32rem;
      }
      p{
        text-align: center;
      }
    }
  }
}
.contactiPad{
  .title{
    position: relative;
    margin-top: 1rem;
    margin-bottom: .1067rem;
    .bigText{
      width: 1.4933rem;
      height: 1.6rem;
      position: absolute;
      left: .36rem;
      bottom: .1067rem;
      z-index: -1;
      background:  url('/img/lian.png') no-repeat center center/100% 100%;
    }
    .titleTextMobile{
      margin-left: 1.6rem;
      margin-top: .5333rem;
    }
  }
  .main{
    div{
      display: flex;
      justify-content: center;
    }
    .centent{
      width: 90%;
      height: 4rem;
      margin: 0 auto;
      margin-bottom: .5333rem;
      display: inline-block;
      background: #f2f2f2;
      .title{
        margin-top: 1.0667rem;
        margin-bottom: .1333rem;
        font-size: .34rem;
        font-weight: 500;
      }
      .info{
        font-size: .25rem;
      }
      p{
        text-align: center;
      }
    }
  }
}
</style>