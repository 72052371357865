<template>
  <div v-if="dialogCentent.length > 0" :class="isMobile === null ? 'dialog' : (isMobile && isMobile.includes('iPad')) ? 'dialogiPad dialogMobile' : 'dialogMobile'">
    <span class="close el-icon-close" @click="$emit('closeDialog')"></span>
    <!-- 图片 -->
    <div v-if="isMobile === null" class="photo" :class="`photo${isContent3}`" :style="{background: `url(${dialogCentent[0].photo || dialogCentent[0].photo1}) no-repeat top center/100%`,border: dialogCentent[0].photo1 && '1px solid #ebebeb',height: dialogCentent[0].photo1 && '174px',marginBottom: dialogCentent[0].photo1 && '58px' }"></div>
    <p class="photo" v-else>
      <img :src="dialogCentent[0].photo || dialogCentent[0].photo1" class="" alt="">
    </p>
    <!-- <div class="photo" :style="{background: `url(${dialogCentent[0].photo}) no-repeat top center/100%`}"></div> -->
    <div class="dialogCentent" :class="isContent3">
      <!-- 人物名称 -->
      <p class="name" v-if="dialogCentent[0].name" v-html="dialogCentent[0].name"></p>
      <!-- 人物职位 -->
      <p class="info" v-if="dialogCentent[0].info" v-html="dialogCentent[0].info"></p>
      <!-- 人物信息内容 -->
      <p class="ContentAll" v-if="dialogCentent[0].contentAll" v-html="dialogCentent[0].contentAll"></p>
    </div>
    <p class="bottom" v-if="dialogCentent[0].official" :href="dialogCentent[0].official">
      <a class="goOfficial" :href="dialogCentent[0].official" target="_black" v-if="dialogCentent[0].official !== null">前往企业网站</a>
      <!-- <span class="closeBtn" @click="$emit('closeDialog')">关闭</span> -->
    </p>
    <!-- <span v-else class="closeBtn closeBtnPc" @click="$emit('closeDialog')">关闭</span> -->
  </div>
</template>

<script>
export default {
  name: 'DiaLog',
  inject: {
    isMobile: {
      default: null
    }
  },
  props: {
    dialogCentent: {
      type: Array,
      require: true
    },
    isContent3: {
      require: true,
      type: String
    }
  },
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {
  },
  computed: {},
  watch: {},
  methods: {

  },
}
</script>
<style scoped lang='scss'>
  .dialog{
    width: 100%;
    background: white;
    box-shadow: 0 0 .104167rem #c2c2c2;
    z-index: 999;
    .photo{
      width: 1.875rem;
      height: 2.291667rem;
      margin-left: 1.493056rem;
      margin-top: .176806rem;
    }
    .photocontent3{
     height: 1.491667rem;
    }
    .photocontent2{
      height: 2.291667rem;
    }
    .dialogCentent{
      width: 4.263889rem;
      // min-height: 2.569444rem;
      margin-top: .111111rem;
      margin-left: .319444rem;
      margin-bottom: .479167rem;
      // background: rgb(255, 255, 255) url('/img/Frame 7.png') no-repeat left top ;
      background-size: 1.318333rem 2.573889rem;
      .name{
        color: #000;
        font-size: .125rem;
        font-weight: 500;
        margin-top: 0.104167rem;
        margin-bottom: 0.064167rem;
        text-align: center;
      }
      .info{
        margin-top: .069444rem;
        margin-bottom: .166667rem;
        color: #000;
        font-size: .125rem;
        font-weight: 400;
        text-align: center;
      }
      .ContentAll{
        color: #000;
        font-size: .1111rem;
        line-height: 0.179rem;
        font-weight: 300;
      }
    }
    .content3,.content2{
      background: #fff !important;
      height: auto !important;
      min-height: auto !important;
    }
    .close{
      display: inline-block;
      width: .104861rem;
      height: .104792rem;
      margin-top: .142014rem;
      margin-left: 4.607292rem;
      font-size: .1736rem;
      cursor: pointer;
      background: url("/img/close.png") no-repeat center;
      background-size: 100%;
    }
    .bottom{
      width: 100%;
      position: relative;
      text-align: center;
      margin-bottom: .208333rem;
    }
    .closeBtn{
      width: .416667rem;
      height: .222222rem;
      display: inline-block;
      position: absolute;
      right: .1875rem;
      bottom: 0;
      cursor: pointer;
      text-align: center;
      line-height: .222222rem;
      font-size: .097222rem;
      font-weight: 400;
      background: rgba(242,243,245,1);
    }
    .closeBtnPc{
      position: absolute;
      right: .1875rem;
      bottom: .1083rem;
    }
    .goOfficial{
      color: rgba(0,0,0,0.8);
      font-size: .097222rem;
      font-weight: 500;
      line-height: .222222rem;
    }
  }
  .dialogMobile{
    z-index: 999;
    background: #fff;
    width: 80%;
    padding-bottom: .32rem;
    box-shadow: 0 0 .2667rem -3px #000;
    position: relative;
    .close{
      font-size: .5333rem;
      margin-top: .2667rem;
      margin-left: 90%;
      color: #000;
    }
    .photo{
      margin: 0 auto;
      text-align: center;
      img{
      width: 3.8933rem;
      }
    }
    .dialogCentent{
      width: 100%;
      font-size: 0.4rem;
      padding: .5333rem;
      // background: rgb(255, 255, 255) url('/img/Frame 7.png') no-repeat .5333rem top /30%;
      .name{
        margin-top: .2667rem;
        text-align: center;
        margin-bottom: .16rem;
      }
      .info{
        text-align: center;
        margin-bottom: .16rem;
      }
      .ContentAll{
        font-size: .32rem;
        color: #0e0e0e;
        line-height: .5333rem;
      }
    }
        .content3,.content2{
      background: #fff !important;
    }
    .bottom{
      width: 100%;
      font-size: .32rem;
      line-height: .3733rem;
      text-align: center;
      .closeBtn{
        position: absolute;
        right: 4%;
      }
    }
    &>.closeBtn{
      display: inline-block;
      // padding-right: 0.5333rem;
      margin-left: 88%;
      font-size: .32rem;
      text-align: right;
    }
  }
  .dialogiPad{
    z-index: 999;
    background: #fff;
    width: 80%;
    padding-bottom: .32rem;
    box-shadow: 0 0 .2667rem -3px #000;
    position: relative;
    .close{
      font-size: .5333rem !important;
      margin-top: .2667rem;
      margin-left: 90%;
      color: #000;
    }
    .photo{
      margin: 0 auto;
      text-align: center;
      img{
      width: 3.8933rem;
      }
    }
    .dialogCentent{
      width: 100%;
      font-size: 0.3rem !important;
      padding: .5333rem;
      // background: rgb(255, 255, 255) url('/img/Frame 7.png') no-repeat .5333rem top /30%;
      .name{
        margin-top: .2667rem;
        text-align: center;
        margin-bottom: .16rem;
      }
      .info{
        text-align: center;
        margin-bottom: .16rem;
        font-size: 0.23rem;
      }
      .ContentAll{
        font-size: .2rem !important;
        color: #0e0e0e;
        line-height: .5333rem;
      }
    }
        .content3,.content2{
      background: #fff !important;
    }
    .bottom{
      width: 100%;
      font-size: .2rem !important;
      line-height: .3733rem;
      text-align: center;
      .closeBtn{
        position: absolute;
        right: 4%;
      }
    }
    &>.closeBtn{
      display: inline-block;
      // padding-right: 0.5333rem;
      margin-left: 88%;
      font-size: .2rem;
      text-align: right;
    }
  }
</style>