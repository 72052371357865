/*
imgUrl: 封面图片
title: 标题
date: 副标题
centent: vue组件名称
*/
export default [
  {
    imgUrl: '/img/03-25/tow.png',
    title: '热烈欢迎励备兰女士正式加入泽悦创投',
    date: '泽悦创投',
    centent: 'sanYueErShiJiuHaoTwo'
  }, {
    imgUrl: '/img/yimai.jpg',
    title: '泽悦创投投资企业一脉阳光港股上市',
    date: '泽悦创投',
    centent: `yiMaiYangGuang`
  }, {
    imgUrl: '/img/03-25/tow.png',
    title: '热烈欢迎唐振宁先生加入泽悦创投',
    date: '泽悦创投',
    centent: 'sanYueErShiJiuHaoOne'
  }, {
    imgUrl: '/img/12-05/33.jpg',
    title: '张江集团&泽悦创投被投企业交流会成功举办',
    date: '张江科投 ',
    centent: 'shierYueWuSix'
  },
  {
    imgUrl: '/img/12-05/52.jpg',
    title: '昂扬奋进 接续进博 | 上药云健康：争分夺秒，携手奋进在进口创新药商业化之路上',
    date: '上药云健康 ',
    centent: 'shierYueWuFive'
  },
  {
    imgUrl: '/img/12-05/16.png',
    title: '祝贺！元启生物研发中心正式启用',
    date: '元启生物',
    centent: 'shierYueWuFour'
  },
  {
    imgUrl: '/img/12-05/13.jpg',
    title: '奥然生物核心技术专利喜获国际授权',
    date: '奥然生物 ',
    centent: 'shierYueWuThree'
  },
  {
    imgUrl: '/img/12-05/4.jpg',
    title: 'Prizvalve®球扩瓣亮相2022第二届国际心血管3D打印技术高峰论坛',
    date: '纽脉医疗 ',
    centent: 'shierYueWuTow'
  },
  {
    imgUrl: '/img/12-05/01.jpg',
    title: '中国检科院院长李文涛带队到清谱科技调研交流',
    date: '清普科技 ',
    centent: 'shierYueWuOne'
  },
  {
    imgUrl: '/img/qiaoQianZhiXi.jpg',
    title: '贺泽悦创投乔迁之喜',
    date: '2022-9-28',
    centent: 'qiaoQian'
  }, {
    imgUrl: '/img/zhuTiYanJiang.jpg',
    title: '“医药创投2.0模式思考”-BioBAY合作基金暨产业论坛泽悦创投主题演讲',
    date: '2022-09-28',
    centent: 'zhuTiYanJiang'
  }, {
    imgUrl: '/img/zhongbang.png',
    title: '重磅！第21届国际烧伤学会年会成功召开 | 赛德迪康作为中国唯一受邀发表主题演讲的企业，携颠覆式技术产品亮相行业最高级别盛会',
    date: '来源：赛德迪康',
    centent: 'zhongbang'
  }, {
    imgUrl: '/img/xinkai.png',
    title: '砥砺创新|心凯诺医疗再次喜获上海市高新技术成果转化项目认定',
    date: '来源：心凯诺医疗',
    centent: 'xinkai'
  }, {
    imgUrl: '/img/jinfang.png',
    title: '劲方医药登陆“2022年中胡润全球瞪羚企业”榜单',
    date: '来源：劲方医药',
    centent: 'jinfang'
  }, {
    imgUrl: 'img/10-26/c.png',
    title: '又一喜讯！普昂胰岛素注射器（ISY）拿到国内注册证!',
    date: '来源： 普昂Promisemed',
    centent: 'shiYueErShiLiuOne'
  }, {
    imgUrl: 'img/10-26/e.png',
    title: '元启生物荣获 2021 BioBAY「成长奖」!',
    date: '来源：元启生物',
    centent: 'shiYueErShiLiuTow'
  }, {
    imgUrl: 'img/10-26/image9.png',
    title: '【重磅】心凯诺 “SkyNova” 外周血管支架系统临床试验完成入组',
    date: '来源：心凯诺医疗',
    centent: 'shiYueErShiLiuThree'
  }, {
    imgUrl: 'img/10-26/image13.jpeg',
    title: '开工喜报｜奥然生物Galaxy Pro一体机获批NMPA三类证',
    date: '来源：奥然生物',
    centent: 'shiYueErShiLiuFour'
  }, {
    imgUrl: 'img/10-26/image14.png',
    title: '奥然生物（Igenesis）成功完成数亿元人民币C轮融资',
    date: '来源：奥然生物',
    centent: 'shiYueErShiLiuFive'
  }, {
    imgUrl: 'img/10-26/image21.jpeg',
    title: '喜讯｜奥然生物5个产品获得欧盟IVDR CE 认证',
    date: '来源：奥然生物',
    centent: 'shiYueErShiLiuSix'
  }, {
    imgUrl: 'img/10-26/image24.png',
    title: '喜讯！奥精医疗上榜北京市专精特新“小巨人”企业名单',
    date: '来源：奥精医疗',
    centent: 'shiYueErShiLiuSeven'
  }, {
    imgUrl: 'img/10-26/image25.png',
    title: '赛岚医药宣布与罗氏上海创新中心达成研发合作协议',
    date: '来源：赛岚医药',
    centent: 'shiYueErShiLiuEight'
  }, {
    imgUrl: 'img/10-26/image30.jpeg',
    title: '明星VC押注，初创开发「新一代」表观遗传学药物，预计今年底首条管线进临床|专访赛岚医药',
    date: '来源：赛岚医药',
    centent: 'shiYueErShiLiuNine'
  }, {
    imgUrl: '/img/jinFangYiYao.png',
    title: '劲方医药与SELLAS生命科学集团达成战略合作，开发高选择性CDK9抑制剂GFH009',
    date: '来源：劲方医药',
    centent: 'shiYueErShiLiuTen'
  }, {
    imgUrl: '/img/10-26/image40.png',
    title: '赛德迪康惊艳亮相2022美国烧伤学会年会，汇报PermeaDerm®中美两国临床进展，中国完成首例深二度临床观察重磅发布',
    date: '来源：赛德迪康',
    centent: 'shiYueErShiLiuEvelen'
  }, {
    imgUrl: '/img/10-26/image48.png',
    title: '劲方医药登陆CHC·中信证券“医疗健康最具投资价值企业50强”',
    date: '来源：劲方医药',
    centent: 'shiYueErShiLiuTwelve'
  }, {
    imgUrl: '/img/10-26/image52.jpeg',
    title: '“益”起见证荣耀！上药云健康荣登动脉网未来医疗100强·中国创新医疗服务榜单',
    date: '来源：上药云健康',
    centent: 'shiYueErShiLiuThirteen'
  }, {
    imgUrl: '/img/10-26/image56.jpeg',
    title: '劲方医药与BioLineRx达成合作，共同加速开发CXCR4抑制剂motixafortide',
    date: '来源：劲方医药',
    centent: 'shiYueErShiLiuFourteen'
  }, {
    imgUrl: '/img/10-26/image57.jpeg',
    title: '国内首创！恩盛医疗自研VeSeal静脉闭合系统首例临床入组，造福超1亿静脉曲张患者',
    date: '来源：张靖动脉网',
    centent: 'shiYueErShiLiuFifteen'
  }, {
    imgUrl: '/img/yiMo.svg',
    title: '企业百强 | 一脉阳光获称2022准独角兽企业',
    date: '来源：一脉阳光医学影像',
    centent: 'shiYueErShiLiuSixteen'
  }, {
    imgUrl: '/img/saiLanYiYao.png',
    title: '推进真正中国原研创新， 表观遗传学药企赛岚医药完成近2亿元A轮融资',
    date: '2022-1-4',
    centent: `saiLanYiYao`
  }, {
    imgUrl: '/img/jinFangYiYao.png',
    title: '泽悦创投投资企业劲方医药与信达生物达成3.12亿美元KRAS抑制剂合作协议',
    date: '来源：医药魔方',
    centent: `jinFangYiYao`
  }, {
    imgUrl: '/img/shangHaiZhiMeng.png',
    title: '泽悦创投投资企业挚盟医药TLR8激动剂CB06-036获美国FDA临床试验许可',
    date: '2021-12-16',
    centent: `shangHaiZhiMeng`
  }, {
    imgUrl: '/img/aoJingYiLiao.png',
    title: '泽悦创投投资企业奥精医疗科创板上市',
    date: '2022-1-4',
    centent: `aoJingYiLiao`
  }, {
    imgUrl: '/img/jinFangYiYao.png',
    title: '泽悦创投投资企业劲方医药获得数亿元B+轮融资，步入全球多中心临床试验发展新阶段',
    date: '来源：劲方药业',
    centent: `jinFangYiYaoTow`
  }, {
    imgUrl: '/img/aoRanShengWu.png',
    title: '泽悦创投投资企业奥然生物呼吸道病原体多重检测试剂盒等6个产品喜获欧盟CE认证',
    date: '来源：奥然生物',
    centent: `aoRanShengWu`
  }, {
    imgUrl: '/img/xinKaiNuo.png',
    title: '泽悦创投投资企业心凯诺医疗完成近亿元B轮融资，神经和外周介入双布局',
    date: '来源：医药魔方',
    centent: `xinKaiNuo`
  }, {
    imgUrl: '/img/shangHaiZhiMeng.png',
    title: '泽悦创投投资企业挚盟医药抗乙肝病毒核衣壳抑制剂ZM-H1505R获批临床',
    date: '来源：药渡',
    centent: `shangHaiZhiMengTow`
  }]