<template>
  <div>
    <img src="/img/12-05/31.png" alt="">
  </div>
</template>

<script>
export default {
  inject: {
    isMobile: {
      default: null,
    },
  },
  props: {},
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang='scss'>
img {
  display: block;
  margin: 0 auto;
}
</style>