<template>
  <div>
    <article><p class="blue">近日，奥然生物自主研发创新的核心技术专利之一——“ <strong>一种设置有微流控或纳米流控的生物反应装置”，通过PCT国际专利申请途径在欧洲专利局获得授权，并在欧洲多个主要国家正式生效</strong> 。奥然生物的知识产权首次通过PCT途径申请在国外获得授权，说明奥然生物的核心技术在海外已获得了技术先进性和创新性的认可，为奥然生物产品专利版图添上了重要的一笔。截止2022年10月，奥然生物已申请国内外知识产权总数超过160项，授权数量近百项。</p>
      <img src="/img/12-05/13.jpg" class="img3" alt="" />
    </article>
    <p >专利合作条约（Patent Cooperation Treaty，PCT），是于1970年签订的在专利领域进行合作的国际性条约，该条约提供了关于在缔约国申请专利的统一程序，专利申请人可以通过PCT途径递交国际专利申请，向多个国家申请专利。我国于1994年正式加入PCT协定，目前PCT协定已包含了156个缔约国家。欧洲专利局（European Patent Office, EPO）主要负责包含了欧洲、北非以及东南亚地区的44个国家专利的审查和授权，且是世界上实力最强、最现代化的专利局之一，拥有世界上最完整的专利文献资源，申请难度、费用和时间也居世界上各个国家和地区之首。 <strong>奥然生物在国外专利的撰写与布局工作上，由公司海外高层次人才和系统工程首席技术官共同深入研讨，为公司未来的国外专利布局延展树立了标杆。未来，奥然生物将会持续申请授权更多国际专利，为海内外客户展现奥然公司技术实力。</strong> </p>
    <div
      class="title"
      :class="
        isMobile && isMobile.includes('iPad')
          ? 'pad'
          : isMobile !== null && 'mob'
      ">多项核心技术专利的iCassette智能盒</div>
    <p class="title-b"> <span></span></p>
    <video src="/img/12-05/14.mp4" controls></video>
    <p></p>
    <p class="b">近年来，奥然生物在张江科学城管理委员会和上海国际医学园区集团的领导和支持下， <strong>实现研发费用、知识产权专利费用及专利申请授权总数的连年大幅增长</strong> ，并通过了 <strong>数个国家级、省级（上海市）和区级（浦东新区）</strong> 的科技项目立项或资助。同时奥然生物 <strong>聚集了一批机械、电子、光学、生物工程学等学科领域的专家团队</strong> ，不断地在知识产权和技术转化方面推陈出新，对自主研发的创新的全自动核酸检测分析系统产品不断的更新迭代，旨在未来将更多优秀的产品呈现给广大客户！</p>
    <img src="/img/12-05/15.jpg" class="img15" alt="" />

  </div>
</template>

<script>
export default {
  inject: {
    isMobile: {
      default: null,
    },
  },
  props: {},
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang='scss'>
img {
  display: block;
  margin: 0 auto;
  width: 100%;
}
.img15{
  width: 60%;
}
video{
  width: 100%;
}
article{
  width: 100%;
  padding: 20px;
      border: 5px solid rgb(226, 236, 251);
}
.blue{
  color: rgb(14, 79, 168) !important;
}
.title{
  color: rgb(14, 79, 168) !important;
  text-align: center !important;
  font-weight: bold !important;
  font-size: 24px !important;
  &.mob{
    font-size: 16px !important;
  }
}
.title-b{
  text-indent: unset !important;
    text-align: center;
      span{
        display: inline-block;
      background-color: rgb(14, 79, 168);
    height: 4px;
    width: 100px;
  }
}
p.b{
  padding: 25px 23px 23px;
      border: 1px dashed rgb(14, 79, 168);
}
</style>