const getters = {
  userInfo: state => state.user.userInfo,
  token: state => state.user.token,
  stsToken: state => state.user.stsToken,
  companyName: state => state.user.userInfo.detail.customer.companyName,
  fromRouter: state => state.user.fromRouter,
  upatePasswordShow: state => state.user.upatePasswordShow,
  diaLogIsShow: state => state.dialog.diaLogIsShow,
  navBarItemActive: state => state.home.navBarItemActive,
  navBarItemActiveClick: state => state.home.navBarItemActiveClick,
}
export default getters
