<template>
  <div class="banner2" >
    <div :class="isMobile === null ? 'title' : 'tltelMobile'">
      <Guan />
      <TitleText :cn="'泽悦价值观'" :en="'Our Value'"/>
      <div style="clear: both"></div>
    </div>
    <!-- <Title :cn="'泽悦价值观'" :en="'Opinions about Value'" :background="` url('/img/guan.png') no-repeat center/90%`"/> -->
    <div :class="isMobile === null ? 'main' : (isMobile && isMobile.includes('iPad'))? 'mainiPad': 'mainMobile'">
      <img class=" bannerTow" src="/img/bannar2.png" alt="" >
      <div
        class="right wow fadeIn"
        v-scroll-reveal.reset="scrollSet"
        :data-wow-duration="animateConfig.duration"
        :data-wow-delay="animateConfig.delay">
        <ul>
          <li class="title">博学慎思，明辨笃行</li>
          <li>• 多元化组合，全球化视角</li>
          <li>• 基于市场的商业逻辑</li>
          <li>• 穷尽问题，探寻本质</li>
          <li>• 注重创新的落地成效</li>
        </ul>
        <div class="frame"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Guan from '@/views/home/component/bigText'
import { WOW, wowInit, animateConfig, scrollSet } from '@/util/scrollReveal.js'
import TitleText from '@/views/home/component/titleText'
export default {
  name: 'Banner2',
  props: {},
  inject: {
    isMobile: {
      default: null
    }
  },
  data () {
    return {
      animateConfig,
      scrollSet
    }
  },
  components: {
    Guan,
    TitleText,
  },
  created () {},
  mounted () {
    const wowi = new WOW(wowInit)
    wowi.init()
  },
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang='scss'>
.banner2{
  width: 10rem;
  margin-top: .222222rem;
  // position: relative;
  .title{
    position: relative;
    .bigText{
      margin: .145833rem 0 0 .27rem;

      background: url('/img/guan.png') no-repeat bottom/95%;
    }
    .titleText{
      position: absolute;
      bottom: .0486rem;
      left: .9444rem;
    }
  }
  .main{
    position: relative;
      .bannerTow{
    width: 5.375rem;
    height: 3.819444rem;
    // position: absolute;
    margin-left: .909722rem;
  }
  .right{
    width: 4.4375rem;
    height: 2.986111rem;
    position: absolute;
    left: 5.5625rem;
    top: .5625rem;
     letter-spacing: 1px;
    background: rgba(255, 255, 255, 0.75);
    ul{
      float: left;
      margin-top: .333333rem;
      margin-left:.222222rem;
      .title{
        line-height: .277778rem;
        color: rgba($color: #000000, $alpha: 1);
        font-weight: lighter;
        // font-weight: 400;
        font-size: .166667rem;
      }
      li{
        color: #000000;
        font-size: .125rem;
        font-weight: lighter;
        line-height: .277778rem;
      }
    }
    .frame{
      width: 1.298611rem;
      height: 2.5625rem;
      margin: .215278rem .826389rem .208333rem 2.326389rem;
      background: url('/img/frame8.svg') no-repeat;
      background-size: contain;
    }
  }
  }
  .tltelMobile{
    margin: 1.4933rem  .2133rem .1067rem .4267rem;
    position: relative;
    height: 1.6rem;
    margin-top: .9333rem;
    .bigText{
      z-index: -1;
      position: absolute;
      bottom: -0.08rem;
      width: 1.4933rem;
      height: 1.6rem;
      background: url('/img/guan.png') no-repeat center/100%;
    }
    .titleTextMobile{
      margin-left: 1.2rem;
      position: absolute;
      bottom: 0;
    }
  }
  .mainMobile{
    width: 100%;
    text-align: center;
    img{
      width: 6.64rem;
      height: 4.6933rem;
    }
    .right{
      position: relative;
      top: -1.5rem;
      left: 2.4rem;
      width: 7.36rem;
      padding: 0.25rem 0 .24rem .3rem;
      line-height: .6667rem;
      font-weight: lighter;
      background: rgba($color: #fff, $alpha: 0.75);
      ul{
        li{
          text-align: left;
          font-size: .3733rem;
          color: rgba(0, 0, 0, 1);
        font-weight: 400;

        }
        .title{
          font-size: .4267rem;
        }
      }
      .frame{
        width: 1.731707rem;
        height: 3.8133rem;
        position: absolute;
        right: 0.6rem;
        top: 0.65rem;
        background: url('/img/frame8Mobile.png') no-repeat;
        background-size: contain;
      }
    }
  }
  .mainiPad{
    width: 100%;
    text-align: center;
    img{
      width: 6.64rem;
      height: 4.6933rem;
    }
    .right{
      position: relative;
      top: -1.5rem;
      left: 2.4rem;
      width: 7.36rem;
      padding: 0.25rem 0 .24rem .3rem;
      line-height: .6667rem;
      font-weight: lighter;
      background: rgba($color: #fff, $alpha: 0.75);
      ul{
        li{
          text-align: left;
          font-size: .25rem;
          color: rgba(0, 0, 0, 1);
          font-weight: 400;

        }
        .title{
          font-size: .3267rem;
        }
      }
      .frame{
        width: 1.731707rem;
        height: 100%;
        position: absolute;
        right: 0.6rem;
        top: 0.65rem;
        background: url('/img/Frame7Mobile.png') no-repeat top center/100%;
      }
    }
  }
}
</style>