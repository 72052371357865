<template>
  <div :class="isMobile === null ? 'pc' : 'mobile'">
    <p>
      近日，奥然生物（Igenesis）成功完成数亿元人民币C轮融资。本次融资由晨兴创投领投，山蓝资本、硅港资本、康华生物跟投，原股东邦勤资本以及天使投股东追加投资，劢柏资本、生命资本担任财务顾问。本次融资是继2020年深创投领投B轮融资后，奥然生物的又一次成功融资。募得资金将主要用于临床产品注册、产品商业化推进、全自动生产线扩建和新产品研发等方面。
    </p>
    <img class="img14" src="/img/10-26/image14.png" alt="" />
    <p></p>
    <img class="img15" src="/img/10-26/image15.png" alt="" />
    <p>
      奥然生物成立于2015年，集研发、生产、销售于一体，是国内第一批专注于即时分子诊断技术的创新型高科技医疗器械企业。创始人兼CEO余家昌先生，深耕即时分子诊断领域二十余年，凭借其创研兼具的知识背景，吸引了一批国内外尖端人才，公司核心团队更是本领域能力卓越、经验丰富的佼佼者。
    </p>
    <p>
      在精准医疗、个性化医疗的大背景下，分子诊断是体外诊断产业中增长最快的领域。荧光聚合酶链反应技术（荧光PCR）是分子检测的金标准。但传统荧光PCR受流程繁琐、操作复杂、集中送检、专业人员操作等因素影响很大程度上限制了分子诊断行业的快速发展。而即时分子诊断技术，在具备传统荧光PCR优异性能的同时，又突破了现有检测技术对人员及场所的限制，有效缩短了检测用时，极大提升了便捷程度，是核酸检测技术发展的必然趋势。
    </p>
    <p>
      秉承“安全、快速、便捷、精确”的设计理念，奥然生物的产品是真正做到核酸提取、扩增、分析一体化的系统：操作简单、无需复杂培训；全程封闭检测、杜绝污染、保证生物安全；1-1.5小时即可检测完毕；并可自由选择通量。凭借其始终走在同行前列的综合性能，奥然生物的产品已在海内外医院、检验、科研机构开展使用，并与多家产业内上下游知名企业开展战略合作。
    </p>
    <img class="img16" src="/img/10-26/image16.jpeg" alt="" />
    <p>
      奥然生物致力于成为高科技与生物技术结合的即时分子诊断引领者，从未停止技术赓续和迭代的脚步，现已申请近百项国内外知识产权，获73项授权。依托自主研发的创新实时7色荧光PCR分析系统，独创的
      iCassette智能盒技术、最高54重多色熔解曲线检测分析技术、冻干自动化技术、磁珠转移结构发明专利，公司产品不仅适用于传染病检测，正在向肿瘤早筛、遗传病、性病检测、个性化用药等多个检测领域突破和拓展；确保了奥然生物在后疫情时代，有能力处理更多样、更复杂的样品，适用于更广阔的应用场景。
    </p>
    <div v-for="(item, index) in list1" :key="index">
      <h4 class="list">{{ item.t }}</h4>
      <p class="list">{{ item.c }}</p>
    </div>
    <div class="guanyu">
      <div class="guanyu-item" v-for="(item, index) in list" :key="index">
        <h4>
          <span>关于</span>
          <div class="triangle"></div>
          <div class="title">{{ item.t }}</div>
        </h4>
        <p>{{ item.c }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  inject: {
    isMobile: {
      default: null,
    },
  },
  data () {
    return {
      list1: [
        {
          t: '奥然生物创始人兼CEO余家昌先生表示',
          c: '奥然生物的即时分子诊断技术在国内处于领先的地位，我们的多款产品已完成研发，进入批量生产阶段。非常感谢本轮投资人晨兴创投、山蓝资本、硅港资本、康华生物对公司的认可，也感谢原股东邦勤资本以及天使投股东持续的支持。我们将不断推进新品的研发，加快产品的市场化进程。奥然的愿景是使核酸诊断更安全、更简捷、更精确。我们愿意和广大同道一起努力，使先进的即时分子诊断技术早日服务于大众，使医疗检验机构没有难做的核酸检测！',
        },
        {
          t: '晨兴创投董事总经理薛文煜先生表示',
          c: '分子POCT一直被誉为IVD行业成长最快的细分领域，但从国内来看，解决方案契合临床需求、技术成熟度高、以及知识产权清晰的产品仍然十分稀缺，奥然生物是难得的三者兼具的企业。晨兴创投很高兴成为奥然的股东，未来将借助自身的国际化背景以及全球化资本和产业资源，助力公司更好更快的发展。',
        },
        {
          t: '山蓝资本创始及执行合伙人刘道志博士表示',
          c: '分子POCT是IVD产业发展升级的一个重要细分方向，应用场景丰富，行业发展空间广阔。奥然生物创始人具备成功的海外创业经历以及丰富的行业经验，团队在分子POCT技术领域具备深厚的积淀，在多重指标检测、复杂样本检测等产品性能方面具备业内领先的差异化优势，同时公司依赖自主研发实现的自动化生产解决方案，为产品的大规模商业化落地奠定了坚实的基础。山蓝有幸参与本轮融资，期待未来我们的产业资源能够助力公司产品更广泛地为临床与患者带来价值。',
        },
        {
          t: '硅港资本创始合伙人何欣先生表示',
          c: '奥然生物致力于成为即时分子诊断的国际引领者，其全自动分子诊断PCR一体机以及多联检试剂能比肩国外最先进的分子诊断POCT产品，样本进，结果出，模块化设计灵活性高，应用广泛，拓展性强，同时能满足国内外临床应用中快速精准地做病原体多联检筛查的产品需求，相信在后疫情时代，奥然生物的产品能持续独树一帜引领全球市场。',
        },
        {
          t: '康华生物董事长杨致亭先生表示',
          c: '作为深耕POCT行业25年的企业，我们一直在探索去中心化和分级诊疗场景下的检测需求，我们深刻认同奥然对于分子POCT产品应用场景的理解，充分认可奥然在集成卡盒、设备匹配、冻干工艺以及自动化产线等技术领域的研究成果和技术积累，希望未来康华能够助力奥然，成为中国分子POCT的领军者。',
        },
      ],
      list: [
        {
          t: '晨兴创投',
          c: '晨兴创投（Morningside Ventures）隶属于晨兴集团，在全球进行高科技风险投资。自1986年创立以来，晨兴创投致力于帮助处于初创期和成长期的生物技术企业，投资涵盖新药研发、临床诊断、医疗器械、医疗服务及数字健康等诸多领域。晨兴创投拥有一支优秀的投资管理团队，多数成员具备公司运营和创业的经验，对于生命科学的前沿、医疗健康产业及不同区域的创业及投资环境，有着深入的理解。',
        },
        {
          t: '山蓝资本',
          c: '山蓝资本成立于2014年，是一家专注于早期和成长期医疗健康产业投资的专业医疗基金，专注于生物医药、医疗器械（体外诊断和基因技术、微创介入、植入器械、微创外科器械及医疗机器人）等高成长的医疗细分领域，已经投资了近40家医疗明星企业。山蓝资本的核心团队由医疗领域拥有二十多年产业经验的上市公司创始人、生物技术及医疗设备等顶尖专业人士组成，对医疗细分领域产业本质和行业长期发展规律有着深刻的理解和丰富的经验，精准高效的助力被投企业的快速发展。',
        },
        {
          t: '硅港资本',
          c: '硅港资本是一家专注于全球早期、成长期的新兴科技领域的国际化投资机构。创始合伙人何欣先生曾任英特尔资本副总监、美国凯雷集团中国区总裁和美国锐盛管理集团亚洲区总裁。硅港资本的其他合伙人为科技行业产业投资人和连续成功创业者，投资团队汇聚顶尖人才，对半导体、互联网IT及软硬件产业发展有深刻见解。',
        },
        {
          t: '康华生物',
          c: '山东康华生物医疗科技股份有限公司成立于1996年，致力于生命健康、精准医疗产品的研发与创新，集体外诊断试剂、检验分析仪器等产品研发、生产、销售为一体，为客户提供医学诊断服务整体化解决方案。主营业务涵盖临床检验设备、体外诊断试剂、医疗电子等多个领域，拥有酶联免疫、POCT、化学发光、生化检测、分子检测五大平台，已注册产品达500余种，产品线之长，批准文号之多均在业内屈指可数，销售网络遍及全球140多个国家和地区。',
        },
        {
          t: '邦勤资本',
          c: '邦勤资本深耕生物医疗领域，聚焦中早期投资，是一家处在快速成长期的新锐基金公司。成立不足五年的时间，邦勤资本作为基金管理人已成功发起近10支基金，投资孵化企业近20家。目前，邦勤资本私募基金管理人品牌已初具影响力，新基金邦勤兴睿也获得深圳市天使母基金、深圳坪山引导基金及多家上市公司和知名企业出资。',
        },
      ],
    }
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang="scss">
.pc {
  .guanyu {
    border: 1px solid #0e4fa8;
    padding: 0.1389rem;
    .guanyu-item {
      h4 {
        height: 0.2604rem;
        display: flex;
        align-items: center;
        background: #f7f6f9;
        span {
          width: 0.4354rem;
          height: 100%;
          background: #0e4fa8;
          text-align: center;
          line-height: 0.2604rem;
          color: #fff;
        }
        .triangle {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 0px 7px 9px;
          border-color: rgba(255, 255, 255, 0) rgb(62, 62, 62)
            rgba(255, 255, 255, 0) rgb(14, 79, 168);
          margin-right: 0.0347rem;
        }
        .title {
          color: #0e4fa8;
        }
      }
    }
  }
  .img14 {
    width: 60%;
    display: block;
    margin: 0 auto;
  }
  .img15 {
    display: block;
    margin: 0 auto;
  }
  p.list {
    background: #f2f6fd;
    padding: 0.1389rem;
    box-sizing: border-box;
  }
  h4.list {
    color: #0e4fa8;
  }
}
.mobile{
  h4{
    line-height: unset !important;
  }
  .guanyu-item{
    h4{
      display: flex;
      .triangle{
        width: .0694rem;
      }
    }
  }
}
</style>
