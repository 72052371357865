import VueScrollReveal from 'vue-scroll-reveal'
import { WOW } from 'wowjs'
// 默认配置选项
export const main = {
  class: 'v-scroll-reveal',
  duration: 500, // 执行时长
  delay: 0, // 延时时间
  desktop: false, // PC 是否执行
  scale: 1, // 执行方式
  distance: '0px', // 执行距离
  reset: false, // 是否重复执行
  mobile: false, // 是否在移动端执行
  useDelay: 'always', // 延时执行方式
}
export const scrollSet = {
  duration: 500, // 执行时长
  delay: 500, // 延时时间
  desktop: false, // PC 是否执行
  scale: 1, // 执行方式
  distance: '0px', // 执行距离
  reset: false, // 是否重复执行
  mobile: false, // 是否在移动端执行
  useDelay: 'always', // 延时执行方式
}
export { VueScrollReveal }
export { WOW }
export const wowInit = {
  boxClass: 'a', // 需要执行动画元素的 class
  animateClass: 'animated', // 动画库
  offset: 0, // 距离可视区域多少开始执行动画
  mobile: false, // 是否在移动端执行动画
  live: true // 异步加载的内容是否有效
}
export const animateConfig = {
  duration: '0', // 动画执行时间
  delay: '0' // 动画延迟执行时间
}