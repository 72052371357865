<template>
  <div class="content wow fadeIn" :class="(isMobile && isMobile.includes('iPad')) ? 'contentiPad' : isMobile !== null && 'contentMobile'" :data-wow-duration="animateConfig.duration">
    <!-- class 对于标题过长的文章使用flex布局：上下居中 -->
    <Banner4 :title="list.title" :class="{longTitle:longTitle.includes(list.centent)}" :company="list.centent === 'sanYueErShiJiuHaoOne' ? '' : 'sanYueErShiJiuHaoTwo' ? '' : '泽悦创投'" :date="list.date"/>
    <div class="text wow fadeIn" :data-wow-duration="animateConfig.duration" :data-wow-delay="animateConfig.delay">
      <component :is="list.centent"></component>
    </div>

  </div>
</template>

<script>
import { WOW, wowInit, animateConfig } from '@/util/scrollReveal.js'
import Banner4 from '@/components/banner4'
import list from './import-list'
export default {
  name: 'Content',
  inject: {
    isMobile: {
      default: null
    }
  },
  props: {
  },
  data () {
    return {
      animateConfig,
      list: this.$route.params,
      longTitle: ['zhongbang']
    }
  },
  components: {
    Banner4,
    ...list
  },
  created () {
    this.list.title || this.$router.push({ path: '/zeyuequan' })
  },
  beforeMount () {
  },
  mounted () {
    const wowi = new WOW(wowInit)
    wowi.init()
  },
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang='scss'>
::v-deep img {
  max-width: 100%;
}
.longTitle{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 .2667rem 0;
}
.contentMobile{
  .text{
    width: 90% !important;
    &::v-deep p{
      font-size: .3733rem !important;
      line-height: .64rem !important;
      overflow: hidden;

    }
    &::v-deep span.zhujie{
      margin-bottom: 1rem !important;
      font-size: .2133rem !important;
    }
    &::v-deep h4{
      font-size: .48rem;
      font-weight: 900;
      margin-bottom: .4rem;
    }
    &::v-deep .cankao{
      font-size: .2667rem !important;
    }
  }
}
.contentiPad{

  .text{
    width: 90% !important;
    &::v-deep p{
      font-size: .2rem !important;
      line-height: .54rem !important;
      overflow: hidden;
    }
    &::v-deep span.zhujie{
      margin-bottom: 1rem !important;
      font-size: .12rem !important;
    }
    &::v-deep h4{
      font-size: .3rem;
      font-weight: 900;
      margin-bottom: .4rem;
    }
    &::v-deep .cankao{
      font-size: .2667rem !important;
    }
  }
}
.content{
  background: #f0f0f0;
    padding-bottom: .4306rem;

  .banner4{
    margin-bottom: .333333rem;
  }
  .text{
    width: 7.986111rem;
    margin: 0 auto;
    background: white;
    padding: .333333rem .583333rem;
    font-size: .125rem;
    &::v-deep p{
      margin-bottom: .4167rem;
      font-size: .125rem;
      color: rgba($color: #000000, $alpha: 0.9);
      text-indent: 2em;
      line-height: .2778rem;
      font-weight: lighter;
    }
    &::v-deep h4{
      font-weight: 700;
      line-height: .2778rem;
    }
    &::v-deep .aoRan{
      width: 100%;
    }
    &::v-deep .ce{
      display: block;
      width: 1rem;
      margin-left: 2em;
    }
        &::v-deep span.zhujie{
      display: inline-block;
      width: 100%;
      text-align: center;
      margin-bottom: 0.144rem;
      color: rgb(128, 128, 128);
    font-size: 0.083333rem;
    }

  }
  .footer{
    margin-top: .333333rem;
  }
}
</style>