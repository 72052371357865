<template>
  <div>
    <img src="/img/12-05/16.png" class="img16" alt="" />
    <span class="zhujie">落成庆典现场</span>
    <article>
      <p>
        2022年11月10日，中国苏州，
        <strong>元启生物制药苏州研发中心正式投入运营。</strong>
      </p>
      <p>
        凭借
        <strong
        >经验丰富的新药猎手，充满激情的高效团队，创新多维的合作模式</strong
        >
        ，聚焦转录调控的新治疗，元启成立两年来，备受行业瞩目，建立了在自免、炎症及肿瘤等疾病方向具有国际竞争力的管线。
      </p>
      <p>
        活动现场，苏州工业园区党工委副书记、管委会主任
        <strong>林小明</strong> ，苏州工业园区党工委委员、管委会副主任
        <strong>倪乾</strong> ，苏州工业园区生物产业发展有限公司董事长、总裁
        <strong>殷建国</strong> ，元启生物制药创始人/CEO
        <strong>丁元华</strong>
        博士，上海科技大学iHuman研究所执行所长、元启生物制药科学顾问委员会主席
        <strong>刘志杰</strong>
        教授，上海道禾志医私募基金管理有限公司总经理、上海道禾长期投资管理有限公司合伙人
        <strong>周灏</strong> 博士等出席研发中心开业庆典。
      </p>
    </article>
    <p></p>
    <div class="title"><span>落成庆典</span></div>
    <img src="/img/12-05/17.jpg" class="img17" alt="" />
    <p class="title-list">丁元华：元启生物制药创始人/CEO</p>
    <p>
      元启生物制药成立两年来，建立了在自免、炎症及肿瘤等疾病方向具有国际竞争力的管线，八个在研项目中，
      两个正在进入临床前研究，力争在2023年底或24年初进入临床阶段。
    </p>
    <p>
      成立以来，元启建立了国际化的团队，既有多位有多年国际药企研发和管理经验的老司机，也有具有执行力和领导力的年轻骨干团队，累计融资超过2亿元人民币。
    </p>
    <p>
      感谢苏州工业园区、生物产业发展公司、薄荷/安龙等投资人以及合作伙伴给予的的支持，感谢A轮投资人的支持使元启在今年上半年疫情期间能够完成1.35亿人民币的融资，使我们可以加速研发项目的推进。同时能够入驻BioBAY这样国际性有竞争力的园区，和苏州这个有深厚文化底蕴的城市感到荣幸和骄傲。
    </p>
    <p>
      元戎启行，笃实创新，厚德济民，热切盼望与在座的各位一起共同创造一个美好的未来，为病人造福，创造辉煌的中国式现代化。
    </p>
    <img src="/img/12-05/18.jpg" class="img18" alt="" />
    <p class="title-list">
      刘志杰：上海科技大学iHuman研究所执行所长、元启生物制药科学顾问委员会主席
    </p>
    <p>
      随着中国在生物技术和新药研发领域的快速发展，做真正源头创新的环境日趋成熟，非常希望在元启探索中国新药研发新时代的产学研合作模式，加速高校和研究所科研成果转化，利用蛋白结构生物学最新研究指导具有差异化优势新药的开发，为健康中国的推动贡献一份力量。
    </p>
    <img src="/img/12-05/19.jpg" class="img18" alt="" />
    <p class="title-list">
      周灏：上海道禾志医私募基金管理有限公司总经理，上海道禾长期投资管理有限公司合伙人
    </p>
    <p>
      2008年，我从美国回来开始做产业投资，看到中国生物医药在一波波风浪中成长，现在面临的大浪淘沙是产业一个洗牌的过程。道禾和其他投资人在今年上半年决定投资元启生物制药，主要是基于元启核心团队在新药研发领域产业经验的积累深厚，强大科学顾问团队的支持，独特技术平台持续产生新项目，以及团队丰富的BD经验和与国内外大型药企的网络。
    </p>
    <img src="/img/12-05/20.jpg" class="img18" alt="" />
    <p class="title-list">倪乾：苏州工业园区党工委委员、管委会副主任</p>
    <p>
      热烈祝贺元启生物制药苏州研发中心的开业启动，并希望元启能够继续深耕园区、开拓奋进，持续领跑小分子创新药领域，积极助力园区生物医药产业高质量发展。
    </p>
    <img src="/img/12-05/21.jpg" class="img18" alt="" />
    <span class="zhujie">邵湘红 元启生物制药CBO</span>
    <img src="/img/12-05/22.jpg" class="img18" alt="" />
    <img src="/img/12-05/23.jpg" class="img18" alt="" />
    <span class="zhujie">典礼现场花絮</span>
    <div class="title">新药研发与合作研讨会</div>
    <p>
      下午，“元戎启行，笃实创新，厚德济民”新药研发与合作研讨会，来自产学研的资深科学家和产业界专家独墅湖闭门论药。
    </p>
    <p>
      从基于结构生物学的药物设计进展、肿瘤复发机制研究与新药研发、计算化学与AI在新药研发中的应用等热点话题进行了交流与讨论，并希望不断加强产学研间交流与合作，将更具临床优势的创新性或差异化产品带给全球医患。
    </p>
    <img src="/img/12-05/24.jpg" class="img18" alt="" />
    <p class="noi">
      <strong class="green">
        欢迎词
        <br />
        元启生物制药发展策略介绍
      </strong>
      <br />
      <strong>陈金山博士</strong> 元启生物制药中国研发总经理
    </p>
    <img src="/img/12-05/25.jpg" class="img18" alt="" />
    <p class="noi">
      <strong class="green">
        AlphaFold2时代的结构生物学研究与新药研发及上科大iHuman研究所GPCR结构生物学研究最新进展
      </strong>
      <br />
      <strong>刘志杰教授</strong>
      上海科技大学iHuman研究所执行所长，元启生物制药科学顾问委员会主席
    </p>
    <img src="/img/12-05/26.jpg" class="img18" alt="" />
    <p class="noi">
      <strong class="green">
        基于结构的药物发现在G蛋白偶联受体神经系统的进展
      </strong>
      <br />
      <strong>周斌兵教授 </strong>
      上海交通大学医学院儿科转化医学研究所所长，上海交通大学医学院药理与化学生物学系主任
    </p>
    <img src="/img/12-05/27.jpg" class="img18" alt="" />
    <p class="noi">
      <strong class="green">
        基于结构的药物发现在G蛋白偶联受体神经系统的进展
      </strong>
      <br />
      <strong>杜洋教授 </strong
      >香港中文大学深圳医学院助理院长，深圳康体生物医药科技有限公司联合创始人
    </p>
    <img src="/img/12-05/28.jpg" class="img18" alt="" />
    <p class="noi">
      <strong class="green">Panel：欢聚冬日元科创，同奔暖阳启辉煌 </strong
      ><br />
      <strong class="list">丁元华</strong> 元启生物 主持<br />
      <strong class="list">程建军</strong> 上海科技大学iHuman研究所<br />
      <strong class="list">张彦涛</strong> 泰励生物<br />
      <strong class="list">余强</strong> 盛世泰科<br />
      <strong class="list">何骑</strong> 腾迈医药<br />
      <strong class="list">刘恕</strong> 道禾志医<br />
      <strong class="list">彭巍</strong> 弘晖资本
    </p>
    <img src="/img/12-05/29.jpg" alt="">
    <p class="noi">
      <strong>话题：</strong>
      <br>
      <span>1. 时下新药寒冬的各种因素及应对之举
        <br>
        2. 小分子药物研发是否已经山穷水尽
        <br>
        3. 新技术新发现如何真正成为小分子药物研发的元动力
        <br>
        4. 新时代，新元点，重启中国式早期创新之路
      </span>
      <br>
      <br>
      <strong>（更多干货内容稍后将陆续报道）</strong>
    </p>
    <img src="/img/12-05/30.jpg" alt="">
    <span class="zhujie" >新药研发与合作研讨会现场花絮</span>

    <br>
    <br>
    <div class="guanyu">- 关于元启 -</div>
    <p>元启生物制药是一家于2020年11月注册成立的生物技术公司，在中国苏州和美国波士顿均设有研发团队。元启生物由资深的国际药企研发及管理人员及充满激情的年轻科学家们共同创建，通过团队对免疫和肿瘤等疾病独到的见解，丰富的药物研发经验与合作伙伴特有的技术有效结合，同时充分利用世界各地的不同优势特别是中国团队的强有效执行力，致力成为创新药物研发的领导者。元启生物制药正在通过内部研发和外部合作方式建立自己的特色产品线，重点解决在免疫和肿瘤等相关方面尚未满足的医疗需求，竭诚为全球病人及家庭健康服务。</p>
  </div>
</template>

<script>
export default {
  inject: {
    isMobile: {
      default: null,
    },
  },
  props: {},
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang='scss'>
img {
  display: block;
  margin: 0 auto;
  width: 100%;
}
article {
  background: rgb(247, 247, 247);
  padding: 15px 17px 13px 18px;
}
.guanyu{
  text-align: center;
  font-size: 16px;
    font-weight: bold;
    color: rgba(71, 182, 193, 0.914);
}
.title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  span {
    border-bottom: 1px solid black;
  }
}
.title-list{
  text-indent: unset !important;
  font-weight: bolder !important;
}
p{
  line-height: unset !important;
}
.noi {
  text-indent: unset !important;
}
.green {
  color: rgb(13, 149, 156);
}
.list {
  display: inline-block;
  width: 3em;
  text-align-last: justify !important;
}

</style>