<template>
  <div>
    <img src="/img/zhongbang7.gif" alt="" />
    <p></p>
    <p class="color">
      2022年1月27日，由
      <strong
      >心凯诺医疗自主研发的“SkyNova”外周血管支架系统临床试验顺利完成全部受试者入组</strong
      >
      。 在此，心凯诺医疗感谢所有临床试验中心老师们的付出，您辛苦了！
    </p>
    <p>
      该注册研究是由
      <strong
      >南京市第一医院牵头，顾建平教授担任PI，全国14家权威临床试验机构共同参与的</strong
      >
      “评价外周血管支架系统治疗下肢动脉硬化闭塞症安全性和有效性的临床试验”研究。
    </p>
    <img class="img9" src="/img/10-26/image9.png" alt="" />
    <p>
      外周血管支架系统主要用于髂动脉、股动脉狭窄及闭塞的患者。SkyNova外周支架是新一代的自扩张支架，具有优异的柔顺性、贴壁性、抗疲劳性能。当支架释放到血管后，在体温下可以保持良好的持久径向支撑力，有效地撑开狭窄及闭塞的病变血管，从而恢复血流的通畅，改善远端肢体的血液循环。产品的长支架规格可用于下肢的长段动脉狭窄及闭塞的患者，可以有效减少支架的重叠释放。
    </p>
    <p>
      目前临床上常见的外周血管支架都是进口产品，国产替代的需求强劲！“SkyNova”
      临床入组的顺利完成，不仅是心凯诺医疗外周产品线的重大进展，也是外周血管支架产品国产化的曙光，<span
        class="blue"
      >希望 “SkyNova”
        能尽快上市，为下肢动脉患者带来性价比更高的临床解决方案。</span
      >
    </p>
    <div class="back">
      <img class="img10" src="/img/10-26/image10.jpeg" alt="" />
      <p> </p>
      <div class="ine">
        <div><strong>【公司简介】</strong></div>
        <div class="smallSize">
          心凯诺医疗成立于2014年，位于上海国际医学园区，专注于研发和生产高端血管微创介入产品，拥有神经介入和外周介入两大产品线，定位为聚焦神经介入和外周介入领域的平台型公司，为脑卒中、外周动脉/静脉血管疾病患者提供完整的治疗方案。
        </div>
      </div>
    </div>
    <img src="/img/10-26/image11.gif" alt="">
  </div>
</template>

<script>
export default {
  props: {},
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang="scss">
.color {
  width: 100%;
  border-radius: 0.1389rem;
  border: 1px solid #4c6ff3;
  padding: 0.0694rem;
  color: #4c6ff3 !important;
}
.img9 {
  display: block;
  margin: 0 auto;
  width: 70%;
}
.blue {
  color: #4c6ff3;
  font-weight: bold;
}
.img10 {
  width: 100%;
  display: block;
  margin: 0 auto;
}
.smallSize{
  font-size: .1042rem;
}
.back{
  background: #f3f8ff;
  padding: .2083rem;
  box-sizing: border-box;
}
.ine{
  line-height: .2778rem;
}
</style>
