<template>
  <div class="main" >
    <img src="/img/03-25/one.gif" alt="">
    <div class="top" :class="(isMobile && isMobile.includes('iPad')) ? 'contentiPad' : isMobile !== null && 'contentMobile'">
      <span></span>
      <div>
        <span class="one"></span>
        <span class="tow"></span>
      </div>
      <span></span>
    </div>
    <p>泽悦创投荣幸地宣布唐振宁先生于2023年3月27日加入公司，担任财务总监。</p>
    <img src="/img/03-25/three.jpg" alt="" class="img-one">
    <p>唐振宁先生拥有16年以上丰富的财务管理、内外部审计和合规管理经验，曾先后在安永华明会计师事务所、飞利浦（中国）医疗器械、复星医药和弘盛投资担任内部审计、风控合规负责人、财务总监等职务。</p>
    <p>加入泽悦创投前，唐振宁先生是弘盛君浩基金的财务总监和风控合规负责人，同时兼任下属控股公司的财务负责人。他从资本和企业双重视角出发，精准把控企业运营中的风险点，成功地搭建了基金和控股医药公司的财务管理体系和风控管理体系。</p>
    <p>唐振宁先生毕业于上海交通大学工商管理系、及复旦大学MBA，获硕士学位。他也是国际注册内审师CIA。</p>
    <p>泽悦创投创始合伙人吕晓翔表示：<strong>“欢迎唐振宁先生加入泽悦创投。他的加入，将带来更多元的视角和实战经验，进一步强化泽悦创投的核心竞争力。”</strong></p>
    <div class="top" :class="(isMobile && isMobile.includes('iPad')) ? 'contentiPad' : isMobile !== null && 'contentMobile'">
      <span></span>
      <div>
        <span class="one"></span>
        <span class="tow"></span>
      </div>
      <span></span>
    </div>
    <br>
    <br>
    <div class="footer">
      <img src="/img/03-25/tow.png" class="img-tow" alt="">
      <br>
      <p>泽悦创投专注于医疗大健康领域的股权投资，由行业资深人士共同创立，拥有丰富的行业资源和差异化的投资理念。基于对市场环境、医改趋势的深刻洞见，整合研发、生产、营销资源，寻找具有“底层创新”和“全球化商业潜力”的创业项目，以长期价值为基础作出投资决策。</p>
    </div>
  </div>
</template>

<script>
export default {
  inject: {
    isMobile: {
      default: null,
    },
  },
  props: {},
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang='scss'>
.main{
  img{
    display: block;
    margin: 0 auto;
  }
  .img-one{
    width: 60%;
  }
  .top{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &>span{
      width: 46%;
      height: 1px;
      background: #3b6791;
    }
    &>div{
      width: 0.3rem;
      height: 0.15rem;
      span{
        display: inline-block;
        width: 50%;
        height: 100%;
        border-radius: 50%;
      }
      .one{
        border: 1px solid #3b6791;
        transform: translateX(20%);
        position: relative;
        z-index: 3;
      }
      .tow{
        background: #ffca00;
        transform: translateX(-20%);
      }
    }
  }
  .contentMobile{
     &>div{
      width: 0.6rem;
      height: 0.3rem;
     }
  }
  .footer{
    width: 100%;
    background: #f8fafb;
    padding: 0 0.2rem 0.2rem 0.2rem;
    .img-tow{
      width: 40%;
    }
    p{
      margin-bottom: unset !important;
    }
  }
}
</style>