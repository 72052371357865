import content from '@/views/quan/content.vue'
import index from '@/views/home/index.vue'
import quan from '@/views/quan'
import contact from '@/views/contact'
const routes = [
  {
    path: '/',
    name: 'home',
    component: index,
    meta: {
      title: '首页'
    }
  }, {
    path: '/',
    name: 'home',
    component: index,
    meta: {
      title: '价值观'
    }
  }, {
    path: '/',
    name: 'home',
    component: index,
    meta: {
      title: '投资策略'
    }
  }, {
    path: '/',
    name: 'home',
    component: index,
    meta: {
      title: '创始人'
    }
  }, {
    path: '/',
    name: 'home',
    component: index,
    meta: {
      title: '团队'
    }
  }, {
    path: '/',
    name: 'home',
    component: index,
    meta: {
      title: '投资企业'
    }
  }, {
    path: '/zeyuequan',
    name: 'zeyuequan',
    component: quan,
    meta: {
      title: '泽悦圈',
      id: 6
    }
  }, {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/contact'),
    // component: contact,
    meta: {
      title: '联系我们',
      id: 7
    }
  }, {
    path: '/content',
    name: 'content',
    component: () => import('@/views/quan/content.vue'),
    // component: content,
    meta: {
      title: null,
      id: 6
    }
  }
]
export default routes