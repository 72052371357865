export default {
  state: {
    diaLogIsShowCentent1: false,
    diaLogIsShowCentent2: false,
    diaLogIsShowCentent3: false,
  },
  mutations: {
    openDialog (state, dialog) {
      // 先全部关闭，再打开触发的
      state.diaLogIsShowCentent1 = false
      state.diaLogIsShowCentent2 = false
      state.diaLogIsShowCentent3 = false
      state[dialog] = true
    },
    closeDialog (state) {
      console.log('关闭 dialog.js')
      state.diaLogIsShowCentent1 = false
      state.diaLogIsShowCentent2 = false
      state.diaLogIsShowCentent3 = false
    }
  },
}