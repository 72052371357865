<template>
  <div>
    <p>
      2022年10月9日，中国检验检疫科学研究院（中国检科院）院长、党委副书记李文涛带队到清谱科技调研交流。清谱科技创始人、清华大学副教务长、精密仪器系教授欧阳证及其他有关负责人员全程陪同。
    </p>
    <div
      class="title"
      :class="
        isMobile && isMobile.includes('iPad')
          ? 'pad'
          : isMobile !== null && 'mob'
      "
    >
      <span
        :class="
          isMobile && isMobile.includes('iPad')
            ? 'pad'
            : isMobile !== null && 'mob'
        "
      >以下内容转载于中国检科院官网： </span
      ><strong></strong>
    </div>
    <br />
    <div class="wenzhang">
      <p
        class="center"
        :class="
          isMobile && isMobile.includes('iPad')
            ? 'pad'
            : isMobile !== null && 'mob'
        "
      >
        李文涛赴在京单位调研检验检测仪器装备国产化情况
      </p>
      <span class="center">发布时间：2022-10-12 信息来源：中国检科院</span>
      <br />
      <p>
        为进一步深入贯彻落实市场监管总局“抓学习、抓调研、抓落实”活动部署，更好地推动检验检测仪器装备国产化进程，助力市场监管事业发展，2022年10月9日，院长、党委副书记李文涛带队赴清华大学精密仪器系和北京清谱科技有限公司（简称清谱科技）调研交流。
      </p>
      <img
        src="/img/12-05/01.jpg"
        class="oneImg"
        :class="
          isMobile && isMobile.includes('iPad')
            ? 'pad'
            : isMobile !== null && 'mob'
        "
        alt=""
      />
      <p>
        清谱科技是由国际质谱仪小型化倡导者、清华大学归国知名专家欧阳证教授在清华大学的大力支持下组建的高科技企业，主营高端质谱检测设备的研发、生产和服务，拥有国际前沿的便携式质谱开发经验，技术实力雄厚。
      </p>
      <p>
        在清谱科技，李文涛参观了实验室，并观摩了小型质谱分析系统应用操作演示。欧阳证对清谱科技研发团队及技术产品进行了详细介绍，他指出，质谱技术是化学分析的“金标准”，可以很好地发挥“可靠性高、通用性强”的优势，质谱分析系统的小型化是当前质谱仪的主要发展趋势之一。“十五”以来，国家对自主研发质谱仪器非常重视，提出“突破关键技术，主攻专用小型质谱仪自主研制”的目标。清谱科技成立以来，始终以打破我国质谱市场被进口仪器垄断局面为目标，坚持不做仿制、不做简单的国产替代，而是选择从零开始的小型化仪器产品创新，不断为中国高端质谱仪器行业在国际上争取主导地位。
      </p>
      <p>
        李文涛对清谱科技为推动质谱仪器国产化取得的突出成绩给予了高度肯定。他指出，党中央、国务院在今年提出加快建设全国统一大市场的重大决策部署，重点提出促进科技创新和产业升级，培育参与国际竞争合作新优势，其中推动中高端检验检测仪器设备国产化是其中的重要一环。清谱科技作为国际质谱系统小型化研发与产业化的领军企业，要充分借助国内超大规模和整体市场优势，积极在该领域抢占科技制高点、掌控技术话语权。中国检科院将与清华大学精密仪器系和清谱科技一起携手，在产学研用联合创新、标准制定、国产检测仪器设备能力验证等领域加强合作，共同推动检测仪器设备国产化进程，助力经济社会高质量发展。要立足市场监管需求，研发更多精准、高效、便携的监管装备，促进市场监管能力的有效提升。
      </p>
      <img
        src="/img/12-05/02.jpg"
        class="towImg"
        :class="
          isMobile && isMobile.includes('iPad')
            ? 'pad'
            : isMobile !== null && 'mob'
        "
        alt=""
      />
      <p>
        李文涛一行还参观了清华大学精密仪器系实验室。我院办公室、科管部、工业品所有关同志参加了调研。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  inject: {
    isMobile: {
      default: null,
    },
  },
  props: {},
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang='scss'>
img {
  display: block;
  margin: 0 auto;
}
.center {
  text-align: center;
  &.mob {
    font-size: 14px;
    line-height: 25px !important;
  }
}
p.center {
  text-indent: unset !important;
  font-size: 18px !important;
  font-weight: bold !important;
  margin-bottom: unset !important;
}
span.center {
  display: block;
  color: rgb(117, 117, 117);
  font-size: 12px;
}
.oneImg,
.towImg {
  width: 60%;
  &.mob {
    width: 90%;
  }
}
.title {
  width: 100%;
  height: 50px;
  border-bottom: 2px solid #5f91b7;
  &.mob {
    height: 45px;
  }
  span {
    color: rgb(239, 239, 239);
    font-size: 18px;
    display: inline-block;
    width: 35%;
    height: 100%;
    line-height: 50px;
    text-align: center;
    background: #5f91b7;
    &.pad {
      width: 50%;
    }
    &.mob {
      width: 70%;
      font-size: 13px;
      line-height: 45px;
      & + strong {
        border-width: 44px 0px 0px 19px;
      }
    }
  }
  strong {
    height: 0px;
    width: 0px;
    font-size: 0;
    vertical-align: bottom;
    border-style: solid;
    border-width: 49px 0px 0px 19px;
    border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0)
      rgba(255, 255, 255, 0) rgb(95, 145, 183);
  }
}
</style>