<template>
  <div
    :class="
      isMobile === null
        ? 'quan wow fadeIn'
        : isMobile && isMobile.includes('iPad')
          ? 'quaniPad'
          : 'quanMobile'
    "
    :data-wow-duration="animateConfig.duration"
  >
    <Banner4 />
    <div class="title1">
      <BigText />
      <TitleText :cn="'泽悦圈'" :en="'News'" />
    </div>
    <ul>
      <!-- <ul :class="isMobile === null && scrollReveal"> -->
      <li
        @click="goContent(item)"
        v-for="(item, index) in list"
        :class="{ mobile: isMobile }"
        :key="index"
        class="wow fadeIn"
        v-scroll-reveal.reset="scrollSet"
        :data-wow-duration="animateConfig.duration"
        :data-wow-delay="animateConfig.delay"
      >
        <div class="imgBox">
          <img :src="item.imgUrl" alt />
        </div>
        <div class="title" :class="{ mobileTitle: isMobile }">
          <div class="nei">
            <p class="itemTitle">{{ item.title }}</p>
            <p class="date">{{ item.date }}</p>
          </div>
        </div>
        <div style="clear: both"></div>
      </li>
    </ul>
  </div>
</template>

<script>
import Banner4 from '@/components/banner4'
import BigText from '@/views/home/component/bigText'
import TitleText from '@/views/home/component/titleText'
import wenan from './wenAn.js'
import { WOW, wowInit, animateConfig, scrollSet } from '@/util/scrollReveal.js'
export default {
  inject: {
    isMobile: {
      default: null,
    },
  },
  props: {},
  data () {
    return {
      animateConfig,
      list: wenan,
      scrollSet,
    }
  },
  components: {
    Banner4,
    BigText,
    TitleText,
    // Content
  },
  created () { },
  mounted () {
    const wowi = new WOW(wowInit)
    wowi.init()
  },
  computed: {},
  watch: {},
  methods: {
    goContent (item) {
      this.$router.push({
        name: 'content',
        params: item,
      })
    },
  },
  destroyed () { },
}
</script>
<style scoped lang='scss'>
.quan {
  .title1 {
    position: relative;
    margin-bottom: 0.0486rem;
  }
  .bigText {
    display: inline-block;
    margin-top: 0.0694rem;
    margin-left: 0.1389rem;
    background: url('/img/quan.png') no-repeat left bottom;
    background-size: 75%;
  }
  .titleText {
    display: inline-block;
    position: absolute;
    left: 0.825013rem;
    bottom: 0;
  }
  ul {
    width: 10rem;
    padding-right: 0.798611rem;
    :first-child {
      margin-top: 0 !important;
    }
    .mobile {
      margin-top: 0.0972rem;
      padding-bottom: 0.0972rem;
    }
    li {
      width: 8.291667rem;
      margin-left: 0.847222rem;
      margin-top: 0.2778rem;
      padding-bottom: 0.2778rem;
      border-bottom: 1px solid #e3e3e3;
      color: rgba(0, 0, 0, 0.8);
      font-size: 0.125rem;
      font-weight: 400;
      letter-spacing: 1px;
      cursor: pointer;
      .main {
        // margin-bottom: .2778rem;
      }
      .imgBox {
        width: 2.0833rem;
        height: 1.1111rem; // 跟 title 的高度一样
        margin-right: 0.4028rem;
        vertical-align: middle;
        float: left;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .title {
        height: 1.1111rem; // 跟 img 的高度一样
        margin-bottom: 0.2222rem;
        padding-top: 0.125rem;
        display: table-cell;
        vertical-align: middle;
        .nei {
          .itemTitle {
            color: rgba(0, 0, 0, 0.8);
            font-size: 0.125rem;
            font-weight: 400;
            line-height: 0.2083rem;
            letter-spacing: 0.0069rem;
          }
          .date {
            color: rgba(142, 142, 142, 1);
            font-size: 0.0972rem;
            font-weight: lighter;
            line-height: 0.1667rem;
            letter-spacing: 0.0069rem;
          }
        }
      }
    }
    &:last-child {
      margin-bottom: 0.2083rem;
    }
  }
}
.quanMobile {
  .title1 {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 0.1067rem;
    .bigText {
      z-index: -1;
      width: 1.4933rem;
      height: 1.6rem;
      position: absolute;
      bottom: 0;
      left: 0.4267rem;
      background: url('/img/quan.png') no-repeat center center/100% 100%;
    }
    .titleTextMobile {
      margin-left: 1.7867rem;
    }
  }
  ul {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 0.5333rem;
    li {
      width: 95%;
      margin-left: 50%;
      transform: translate(-50%, 0);
      margin-bottom: 0.6667rem;
      .imgBox {
        width: 100%;
        margin-bottom: 0.2667rem;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .title {
        .itemTitle {
          color: rgba(0, 0, 0, 0.8);
          font-size: 0.4rem;
          margin-bottom: 0.2667rem;
        }
        .date {
          font-size: 0.3rem;
          color: #8e8e8e !important;
        }
      }
    }
  }
}
.quaniPad {
  .title1 {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 0.1067rem;
    .bigText {
      z-index: -1;
      width: 1.4933rem;
      height: 1.6rem;
      position: absolute;
      bottom: 0;
      left: 0.4267rem;
      background: url('/img/quan.png') no-repeat center center/100% 100%;
    }
    .titleTextMobile {
      margin-left: 1.7867rem;
    }
  }
  ul {
    width: 90%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-bottom: 0.5333rem;
    li {
      width: 45%;
      margin-bottom: 0.6667rem;
      .imgBox {
        width: 100%;
        margin-bottom: 0.2667rem;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .title {
        .itemTitle {
          color: rgba(0, 0, 0, 0.8);
          font-size: 0.2rem;
          margin-bottom: 0.2667rem;
        }
        .date {
          font-size: 0.13rem;
          color: #8e8e8e !important;
        }
      }
    }
  }
}
</style>