import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/router/routes'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
const router = new VueRouter({
  scrollBehavior (to, from, saveTop) {
    if (saveTop) { return saveTop } else { return { x: 0, y: 0 } }
  },
  mode: 'history',
  routes
})

export default router
