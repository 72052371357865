<template>
  <div>
    <p>
      2021年12月16日，上海挚盟医药科技有限公司宣布，美国食品药品监督管理局（FDA）已批准其自研的小分子口服TLR8激动剂（CB06-036）的临床试验申请。上海挚盟医药科技有限公司是一家处于临床研发阶段的医药科技公司，专注于抗病毒及中枢神经系统疾病的原创型药物研发。
    </p>
    <p>
      即将在美国开启的CB06-036的一期临床试验将以健康志愿者为受试对象，评估口服递增剂量后CB06-036的安全性和耐受性,并对药物在体内的药代动力学和药效学进行研究。
    </p>
    <p>
      挚盟医药首席执行官（CEO）陈焕明博士表示：“我们团队一直在勇敢有效地探索实现乙肝功能性治愈的途径。今天很高兴收到美国FDA对我们TLR8激动剂的临床批件。我们计划与2022年初对CB06-036开展临床试验，为早日实现乙肝的功能性治愈贡献力量。”
    </p>
    <p>
      关于CB06-036 <br />慢性乙型肝炎是全球性疾病，全球约有2.4亿人为慢性乙型肝炎病毒（HBV）感染者，每年约有65万人死于慢性乙型肝炎导致的肝衰竭、肝硬化和肝细胞癌。在我国，慢性乙型肝炎病毒感染者近9300万人，其中已发展至慢性乙型肝炎需要治疗者约3000多万。乙型肝炎病毒致病机理复杂，现有的治疗药物远不能满足临床需求。Toll样受体8 (TLR8)可识别病原体衍生的单链RNA片段以触发先天性和适应性免疫反应。慢性乙型肝炎(CHB)与功能失调的免疫反应有关，因此选择性TLR8激动剂可能是一种有效的治疗选择。
    </p>
    <p>
      CB06-036是上海挚盟医药科技有限公司自主研发的用于治疗慢性乙肝的小分子口服TLR8激动剂。临床前研究表明CB06-036具有良好的选择性、活性及安全性，可以在人外周血单核细胞中诱导细胞因子，这些细胞因子能够通过多种免疫介质激活抗病毒功能。与此同时，CB06-036具有良好的肝靶向特征。
    </p>
    <p>
      Zhimeng Biopharma Announces FDA Approval of its Investigational New Drug (IND) Application for CB06-036
    </p>
    <p>
      Dec. 16th, 2021. Shanghai, China. The US Food and Drug Administration (FDA) has approved Shanghai Zhimeng Biopharma, Inc’s Investigational New Drug (IND) application for its TLR8 agonist （CB06-036） to start clinical trials for the treatment of chronic hepatitis B virus infection. The planned phase 1 study will start in January 2022 in the US to evaluate the safety, tolerability, pharmacokinetics and preliminary pharmacodynamics of CB06-036 in healthy subjects.
    </p>
    <p>
      Dr. Chen Huanming, Chief Executive Officer (CEO) of Zhimeng Pharmaceutical, commented that “Our team has been working very bravely and efficiently to explore and find effective medicines to help achieve functional cure of HBV infection. Today we are very pleased to achieve this regulatory milestone on our TLR8 agonist and are excited to bring it to clinical study early next year.”
    </p>
    <p>
      About CB06-036 <br />About 250 million people worldwide are infected with chronic hepatitis B virus (HBV), and about 650,000 people die each year from liver failure, cirrhosis and hepatocellular carcinoma caused by chronic hepatitis B. In China, there are about 90 million people with chronic HBV infection, of which about 30 million have developed chronic hepatitis B who need treatment. The pathogenic mechanism of HBV is complex, and current therapeutic drugs (including nucleoside/nucleotide analogues and immunomodulators) have obvious limitations and are clearly inadequate to address the clinical need for HBV therapeutics. Toll-like receptor 8 (TLR8) recognizes pathogen-derived single-stranded RNA fragments to trigger innate and adaptive immune responses. Chronic hepatitis B (CHB) is associated with a dysfunctional immune response, and therefore a selective TLR8 agonist may be an effective treatment option.
    </p>
    <p>
      CB06-036 is an orally active, potent and selective toll-like receptor 8 (TLR8) agonist developed at Zhimeng Biopharma for the treatment of hepatitis B virus (HBV). CB06-036 can induces cytokines in human peripheral blood mononuclear cells that are able to activate antiviral effector function by multiple immune mediators.. Preclinical studies show that CB06-036 also has a good liver targeting characteristics.
    </p>
  </div>
</template>

<script>
export default {
  props: {},
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang='scss'>
</style>