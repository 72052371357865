<template>
  <div>
    <p>
      2022年1月4日，专注于表观遗传学领域治疗技术和新药研发的赛岚医药（CytosinLab Therapeutics）宣布于近期完成近2亿元人民币的A轮融资。本轮融资由行业知名机构泽悦创投和鼎晖投资联合领投，凯泰资本、达武创投、上汽恒旭资本跟投，昊壹资本担任本轮独家财务顾问。
    </p>
    <p>
      据介绍，本轮融资将用于加速赛岚医药管线上多个表观遗传靶向新药向临床推进开发，并进一步打造赛岚医药EpigenPLUS表观遗传学技术研发平台。
    </p>
    <p>
      赛岚医药创始人吴海平博士和米沅博士表示：“感谢新老投资人对赛岚的高度认可和支持。表观遗传机制已被证明是肿瘤等重大疾病的关键发病机理之一，也是开发治疗手段的一大途径。领域内基础科学与临床研究的结合将提供不可估量的新药研发机会，开拓广阔的市场前景。赛岚正全力打造独具特色的EpigenPLUS技术平台，并高效转化开发创新药物。本轮融资将助力我们加快技术平台的建设和管线开发，希望能早日将项目推进到临床。”
    </p>
    <h4>
      新兴手段瞄准肿瘤治疗难题
    </h4>
    <p>所谓表观遗传学是研究DNA序列未改变，但基因表达和性状的改变可遗传的科学，是生命系统联系内部基因表达调控和外部环境变化的桥梁。 </p>
    <p>在市场人士看来，目前表观遗传学手段是肿瘤治疗最具前景的方向之一。这是因为，肿瘤的发生和进展往往是由于一系列关键的基因表达调控异常所致，因此针对基因表达调控特别是表观遗传调控的研究正是对症下药的最佳手段。</p>
    <p>与此同时，与控制基因表达的转录因子相比，表观遗传因子靶点往往是在体内行使催化功能的各种酶类，现有药物发现技术下有机会设计各类小分子化合物进行靶向。而通过靶向表观遗传调控关键节点调控机制，通过干预表观基因组水平，更有望从整体上控制肿瘤的发生。通过靶向肿瘤及其所处微环境中各类细胞的表观遗传调控关键因子，也可以从根本上控制肿瘤的进展。</p>
    <p>而成立于2019年赛岚医药，正是一家专注于新一代表观遗传治疗和新药研发的创新生物技术公司。</p>
    <p>
      赛岚医药创始人吴海平博士和米沅博士表示：“感谢新老投资人对赛岚的高度认可和支持。表观遗传机制已被证明是肿瘤等重大疾病的关键发病机理之一，也是开发治疗手段的一大途径。领域内基础科学与临床研究的结合将提供不可估量的新药研发机会，开拓广阔的市场前景。赛岚正全力打造独具特色的EpigenPLUS技术平台，并高效转化开发创新药物。本轮融资将助力我们加快技术平台的建设和管线开发，希望能早日将项目推进到临床。”
    </p>
    <p>公司由表观遗传学领域全球顶尖的著名科学家徐国良院士，与前诺华生物学研发团队的吴海平博士、药物化学及运营团队的米沅博士共同联合创立。其余研发团队则来自诺华、强生等跨国药企研发部门，以及中科院、复旦大学、北京大学、哈佛大学等海内外顶尖科研机构的经验丰富的本土及海归科学家、企业界精英人士。此前深创投旗下多个生物医药基金及三一创新基金先后对赛岚医药完成了天使轮和Pre-A轮投资支持。</p>
    <p>“中国创新药研发正在经历一个迭代过程。关于创新药临床、商业化价值的判断将不再局限于中国巨大的病人体量，而是基于全球未满足临床需求及竞争格局的考量。新药研发的生态环境将越来越有利于那些有持续差异化创新能力的生物科技公司。”本轮领投方之一的泽悦创投创始合伙人吕晓翔表示，针对表观遗传改变的治疗策略是肿瘤治疗的一个新方向，赛岚医药创始团队徐国良院士、吴海平博士、米沅博士拥有表观遗传领域深刻的科学理解以及产业端新药发现和临床转化的丰富经验，是一家具备真正的创新能力和全球化竞争力的公司。</p>
    <p>
      鼎晖投资创新成长基金合伙人柳丹博士也表示，中国医药创新进入新阶段，需要像赛岚这样，依托中国顶尖科学家团队的深厚基础研究积累，开发真正的原研创新药。“徐国良院士和吴海平、米沅博士的联创团队十分互补，一位是世界级的科学家，另两位产业经验丰富，我们期待赛岚医药成长为表观遗传学领域药物开发的国际领军企业。”
    </p>
    <h4>
      成立两年打造创新性靶向新药矩阵
    </h4>
    <p>
      具体而言，赛岚医药所专注的表观遗传治疗领域，是通过包括小分子药物等创新的表观遗传学技术手段，调节和重编程肿瘤及其微环境的基因表达，精准靶向肿瘤依赖的重要基因/机制，实现对肿瘤等重大疾病的治疗。
    </p>
    <p>
      目前，赛岚团队基于深厚的基础研究和转化，靶向肿瘤表观遗传最新的靶点和机理，短短2年内打造了一个丰富多样且极具全球创新性的靶向新药研发管线，包含10余个全新靶点和机理（first-in-class）或同类最优（best-in-class）项目。其中，多个小分子新药项目已进入临床前研究阶段，将先后进行IND申报和开展临床研究。
    </p>
    <p>
      公司还与中科院、复旦、交大等一流院校建立了良好和深入的全方位合作，实现了后续原创项目的持续推陈出新，同时公司还将与外部合作方包括跨国药企进行合作打造更多有全球竞争力的研发管线产品。
    </p>
    <p>
      快速突出原研新药的背后，是赛岚医药科学家团队着力打造的EpigenPLUS技术平台。该技术平台结合了中国在表观遗传学领域快速发展和积累的科学和技术成果，以及前沿的药物开发、生物技术和治疗手段，整合生物技术、基础研究、新药研发和临床医学应用等。
    </p>
    <p>
      据悉，EpigenPLUS技术平台不仅针对单个的靶点，也针对相关的表观遗传学调控机制，包括肿瘤错配修复机制、肿瘤分化机制和肿瘤代谢机制等，拥有表观领域完整成体系的工具化合物和体内、体外药效模型。有望实现表观遗传治疗技术与肿瘤免疫、靶向治疗等其他创新疗法的联合，开创更为激动人心的广阔市场，为中国和全世界的肿瘤病人未被满足的临床需求带来新希望和新机会。
    </p>
    <p>
      “中国近年在表观遗传研究领域成果辈出，很多处于国际前沿水平。这些原创性基础研究成果急需由研发企业进行转化，形成造福于民的医药产品。赛岚医药将持续致力打造中国在该领域内具有代表性的前沿生物科技向治疗和应用转化的平台。”赛岚医药共同创始人、公司首席科学顾问徐国良院士如是称。
    </p>
  </div>
</template>

<script>
export default {
  props: {},
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang='scss'>
</style>