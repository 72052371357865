import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import '@/util/removeDefaultCss.css' // 清除默认样式
import './error' // 日志
import './cache'
import 'element-ui/lib/theme-chalk/index.css'
import 'wowjs/css/libs/animate.css'
// 引入 去除移动端点击延迟
import fastClick from 'fastclick'
// 初始化FastClick实例。在页面的DOM文档加载完成后
import { VueScrollReveal, main } from '@/util/scrollReveal'
// 引入字体包
import '@/styles/font.css'
Vue.use(VueScrollReveal, main) // v-scroll-reveal.reset
fastClick.attach(document.body)
Vue.use(ElementUI)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
}).$mount('#app')
