<template>
  <div v-scroll-reveal.reset class="banner4" :class="(isMobile && isMobile.includes('iPad')) ? 'banner4iPad' : isMobile !== null && 'banner4Mobile'">
    <div>
      <p class="title">{{ title }}</p>
      <p class="company">{{ company }}  {{ date }}</p>
    </div>
  </div>
</template>

<script>
import { scrollSet } from '@/util/scrollReveal.js'
export default {
  inject: {
    isMobile: {
      default: null
    }
  },
  props: {
    title: {
      type: String,
    },
    company: {
      type: String,
    },
    date: {
      type: String
    }
  },
  data () {
    return {
      scrollSet
    }
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang='scss'>
.banner4Mobile{
  background: url('/img/mobileBanner4.png') no-repeat center;
  background-size: 100% 100%;
  height: 2.1333rem !important;
  .title{
    padding-left: .2667rem !important;
    padding-right: .2667rem !important;
    font-size: .32rem !important;
    line-height: .5333rem;
  }
  .company{
    font-size: .25rem !important;
    color: rgba(255, 255, 255, 0.623);
    font-weight: 300;
    line-height: .277778rem;
  }
}
.banner4iPad{
  background: url('/img/mobileBanner4.png') no-repeat center;
  background-size: 100% 100%;
  height: 1.8rem !important;
  padding-top: 0.5rem !important;
  .title{
    padding-left: .2667rem;
    padding-right: .2667rem;
    font-size: .22rem !important;
    line-height: .5333rem;
  }
  .company{
    font-size: .12rem !important;
    color: rgba(255, 255, 255, 0.623);
    font-weight: 300;
    line-height: .277778rem;
  }
}
.banner4{
  width: 100%;
  height: 1.111111rem;
  padding-top: .305556rem;
  background: url('/img/bannar4.png') no-repeat;
  background-size: 100% 100%;
  &>div{
      .company{
    font-size: .0972rem;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 300;
    line-height: .277778rem;
  }
  p{
    text-align: center;
    opacity: 1;
    color: rgba(255,255,255,1);
    font-size: .166667rem;
    font-weight: 400;
    line-height: .277778rem;
    letter-spacing: 1px;
  }
  }
}
</style>