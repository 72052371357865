<template>
  <div :class="(isMobile !== null && (isMobile.includes('Android') || isMobile.includes('iPhone'))) ? 'mobile' : 'pc'">
    <img src="/img/10-26/image25.png" alt="" class="img25">
    <p></p>
    <p class="border"></p>
    <p>2022年3月14日，专注于表观遗传治疗领域的领导企业赛岚医药宣布与罗氏上海创新中心正式达成研发合作协议。赛岚医药是罗氏中国加速器的首批成员企业之一。根据协议，双方将致力于针对中国高发、亟需创新治疗方式的肿瘤类型，利用赛岚医药多年的研究基础、EpigenPLUS技术平台和多种研究模型，评估并选择表观遗传学领域中极具前景的方向和至少一个未公开的同类首创(First-in-Class)靶点予以突破。</p>
    <p class="border"></p>
    <p>赛岚医药创始人、首席执行官吴海平博士表示：“我们很高兴赛岚和表观遗传学赛道得到全球生物技术领导者罗氏的认可，我们一直坚信新一代表观遗传治疗将带给中国和全球患者治疗的希望，并希望能和罗氏研发团队密切合作，共同加速推进基于创新机理的新药研发，早日获得积极成果。”</p>
    <img src="/img/10-26/image26.png" alt="" class="img26">
    <p></p>
    <img src="/img/10-26/image27.png" alt="" class="img27">
    <p>赛岚医药专注于新一代表观遗传治疗和新药研发，利用表观遗传学创新机理和研究技术，开发针对癌症等重大疾病的新药和诊断治疗手段，满足中国及全球患者的医学需求。联合创始人徐国良院士和米沅博士深耕领域研究多年，成果深厚，得到国际学术界和产业界的认可。赛岚医药成立以来快速打造了丰富且极具全球创新性的新药研发管线，包含10余个全新同类首创(First-in-Class)靶点和机理或同类最优(Best-in-Class)项目，多个项目将进行IND申报和开展临床研究。公司搭建的EpigenPLUS技术平台，可望高效、集成、综合性地对领域内新机理、新靶点、新技术进行平台化的转化，并已发现了一系列创新靶点和候选化合物。</p>
    <img src="/img/10-26/image28.png" alt="" class="img28">
    <p>早在2004年，罗氏成立了中国研发中心，这是罗氏在新兴市场设立的首个研发中心，也是跨国药企在上海独资建立的第一个研发中心，主要从事药物的研究和早期开发。2019年，罗氏追加投资8.63亿人民币，将研发中心升级为全新的罗氏上海创新中心。罗氏上海创新中心将进一步加强免疫领域和肿瘤领域的药物研发能力和抗体发现研究能力，打造世界一流的研发中心。罗氏上海创新中心负责人沈宏博士表示：“在罗氏上海创新中心发展壮大的同时，我们非常高兴与全球新药研发领域锐意创新的企业建立合作，探索前沿技术平台和靶点信号通路，将科学和创新早日转化为药物分子，服务病患”。</p>
    <p>2021年，投资近3亿人民币的罗氏中国加速器，在政府与战略合作伙伴的支持下启动。作为罗氏全球首个自主建立并运营的加速器，它将助力推动中国医疗创新的加速发展，赋能中国本土医疗创新生态圈的建设。罗氏中国加速器负责人唐秋嵩博士表示：“寻找下一站的源头创新，搭建起同罗氏合作的桥梁是罗氏中国加速器的重要使命，期待赛岚医药同罗氏上海创新中心的合作实现双赢”。</p>
    <img src="/img/10-26/image29.png" alt="" class="img28">
  </div>
</template>

<script>
export default {
  props: {},
  inject: {
    isMobile: {
      default: null,
    },
  },
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang="scss">
img{
  display: block;
  margin: 0 auto;
}

.border{
  border-style: dashed;
  border-width: 1px 0px;
  border-color: rgb(13, 71, 144);
}
</style>
